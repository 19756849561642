import React, { useState } from "react";
import { Tabs } from 'antd';
import CardConfigurationPermission from "components/Cards/Admin/CardConfigurationPermission";

import CardConfigurationRole from "components/Cards/Admin/CardConfigurationRole";



const { TabPane } = Tabs;


const RolePermissionTab = ({ color }) => {
  const [open, setOpen] = useState("2")
     const handleTabclick = (key,event) => {
      //  alert(key)
       setOpen(key)
     }
//   state = { size: 'small' };

//   onChange = e => {
//     this.setState({ size: e.target.value });
//   };

 
//     const { size } = this.state;
    return (
      <div>
         <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white px-4" 
        }
      >
        
        <Tabs defaultActiveKey={open} tabPosition="top" animated={{ inkBar: true, tabPane: false }} onTabClick={handleTabclick}  size={'large'}>
            <TabPane  tab={
                        <span className="text-indigo-500 bg-white">
                        <i className="fas fa-user-tag px-2"></i>
                          Roles
                        </span>
                        } 
                       key="1">
            {open==='1' && <CardConfigurationRole />}
            {/* <SentItemCardTable /> */}
          </TabPane>
          
          <TabPane  tab={
                        <span className="text-red-500 bg-white">
                        <i className="fas fa-universal-access px-2"></i>
                        Permissions
                        </span>
                        } 
           key="2">
       {open==='2' && <CardConfigurationPermission />}
    
          </TabPane>
         
          
          
          
          
        </Tabs>
      </div>
      </div>
    );
    
}

export default function TabsRender() {
    return (
      <>
        <RolePermissionTab color="indigo" />
      </>
    );
  }
// ReactDOM.render(<MailTransaction />, mountNode);