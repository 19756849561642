import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import { App } from './App';
import {SocketContext, socket} from 'context/socket';



render(
    <Provider store={store}>
         <SocketContext.Provider value={socket}>
        <App />
        </SocketContext.Provider>
    </Provider>,
    document.getElementById('root')
);