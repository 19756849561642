import React, { useEffect, useState, useRef } from "react";
import { Table, Spin, Timeline, Empty, Divider, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Input from "../../Elements/Input.js";
import { toast } from 'react-toastify';
import { authHeader } from '../../../_helpers';
import axios from "axios";
import { errorHandler } from "error/errorHandler";
import {  FolderOpenTwoTone } from "@ant-design/icons";
import InfoAreaIconMail from "components/InfoAreas/Presentation/InfoAreaIconMail"
import Button from "../../Elements/Button.js";
import { Drawer, Chip, Accordion, AccordionSection, Path, PathStep } from 'react-rainbow-components';
import moment from 'moment'
import DeliverySpinner from "animations/DeliverySpinner.js";
import CardStatsMini from "../Admin/CardStatsMini.js";




// const expandable = { expandedRowRender: (record => <><section
//     className="not-found-controller"
//     dangerouslySetInnerHTML={{ __html: record.body_content }}
// />
// <p>Attachment : {record.attachment}</p><hr></hr><p> To Address : {record.to}</p><p>CC : {record.cc}</p><p>BCC :{record.bcc}</p></>),
//       expandIcon: ({ expanded, onExpand, record }) =>
//         expanded ? (
//           <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
//         ) : (
//           <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
//         )
//     };



const SentItemCardTable = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [state, setState] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 5,
        },
        loading: false,
        visible: false,
        searchText: '',
        searchedColumn: '',
        eventData: [],
        eventDataSummary: [],

    });
    const { data, pagination, loading, visible, searchText, searchedColumn, eventData, eventDataSummary } = state;
    
    function deliveryStatus(e) {
        e.preventDefault();
        const { id } = e.target; 
        if(id){
        setState(state => ({ ...state, ['loading']: true }));
        setState(state => ({ ...state, ['visible']: true }));
        axios.post(`${apiUrl}/api/mail/events`, {
            mlogid: id
        }, {
            headers: authHeader()
        }).then(response => {
            if (response.data.totalRows === 0) {
                toast.warn("No records found", {
                    // position: toast.POSITION.TOP_CENTER
                });
                setState(state => ({ ...state, ['eventData']: [] }));
            }
            setState(state => ({ ...state, ['eventData']: response.data.results }));
            setState(state => ({ ...state, ['eventDataSummary']: response.data.summary }));
            setState(state => ({ ...state, ['loading']: false }));

            console.log(eventData)
        })
            .catch((error) => {
                errorHandler(error);
            });
            
        }else{
            toast.warn("Delivery status has not yet been updated; kindly check again later.", {
                // position: toast.POSITION.TOP_CENTER
            });
        }

    }
    function forwardMail(e) {
        localStorage.removeItem('forwardMail');
        localStorage.setItem('forwardMail', e.target.value);
        window.location.reload(false);
        // history.push('/email')
    }
    const [searchVal, setSearchVal] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const searchItems = (searchValue, field) => {
        setSearchVal(searchValue)
        if (searchVal !== '') {
            const filteredData = eventData.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchVal.toLowerCase())
            })
            setFilteredResults(filteredData)

        }
        else {
            setFilteredResults(eventData)

        }

    }
    const [searchMailVal, setSearchMailVal] = useState('');
    const [filteredMailResults, setFilteredMailResults] = useState([]);
    const searchMailItems = (searchValue, field) => {
        setSearchMailVal(searchValue)
        if (searchMailVal !== '') {
            const filteredData = data.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchMailVal.toLowerCase())
            })
            setFilteredMailResults(filteredData)

        }
        else {
            setFilteredMailResults(data)

        }

    }
    const inputprops = {
        placeholder: "Search Mail id ",
        leftIcon: "fas fa-search",
        size: "sm",
        border: "borderless",
    }
    function handleDrawerClose() {

        setState(state => ({ ...state, ['loading']: false }));
        setState(state => ({ ...state, ['visible']: false }));
        setState(state => ({ ...state, ['eventData']: [] }));
        setState(state => ({ ...state, ['eventDataSummary']: [] }));
        setSearchVal('')


    }
    let searchInput = useRef(null);
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="sm"
                        color="indigo"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="sm" color="light" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        color="indigo"
                        size="sm"
                        onClick={() => {
                            confirm({ closeDropdown: false });

                            setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
                            setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
        setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
    };

    const handleReset = clearFilters => {
        clearFilters();
        setState(state => ({ ...state, ['searchText']: '' }));

    };
    const expandable = {
        expandedRowRender: (record =>


            <div className='bg-white'><InfoAreaIconMail to={record.to} cc={record.cc} bcc={record.bcc} from={record.from_address} date={record.date} mlogid={record.mlogid} title={record.title} description={record.body_content} attachment={record.attachment} /></div>),
        expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
                // <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                <FolderOpenTwoTone onClick={e => onExpand(record, e)} />

            ) : (
                
                <i className="far fa-folder" onClick={e => onExpand(record, e)}> Show More</i>
            )
    };
    const columns = [

        {
            title: 'Subject',
            dataIndex: 'subject',
            sorter: true,
            //   render: subj => `${name.first} ${name.last}`,
            // width: '30%',
            expandable: true,
            responsive: ['lg'],
            ...getColumnSearchProps('subject'),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            sorter: true,
            render: date => `${moment(date).format("Do MMMM YYYY, hh:mm a")}`,
            // width: '20%',
            responsive: ['lg'],
            ...getColumnSearchProps('date'),
        },

        {
            title: 'Sender',
            dataIndex: 'name',
            sorter: true,
            responsive: ['lg'],
            // render: text => <a>{text}</a>,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            sorter: true,
            responsive: ['lg'],
            render: record => <a>{record}</a>,
        },
        {
            title: 'Device Information',
            dataIndex: 'device',
            // sorter: true,
            responsive: ['lg'],
            // render: record => <a>{(record === null ? "" :record)}</a>,
            render: (text, record) => (
                <p> {(record.device === null ? "" :
                    <>
                        <span>Device : {(JSON.parse(record.device).platform_product === null ? "Desktop" : JSON.parse(record.device).product)}</span><br></br>
                        <span>Browser : {JSON.parse(record.device).platform_name}</span> <br></br>
                        <span>OS : {JSON.parse(record.device).platform_os.family}</span> <br></br>
                    </>
                )} </p>
            )
        },

        {
            title: 'Action',
            dataIndex: 'mlogid',
            key: 'x',
            render: (mlogid) =>
                <>
                    {/* <Button outline={true} color="lightBlue" children="Forwad" name="Status" id={mlogid} size="sm" onClick={deliveryStatus} /> */}
                    <Button color="emerald" outline={true} children="" icon="fas fa-shipping-fast" name="Status" title="Delivery Status" id={mlogid} size="sm" onClick={deliveryStatus} />
                    <Button outline={true} onClick={forwardMail} value={mlogid} size="sm" color="indigo" icon="fas fa-forward" children="" title="forward" />
                </>,
            responsive: ['lg'],
        },

    ];

    
    useEffect(() => {
        console.log('oopennnnnnnnnnnn')
        const { pagination } = state;
        fetch({ pagination });
    }, []);
    const handleTableChange = (pagination, filters, sorter) => {
        fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    fetch = (params = {}) => {
        setState(state => ({ ...state, ['loading']: true }));
        axios.post(`${apiUrl}/api/mail/mail_trasactions`, {
            status: [2], results: params.pagination.pageSize, page: params.pagination.current,
            ...params
        }, {
            headers: authHeader()
        }).then(response => {
            setState({
                loading: false,
                data: response.data.result,
                pagination: {
                    ...params.pagination,
                    total: response.data.totalRows,

                },
                eventData: eventData,
                eventDataSummary: eventDataSummary,
            });

        }).catch((error) => {
            errorHandler(error);
        });
    };



    return (
        <>
            <Drawer
                id="drawer-3"
                size="large"
                isOpen={visible}
                onRequestClose={handleDrawerClose}

            >

                <div className="flex-auto px-6 pb-6 pt-0">

                    <Spin spinning={loading} indicator={<DeliverySpinner />}  >
                        {eventDataSummary.length > 0 &&
                            eventDataSummary.map((prop, key) => (
                                <>
                                    <div className="flex flex-wrap">
                                        <div key={key} className="w-full lg:w-2/12 xl:w-2/12 px-4">
                                            <CardStatsMini statSubtitle="Total Recipients"
                                                statTitle= {prop.totalRequest}
                                                statPercentColor= "text-emerald-500"
                                                statIconName= "fas fa-mail-bulk"
                                                statIconColor= "bg-blueGray-500" />
                                                
                                        </div>
                                        <div key={key} className="w-full lg:w-2/12 xl:w-2/12 px-4">
                                            <CardStatsMini statSubtitle="Total Opened"
                                                statTitle= {prop.totalOpened}
                                                statPercentColor= "text-emerald-500"
                                                statIconName= "fas fa-envelope-open-text"
                                                statIconColor= "bg-emerald-500" />
                                                
                                        </div>
                                        <div key={key} className="w-full lg:w-2/12 xl:w-2/12 px-4">
                                            <CardStatsMini statSubtitle="Total Clicked"
                                                statTitle= {prop.totalClicked}
                                                statPercentColor= "text-emerald-500"
                                                statIconName= "fas fa-mouse"
                                                statIconColor= "bg-lightBlue-500" />
                                                
                                        </div>
                                        <div key={key} className="w-full lg:w-2/12 xl:w-2/12 px-4">
                                            <CardStatsMini statSubtitle="Total Bounced"
                                                statTitle= {prop.totalBounced}
                                                statPercentColor= "text-emerald-500"
                                                statIconName= "fas fa-ban"
                                                statIconColor= "bg-amber-500" />
                                                
                                        </div>
                                        <div key={key} className="w-full lg:w-2/12 xl:w-2/12 px-4">
                                            <CardStatsMini statSubtitle="Total Dropped"
                                                statTitle= {prop.totalDropped}
                                                statPercentColor= "text-emerald-500"
                                                statIconName= "fas fa-question-circle"
                                                statIconColor= "bg-red-500" />
                                                
                                        </div>
                                        <div key={key} className="w-full lg:w-2/12 xl:w-2/12 px-4">
                                            <CardStatsMini statSubtitle="Total Invalid"
                                                statTitle= {prop.totalInvalid}
                                                statPercentColor= "text-emerald-500"
                                                statIconName= "fas fa-exclamation-circle"
                                                statIconColor= "bg-orange-500" />
                                                
                                        </div>
                                        
                                    </div>
                                   

                                </>
                            ))
                        }
                        <div className="w-full item-center xl:w-12/12 px-2">
                            <Divider><Input name="to" onChange={(e) => searchItems(e.target.value, e.target.name)} {...inputprops} /></Divider>
                        </div>
                        {eventData.length > 0 &&
                            (searchVal.length > 1 ? filteredResults : eventData).map((prop, key) => (

                                <>

                                    <div className="rainbow-m-around_xx-small">
                                        <Accordion className="text-sm text-lightBlue" id="accordion-1">
                                            {/* <AccordionSection label={"Recipients Email  : " + prop.rcptEmail} > */}
                                            <AccordionSection label={<> <div className="text-center flex justify-between items-center">{"Recipients Email  : " + prop.rcptEmail}<div className="rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">
                                                Current Status <Chip className="rainbow-m-around_medium" label={prop.status} variant={prop.status === 'open' ? 'success' : "brand"} />
                                            </div></div></>}>
                                                <p>
                                                    <div className="rainbow-p-around_x-large rainbow-align-content_center items-center justify-center">
                                                        <Path currentStepName={prop.status === 'open' || prop.status === 'click' ? "opened" : "delivered"}>
                                                            <PathStep name="sent" label="Sent" />
                                                            <PathStep name="in-progress" label="InProgress" />
                                                            <PathStep name="delivered" label="Delivered" />
                                                            <PathStep name="opened" label="Opened" />
                                                        </Path>
                                                    </div>
                                                    <Divider orientation="center">Status Timeline</Divider>
                                                    <div className="relative flex flex-col min-w-0 break-words bg-white-100 w-full mb-6  rounded">

                                                        <div className="px-4 py-5 flex-auto">
                                                            <div className="flex flex-wrap">
                                                                {/* <div className="w-full xl:w-12/12 px-4">
                                                    <div className="text-center flex justify-between items-center">
                                                        <h6 className="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Recipients Email {prop.rcptEmail}</h6>
                                                        
                                                        <div className="rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">
                                                            <Chip className="rainbow-m-around_medium" label={prop.status} variant={prop.status === 'open' ? 'success' : "brand"} />
                                                        </div>
                                                    </div>
                                                    <p>From {prop.fromaddress}</p>
                                                    <hr></hr>
                                                </div> */}

                                                                <div className="w-full xl:w-4/12 px-4">
                                                                    <Timeline>

                                                                        <h4 className="text-lg">Delivery Status</h4>


                                                                        <div className="rainbow-p-vertical_large rainbow-align-content_center flex justify-between items-center">
                                                                            <Timeline.Item >Requested Time <span>
                                                                                <Chip className="rainbow-m-around_medium" label={prop.requestedTime} variant="outline-brand" />
                                                                            </span>

                                                                            </Timeline.Item>
                                                                        </div>
                                                                        <div className="rainbow-p-vertical_large rainbow-align-content_center flex justify-between items-center">
                                                                            <Timeline.Item color="green">Delivery Time <span>
                                                                                <Chip className="rainbow-m-around_medium" label={prop.deliveryTime} variant="outline-brand" />
                                                                            </span>

                                                                            </Timeline.Item>
                                                                        </div>
                                                                        <div className="rainbow-p-vertical_large rainbow-align-content_center flex justify-between items-center">
                                                                            <Timeline.Item color={prop.status === 'open' || prop.status === 'click' ? "green" : "blue"}>Status <span>
                                                                                <Chip className="rainbow-m-around_medium" label={prop.status} variant={prop.status === 'open' ? 'success' : "brand"} />
                                                                            </span>

                                                                            </Timeline.Item>
                                                                        </div>


                                                                    </Timeline>
                                                                </div>
                                                                <div className="w-full xl:w-4/12 px-4">
                                                                    {prop.status === 'open' &&
                                                                        <Timeline>
                                                                            <h3 className="text-lg">Read Status</h3>

                                                                            {prop.opens.map((open, key) => (
                                                                                <>
                                                                                    <div className="rainbow-p-vertical_large rainbow-align-content_center flex justify-between items-center">
                                                                                        <Timeline.Item color="green">Opend at <span>
                                                                                            <Chip className="rainbow-m-around_medium" label={open.time} variant="outline-brand" />
                                                                                            <span >IP Address <Chip className="rainbow-m-around_medium" label={open.ip} variant="outline-brand" /></span>
                                                                                        </span>

                                                                                        </Timeline.Item>
                                                                                    </div>

                                                                                </>
                                                                            ))}


                                                                        </Timeline>
                                                                    }

                                                                    {prop.status === 'sent' &&
                                                                        <Timeline>
                                                                            <h3 className="text-lg">Read Status</h3>
                                                                            <Timeline.Item>
                                                                                <p>Not opend yet</p>
                                                                            </Timeline.Item>
                                                                        </Timeline>
                                                                    }

                                                                </div>
                                                                <div className="w-full xl:w-4/12 px-4">
                                                                    {prop.status === 'click' &&
                                                                        <Timeline>
                                                                            <h3 className="text-lg">Link Click Status</h3>

                                                                            {prop.clicks.map((click, key) => (
                                                                                <>

                                                                                    <Timeline.Item color="green">Clicked at <span>
                                                                                        <Chip className="rainbow-m-around_medium" label={click.time} variant="outline-brand" />
                                                                                        <span >IP Address <Chip className="rainbow-m-around_medium" label={click.ip} variant="outline-brand" /></span>
                                                                                        <span >Link <Chip className="rainbow-m-around_medium" label={click.link} variant="outline-brand" /></span>
                                                                                    </span>

                                                                                    </Timeline.Item>
                                                                                </>
                                                                            ))}


                                                                        </Timeline>
                                                                    }

                                                                    {(prop.status === 'sent' || prop.status === 'open') &&
                                                                        <Timeline>
                                                                            <h3 className="text-lg">Link Click Status</h3>
                                                                            <Timeline.Item>
                                                                                <p>Not clicked yet</p>
                                                                            </Timeline.Item>
                                                                        </Timeline>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                            </AccordionSection>

                                        </Accordion>
                                    </div>



                                </>))
                        }
                        {eventData.length === 0 && <Empty />}


                    </Spin>


                </div>
            </Drawer>
            <div className="w-full item-center xl:w-12/12 px-2">
                            <Divider><Input name="to" onChange={(e) => searchMailItems(e.target.value, e.target.name)} {...inputprops} /></Divider>
                        </div>
            <Table
                columns={columns}
                rowKey={record => record.mlogid}
                dataSource={(searchMailVal.length > 1 ? filteredMailResults : data)}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                expandable={expandable}
                expandRowByClick={true}
            />
        </>
    );

}
export default SentItemCardTable;
// ReactDOM.render(<CardTable />, mountNode);