import React, { useEffect, useState } from "react";
import { Table, Tag, Spin, Timeline,Empty } from 'antd';
import { toast } from 'react-toastify';
import { authHeader } from '../../../_helpers';
import axios from "axios";
import { errorHandler } from "error/errorHandler";
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import InfoAreaIconMail from "components/InfoAreas/Presentation/InfoAreaIconMail"
import Button from "../../Elements/Button.js";
import { Drawer, Chip,Accordion, AccordionSection } from 'react-rainbow-components';
import CardInfoFullColorSmall from 'components/Cards/Presentation/CardInfoFullColorSmall'




// const expandable = { expandedRowRender: (record => <><section
//     className="not-found-controller"
//     dangerouslySetInnerHTML={{ __html: record.body_content }}
// />
// <p>Attachment : {record.attachment}</p><hr></hr><p> To Address : {record.to}</p><p>CC : {record.cc}</p><p>BCC :{record.bcc}</p></>),
//       expandIcon: ({ expanded, onExpand, record }) =>
//         expanded ? (
//           <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
//         ) : (
//           <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
//         )
//     };



const ScheduleItemCardTable = () => {

    const apiUrl=process.env.REACT_APP_API_URL;
    const [state, setState] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 5,
        },
        loading: false,
        visible: false,
        eventData: [],
        eventDataSummary:[],

    });
    const { data, pagination, loading, visible, eventData,eventDataSummary } = state;
    function handleChange(name, value) {
        setState(state => ({ ...state, [name]: value }));
    }
    function deliveryStatus(e) {
        e.preventDefault();
        const { name, id } = e.target;
        setState(state => ({ ...state, ['loading']: true }));
        setState(state => ({ ...state, ['visible']: true }));
        axios.post(`${apiUrl}/api/mail/events`, {
            mlogid: id
        }, {
            headers: authHeader()
        }).then(response => {
            if (response.data.totalRows === 0) {
                toast.warn("No records found", {
                    // position: toast.POSITION.TOP_CENTER
                });
                setState(state => ({ ...state, ['eventData']: [] }));
            }
            setState(state => ({ ...state, ['eventData']: response.data.results }));
            setState(state => ({ ...state, ['eventDataSummary']: response.data.summary }));
            setState(state => ({ ...state, ['loading']: false }));

            console.log(eventData)
        })
            .catch((error) => {
                errorHandler(error);
            });


    }
    function handleDrawerClose() {

        setState(state => ({ ...state, ['loading']: false }));
        setState(state => ({ ...state, ['visible']: false }));


    }
    const expandable = {
        expandedRowRender: (record =>


            <InfoAreaIconMail  date={record.date} title={record.title} description={record.body_content} attachment={record.attachment} />),
        expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
            ) : (
                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
            )
    };
    const columns = [
        {
            title: 'Subject',
            dataIndex: 'subject',
            sorter: true,
            //   render: subj => `${name.first} ${name.last}`,
            width: '40%',
            expandable: true,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            sorter: true,
            //   render: subj => `${name.first} ${name.last}`,
            width: '20%',
        },

        {
            title: 'message id',
            dataIndex: 'message_id',
        },
        {
            title: 'Action',
            dataIndex: 'mlogid',
            key: 'x',
            render: (mlogid) => <Button color="emerald" children="Delivery Status" name="Status" id={mlogid} size="sm" onClick={deliveryStatus} />,
        },
    ];

    const getRandomuserParams = params => ({
        results: params.pagination.pageSize,
        page: params.pagination.current,
        status: [2],
        ...params,
    });
    useEffect(() => {
        const { pagination } = state;
        fetch({ pagination });
    }, []);
    const handleTableChange = (pagination, filters, sorter) => {
        fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    fetch = (params = {}) => {
        setState(state => ({ ...state, ['loading']: true }));
        // reqwest({
        //   url: 'https://randomuser.me/api',
        //   method: 'get',
        //   data: { foo: 'bar', baz: 100 },
        //   type: 'json',
        //   data: getRandomuserParams(params),
        // }).then(data => {
        //   console.log(data);
        //   this.setState({
        //     loading: false,
        //     data: data.results,
        //     pagination: {
        //       ...params.pagination,
        //       total: 200,
        //       // 200 is mock data, you should read it from server
        //       // total: data.totalCount,
        //     },
        //   });
        // });
        axios.post(`${apiUrl}/api/mail/mail_trasactions`, {
            status: [3], results: params.pagination.pageSize, page: params.pagination.current,
            ...params
        }, {
            headers: authHeader()
        }).then(response => {
            setState({
                loading: false,
                data: response.data.result,
                pagination: {
                    ...params.pagination,
                    total: response.data.totalRows,

                },
                eventData: eventData,
                eventDataSummary:eventDataSummary,
            });

        }).catch((error) => {
            errorHandler(error);
        });
    };

    const currentDateTime= Date().toLocaleString()
    const props = {
        color: "amber",
        icon: "fas fa-smile-beam",
        title: "Best Quality",
        description:
          "It becomes harder for us to give others a hand. We get our heart broken by people we love.",
        gradient: false,
      }
    return (
        <>
            <Drawer
                id="drawer-3"
                header={"Mali Delivery Status Report As On - " + currentDateTime}
                size="full"
                isOpen={visible}
                onRequestClose={handleDrawerClose}
                
            >

                <div className="flex-auto px-6 pb-6 pt-0">

                    <Spin spinning={loading} tip="Loading...">
                    {eventDataSummary.length > 0 &&   
                    eventDataSummary.map((prop, key) => (
                        <>
                        <div className="relative flex flex-col min-w-0 break-words bg-white-100 w-full mb-6  rounded">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="flex flex-wrap">
                        <div className="w-full xl:w-2/12 px-4">
                        <CardInfoFullColorSmall color="blueGray" icon="fas fa-mail-bulk" title="Total Recipients" description={["To "+prop.totalRequest,"CC "+prop.totalRequest,"BCC "+prop.totalRequest]} gradient={true} />
                      </div>
                      <div className="w-full xl:w-2/12 px-4">
                      <CardInfoFullColorSmall color="emerald" icon="fas fa-envelope-open-text" title="Total Open" description={prop.totalOpened} gradient={true} />
                    </div>
                    <div className="w-full xl:w-2/12 px-4">
                      <CardInfoFullColorSmall color="teal" icon="fas fa-mouse" title="Total Clicked" description={prop.totalClicked} gradient={true} />
                    </div>
                    <div className="w-full xl:w-2/12 px-4">
                      <CardInfoFullColorSmall color="orange" icon="fas fa-ban" title="Total Bounced" description={prop.totalBounced} gradient={true} />
                    </div>
                    <div className="w-full xl:w-2/12 px-4">
                      <CardInfoFullColorSmall color="pink" icon="fas fa-question-circle" title="Total Dropped" description={prop.totalDropped} gradient={true} />
                    </div>
                    <div className="w-full xl:w-2/12 px-4">
                      <CardInfoFullColorSmall color="red" icon="fas fa-exclamation-circle" title="Total Invalid" description={prop.totalInvalid} gradient={true} />
                    </div>
                    </div>
                    </div>
                    </div>
                  
                    </>
                    ))
                    }
                        {eventData.length > 0 &&
                            eventData.map((prop, key) => (
                                
                                <>
                                  <div className="rainbow-m-around_xx-large">
                                        <Accordion className="text-sm text-lightBlue" id="accordion-1">
                                            {/* <AccordionSection label={"Recipients Email  : " + prop.rcptEmail} > */}
                                            <AccordionSection  label={<> <div className="text-center flex justify-between items-center">{"Recipients Email  : " + prop.rcptEmail}<div className="rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">
                                                            Current Status <Chip className="rainbow-m-around_medium" label={prop.status} variant={prop.status === 'open' ? 'success' : "brand"} />
                                                        </div></div></>}>
                                                <p>
                                                <div className="relative flex flex-col min-w-0 break-words bg-white-100 w-full mb-6  rounded">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="flex flex-wrap">
                                                {/* <div className="w-full xl:w-12/12 px-4">
                                                    <div className="text-center flex justify-between items-center">
                                                        <h6 className="text-xl font-normal leading-normal mt-0 mb-2 text-emerald-800">Recipients Email {prop.rcptEmail}</h6>
                                                        
                                                        <div className="rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">
                                                            <Chip className="rainbow-m-around_medium" label={prop.status} variant={prop.status === 'open' ? 'success' : "brand"} />
                                                        </div>
                                                    </div>
                                                    <p>From {prop.fromaddress}</p>
                                                    <hr></hr>
                                                </div> */}
                                                <div className="w-full xl:w-4/12 px-4">
                                                    <Timeline>
                                                       
                                                        <h4 className="text-lg">Delivery Status</h4>

                                                      
                                                        <div className="rainbow-p-vertical_large rainbow-align-content_center flex justify-between items-center">
                                                            <Timeline.Item >Requested Time <span>
                                                                <Chip className="rainbow-m-around_medium" label={prop.requestedTime} variant="outline-brand" />
                                                            </span>

                                                            </Timeline.Item>
                                                        </div>
                                                        <div className="rainbow-p-vertical_large rainbow-align-content_center flex justify-between items-center">
                                                            <Timeline.Item color="green">Delivery Time <span>
                                                                <Chip className="rainbow-m-around_medium" label={prop.deliveryTime} variant="outline-brand" />
                                                            </span>

                                                            </Timeline.Item>
                                                        </div>
                                                        <div className="rainbow-p-vertical_large rainbow-align-content_center flex justify-between items-center">
                                                            <Timeline.Item  color={prop.status==='open' || prop.status==='click' ? "green" : "blue"}>Status <span>
                                                                <Chip className="rainbow-m-around_medium" label={prop.status} variant={prop.status === 'open' ? 'success' : "brand"} />
                                                            </span>

                                                            </Timeline.Item>
                                                        </div>


                                                    </Timeline>
                                                </div>
                                                <div className="w-full xl:w-4/12 px-4">
                                                    {prop.status === 'open' &&
                                                        <Timeline>
                                                            <h3 className="text-lg">Read Status</h3>

                                                            {prop.opens.map((open, key) => (
                                                                <>
                                                                    <div className="rainbow-p-vertical_large rainbow-align-content_center flex justify-between items-center">
                                                            <Timeline.Item color="green">Opend at <span>
                                                                <Chip className="rainbow-m-around_medium" label={open.time} variant="outline-brand" />
                                                                 <span >IP Address <Chip className="rainbow-m-around_medium" label={open.ip} variant="outline-brand" /></span>
                                                            </span>

                                                            </Timeline.Item>
                                                        </div>

                                                                </>
                                                            ))}


                                                        </Timeline>
                                                    }

                                                    {prop.status === 'sent' &&
                                                        <Timeline>
                                                            <h3 className="text-lg">Read Status</h3>
                                                            <Timeline.Item>
                                                                <p>Not opend yet</p>
                                                            </Timeline.Item>
                                                        </Timeline>
                                                    }

                                                </div>
                                                <div className="w-full xl:w-4/12 px-4">
                                                    {prop.status === 'click' &&
                                                        <Timeline>
                                                            <h3 className="text-lg">Link Click Status</h3>

                                                            {prop.clicks.map((click, key) => (
                                                                <>
                                                            
                                                                    <Timeline.Item color="green">Clicked at <span>
                                                                <Chip className="rainbow-m-around_medium" label={click.time} variant="outline-brand" />
                                                                 <span >IP Address <Chip className="rainbow-m-around_medium" label={click.ip} variant="outline-brand" /></span>
                                                                 <span >Link <Chip className="rainbow-m-around_medium" label={click.link} variant="outline-brand" /></span>
                                                            </span>

                                                            </Timeline.Item>
                                                                </>
                                                            ))}


                                                        </Timeline>
                                                    }

                                                    {(prop.status === 'sent' || prop.status === 'open') &&
                                                        <Timeline>
                                                            <h3 className="text-lg">Link Click Status</h3>
                                                            <Timeline.Item>
                                                                <p>Not clicked yet</p>
                                                            </Timeline.Item>
                                                        </Timeline>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                </p>
                                            </AccordionSection>
                                        
                                        </Accordion>
                                    </div>
                                    


                                </>))
                        }
                         {eventData.length === 0 && <Empty />}


                    </Spin>


                </div>
            </Drawer>
            <Table
                columns={columns}
                rowKey={record => record.mlogid}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                expandable={expandable}
            />
        </>
    );

}
export default ScheduleItemCardTable;
// ReactDOM.render(<CardTable />, mountNode);