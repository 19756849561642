import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
// components
import Button from "../../Elements/Button.js";
import Input from "../../Elements/Input.js";
import Alert from "../../../components/Elements/Alert.js";
import { userActions } from '../../../_actions';
import { alertActions } from '../../../_actions';
import { history } from '../../../_helpers';
import platform from 'platform';
import ErrorCat from "animations/ErrorCat.js";

export default function CardLoginSocial({
  title,
  subtitle,
  mobileMask,
  email,
  inputs,
  button,
  resendButton,
  inputVal

}) {
  // const user = useSelector(state => state.authentication.user);
  const user = JSON.parse(localStorage.getItem('user'));
  var username = user.username;
  var mobile = user.mobile;
  var otpMethod = user.otpMethod;
  let otp = inputVal.otp;
  const dispatch = useDispatch();
  const location = useLocation();
  // const [submitted, setSubmitted] = useState(false)
  const alert = useSelector(state => state.alert);
  const [counter, setCounter] =useState(59);
  const apiUrl=process.env.REACT_APP_API_URL;
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);



  function handleSubmit(e) {
  
    e.preventDefault();
   
    if (username && otp) {
      console.log(otp);
      const device = {
      platform_name:platform.name, // 'IE' ( your users browser name )
      platform_version:platform.version,
      platform_os:platform.os,
      platform_product:platform.product,
      }
      console.log(device)
      
      // get return url from location state or default to home page
      // const { from } = location.state || { from: { pathname: "/dashboard" } };
      const { from } = { from: { pathname: "/dashboard" } };
      dispatch(userActions.verifyOtp(username, otp,device, from));
    }
  }
  function handleResend(e){
    axios.post(`${apiUrl}/api/auth/resend-otp`, { username:username,mobile:mobile,otpRadio:otpMethod  },  {
    })
      .then((response) => {
        setCounter(59)
      });
  }
  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, []);



  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className="mb-0 px-6 py-6">
          <div className="text-center mb-3">
            {(alert.message) &&
              <div > < ErrorCat/> <Alert children={alert.message} /></div>

            }
            <h6 className="text-blueGray-500 text-lg font-bold">{title}</h6>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div className="text-blueGray-500 text-center mb-3 font-bold">
              <p className="text-sm">{subtitle}</p>
              {/* <small>{subtitle}</small> */}
              <p className="text-emerald-500 text-sm font-bold">{mobileMask}</p>
              <p className="text-emerald-500 text-sm font-bold">{email}</p>
            </div>
            <form onSubmit={handleSubmit}>
              {inputs.map((prop, key) => {
                return (
                  <div key={key} className="relative w-full">
                    <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                      {prop.label}
                    </label>
                    <Input {...prop.input} />
                  </div>
                );
              })}

              <div className="text-center mt-5">
                <Button {...button}  />
                {/* <Button onClick={handleSubmit} children="verify"  /> */}
              </div>
            </form>
          </div>


          <hr className="mt-6 border-b-1 border-blueGray-200" />
          <div className="text-center text-blueGray-500 text-lg mb-5 mt-1">
            <p className="text-sm">Don't receive the code? Resend OTP in <span className="text-red-500" style={{ fontWeight: "bold" }}> 00:{counter}</span> Sec</p>
            {!counter && <Button {...resendButton} onClick={handleResend} />}
          </div>
        </div>

      </div>

    </>
  );
}

CardLoginSocial.defaultProps = {
  inputs: [],
  button: {},
  resendButton: {}

};

CardLoginSocial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mobileMask: PropTypes.string,
  email: PropTypes.string,
  // NOTE: the "Forgot password?" text is allready set

  // It is represetnted by the props you
  // can pass to our Button component element
  button: PropTypes.object,
  resendButton: PropTypes.object,

  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      // It is represetnted by the props you
      // can pass to our Input component element
      // NOTE: if you wish to generate a textarea, you will need to pass
      // // // inside this object >> type: "textarea"
      // // // full example >> input: { type: "textarea" }
      input: PropTypes.object,
    })
  ),
};
