import React, { useState } from "react";
import { Tabs } from 'antd';
import CardConfigurationMail from "components/Cards/Admin/CardConfigurationMail";
import CardConfigurationMailFooter from "components/Cards/Admin/CardConfigurationMailFooter";
import CardConfigurationAPI from "components/Cards/Admin/CardConfigurationAPI";
import CardConfigurationSMTP from "components/Cards/Admin/CardConfigurationSMTP";
import CardSystemConfiguration from "components/Cards/Admin/CardSystemConfiguration";


const { TabPane } = Tabs;


const Configuration = ({ color }) => {
  const [open, setOpen] = useState("2")
     const handleTabclick = (key,event) => {
      //  alert(key)
       setOpen(key)
     }
//   state = { size: 'small' };

//   onChange = e => {
//     this.setState({ size: e.target.value });
//   };

 
//     const { size } = this.state;
    return (
      <div>
         <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white px-4" 
        }
      >
        
        <Tabs defaultActiveKey={open} tabPosition="top" animated={{ inkBar: true, tabPane: false }} onTabClick={handleTabclick}  size={'large'}>
            <TabPane  tab={
                        <span className="text-lightBlue-500 bg-white">
                        <i className="fas fa-cogs px-2"></i>
                          SMTP Configuration
                        </span>
                        } 
                       key="1">
            {open==='1' && <CardConfigurationSMTP />}
            {/* <SentItemCardTable /> */}
          </TabPane>
          
          <TabPane  tab={
                        <span className="text-teal-500 bg-white">
                        <i className="fas fa-mail-bulk px-2"></i>
                        MAIL Global Settings
                        </span>
                        } 
           key="2">
       {open==='2' && <CardConfigurationMail />}
    
          </TabPane>
          <TabPane tab={
                        <span className="text-blueGray-500 bg-white">
                        <i className="fab fa-ethereum px-2"></i>
                       Netcore API Configuration
                        </span>
                        }  key="3">
         {open==='3' && <CardConfigurationAPI />}
          </TabPane>
          <TabPane tab={
                        <span className="text-red-500 bg-white">
                        <i className="fab fa-ethereum px-2"></i>
                       Mail Footer Settings
                        </span>
                        }  key="4">
         {open==='4' && <CardConfigurationMailFooter />}
          </TabPane>

          <TabPane tab={
                        <span className="text-amber-500 bg-white">
                        <i className="fab fa-ethereum px-2"></i>
                       System Configuration
                        </span>
                        }  key="5">
         {open==='5' && <CardSystemConfiguration />}
          </TabPane>
          
          
          
        </Tabs>
      </div>
      </div>
    );
    
}

export default function TabsRender() {
    return (
      <>
        <Configuration color="indigo" />
      </>
    );
  }
// ReactDOM.render(<MailTransaction />, mountNode);