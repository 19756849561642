import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { authHeader } from '../../_helpers';
import { errorHandler } from "error/errorHandler";

import moment from 'moment'

// import { userActions } from '../../_actions';
import axios from "axios";
// components

import RecentLogin from "components/Sections/Misc/Features/RecentLogin";


const apiUrl = process.env.REACT_APP_API_URL;



export default function LoginActivity() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const user = useSelector(state => state.authentication.user);



 
  const [transactions, setTransactions] = useState([])
  const [props, setProps] = useState([])

  useEffect(() => {

    if (localStorage.getItem('user') || user.hasOwnProperty('accessToken')) {

      axios.get(`${apiUrl}/api/users/loginActivity/${user.id}`, {}, {
        headers: authHeader()
      })
        .then((response) => {
       
          let transaction = [];
          let i = 0
          console.log(response.data)
          response.data.map((prop, key) => {
            // console.log(prop.ip)
            // alert(prop.p_mail)
            transaction.push(
              {

                icon: "fab fa-html5",
                color: "purple",
                inverted: (i === 0 ? true : false),
                title: moment(prop.login_time).format("Do MMMM YYYY, h:mm a"),
                description:
                  `Device : ${(prop.device.platform_product===null ? "Desktop":prop.device.platform_product)}  OS : ${prop.device.platform_os.family}   Browser ${prop.device.platform_name} `,
                link: { href: "#pablo", children: `IP Address : ${prop.ip}` },
              }

            )
            setTransactions(
              transaction
            );
            i++
          })


        });

    }


  }, []);


  // const props = 
  // {
  //   heading3badge: {
  //     badge: { color: "purple", children: "Last 3 Logins" },
  //     title: "Your Recent Login Activity",
  //     description:
  //       "I light a cigarette, like any other day. A lot of people wonder if we don't smoke, you know? To many that want to get our attention, that want to offer their unwanted advice.",
  //     alignment: "left",
  //   },
  //   image: "/static/media/ill.07e25086.png",
  //   cards: 
  //     transactions,
  //     [
  //     {
  //       icon: "fab fa-html5",
  //       color: "purple",
  //       title: "For Developers",
  //       description:
  //         "The time is now for it to be okay to be great. People in this world shun people for being great. For being a bright color. For standing out. But the time is now.",
  //       link: { href: "#pablo", children: "Learn more" },
  //     },
  //     {
  //       icon: "fas fa-cubes",
  //       color: "purple",
  //       inverted: true,
  //       title: "For Designers",
  //       description:
  //         "There’s nothing I really wanted to do in life that I wasn’t able to get good at. That’s my skill. I’m not really specifically talented at anything except for the ability to learn.",
  //       link: { href: "#pablo", children: "Learn more" },
  //     },
  //     {
  //       icon: "fas fa-bell",
  //       color: "purple",
  //       title: "For Beginners",
  //       description:
  //         "That’s what I do. That’s what I’m here for. Don’t be afraid to be wrong because you can’t learn anything from a compliment. If everything I did failed - which it doesn't.",
  //       link: { href: "#pablo", children: "Learn more" },
  //     },
  //     ]

  // }
  useEffect(() => {
    console.log(transactions)
    const propsobj = {
      heading3badge: {
        badge: { color: "purple", children: "Last 5 Logins" },
        title: "Your Recent Login Activity",
        description:
          "You can see your sign-in history, including the dates and times that your Postman account was used. You can also see the IP addresses which were used to access your account.",
        alignment: "left",
      },
      image: "/static/media/ill.07e25086.png",
      cards:
        transactions

      ,
    }
    setProps(propsobj)
  }, [transactions])

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12 px-4">
          <div
            className={
              "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white"

            }
          >
            <RecentLogin {...props} />
          </div>

        </div>
      </div>




    </>
  );
}
