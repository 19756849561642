import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
// import { useHistory } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';

import { userActions } from '../../../_actions';
// components
import Button from "../../../components/Elements/Button.js";
import Alert from "../../../components/Elements/Alert.js";
import Input from "../../../components/Elements/Input.js";
import Checkbox from "../../../components/Elements/Checkbox.js";
import { Radio } from 'antd';

import { history } from '../../../_helpers';
import { alertActions } from '../../../_actions';
import ErrorCat from "animations/ErrorCat";

export default function CardLoginSocial({
  title,
  subtitle,
  inputs,
  socials,
  button,
  checkbox,
  radioGroup,
  forgotPassword,
  createAccount,
  inputVal,

}) {

  // const loggingIn = useSelector(state => state.authentication.loggingIn);
  const dispatch = useDispatch();
  const location = useLocation();
  // const [submitted, setSubmitted] = useState(false)
  const alert = useSelector(state => state.alert);
  const [otpSend, setotpSend] = useState(false);
  const [mobile, setMobile] = useState('');
  let username = inputVal.username;
  let password = inputVal.password;
  let otpButton = inputVal.otpButton;
  let otpRadio = inputVal.otpRadio;
  // reset login status
  useEffect(() => {
    dispatch(userActions.logout());
  }, []);



  function handleSubmit(e) {
    e.preventDefault();

    // setSubmitted(true);
    if (username && password) {
      // get return url from location state or default to home page
      // const { from } = location.state || { from: { pathname: "/dashboard" } };
      const { from } =  { from: { pathname: "/otp" } };
      dispatch(userActions.login(username, password,otpRadio, from));
    }
  }

  function handleOtpSubmit(e) {
    e.preventDefault();

    // setSubmitted(true);
    if (mobile) {
      // get return url from location state or default to home page
      // const { from } = location.state || { from: { pathname: "/dashboard" } };
      const { from } =  { from: { pathname: "/otp" } };
      dispatch(userActions.loginOtp(mobile,otpRadio, from));
    }
  }



  

  const [LoginMobiletoggle, setLoginMobileToggle] = useState(false);
  
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });

    axios.get(`${apiUrl}/api/config/global-settings`).then(response => {
      // setLocationOptions(response.data);
      console.log(response.data.result)
      response.data.result.map((prop, key) => {
        console.log(prop.config_key)
        if (prop.config_key === 'LoginMobile') {
          setLoginMobileToggle(parseInt(prop.config_value) === 1 ? true : false)
        }
        
      })
    });


  }, []);


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className="mb-0 px-6 py-6">
          <div className="text-center mb-3">
            {(alert.message ) &&
              <div >< ErrorCat/> <Alert children={alert.message} /></div>

            }
           
          </div>
          {LoginMobiletoggle && <>
           <div className="text-center mb-3">
              <h6 className="text-blueGray-500 text-sm font-bold">{title}</h6>
             </div>
          <div className="text-center">
            {socials.map((prop, key) => (
              <Button
                size="sm"
                {...prop.button}
                key={key}
                color={prop.color}
                fullWidth={false}
                onClick={prop.onclick}
              >
                <i className={"mr-1 fas fa-" + prop.icon}></i> {prop.icon}
              </Button>
            ))}
          </div>
          </>
          }
          {otpButton &&
            <div className="mb-0 px-6 py-6">
              <form onSubmit={handleOtpSubmit}>
                <div className="relative w-full">
                  <label className="block text-center uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"> Mobile</label>
                  <Input type="number"
                    placeholder="Mobile Number" onChange={(ev) => setMobile(ev.target.value)} />


                </div>

                <Button
                  fullWidth={true} color="dark" children="Get OTP">
                </Button>

              </form>
            </div>
          }
          
          <hr className="mt-6 border-b-1 border-blueGray-200" />
          {otpButton && <div><Button
            fullWidth={true} onClick={() => window.location.reload(false)} color="dark" outline={true} children="sign in with credentials">
          </Button></div>}
        </div>
        {!otpButton &&
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div className="text-blueGray-500 text-center mb-3 font-bold">
              <small>{subtitle}</small>
            </div>
            <form onSubmit={handleSubmit}>
              {inputs.map((prop, key) => {
                return (
                  <div key={key} className="relative w-full">
                    <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                      {prop.label}
                    </label>
                    <Input {...prop.input} />
                  </div>
                );
              })}

              {/* <div className="mt-2 inline-block">
                <Checkbox {...checkbox} />
              </div> */}
              <div className="text-blueGray-500 text-center mb-3 font-bold">
              <small>{radioGroup.title}</small>
            </div>
              <Radio.Group onChange={radioGroup.onChange} value={radioGroup.value}>
              {radioGroup.radioButtons.map((prop, key) => {
                return (
                    <Radio value={prop.radio.value}>{prop.radio.name}</Radio>
                );
              })}
               </Radio.Group>
              

              <div className="text-center mt-5">
                <Button {...button} />
              </div>
            </form>
          </div>
        }
      </div>
      {Object.keys(forgotPassword).length === 0 &&
        Object.keys(createAccount).length === 0 ? null : (
        <div className="flex flex-wrap mt-6 relative">
          <div className="w-1/2">
            {forgotPassword && forgotPassword.to ? (
              <Link {...forgotPassword} className="text-blueGray-500 ml-2">
                <small>Forgot password?</small>
              </Link>
            ) : forgotPassword && forgotPassword.href ? (
              <a {...forgotPassword} className="text-blueGray-500 ml-2">
                <small>Forgot password?</small>
              </a>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

CardLoginSocial.defaultProps = {
  inputs: [],
  button: {},
  forgotPassword: {},
  createAccount: {},
};

CardLoginSocial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  // NOTE: the "Forgot password?" text is allready set
  forgotPassword: PropTypes.object,
  // NOTE: the "Create new account" text is allready set
  createAccount: PropTypes.object,
  // It is represetnted by the props you
  // can pass to our Button component element
  button: PropTypes.object,
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOf([
        "facebook",
        "twitter",
        "instagram",
        "github",
        "pinterest",
        "youtube",
        "vimeo",
        "slack",
        "dribbble",
        "reddit",
        "tumblr",
        "linkedin",
        'sms',
      ]),
      // It is represetnted by the props you
      // can pass to our Button component element
      // for example you can add an onClick handler
      button: PropTypes.object,
    })
  ),
  // It is represetnted by the props you
  // can pass to our Checkbox component element
  checkbox: PropTypes.object,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      // It is represetnted by the props you
      // can pass to our Input component element
      // NOTE: if you wish to generate a textarea, you will need to pass
      // // // inside this object >> type: "textarea"
      // // // full example >> input: { type: "textarea" }
      input: PropTypes.object,
    })
  ),
};
