const textProps = {
  copyright: {
    href: "https://kila.ac.in",
    target: "_blank",
    children: "Copyright © " + new Date().getFullYear() + " KILA",
  },
  socials: [
    { icon: "twitter", link: "https://twitter.com/kilathrissur" },
    { icon: "facebook", link: "https://www.facebook.com/kilatcr" },
    { icon: "instagram", link: "https://www.instagram.com/kilatcr/" },
    { icon: "youtube", link: "https://youtube.com/kilatcr" },
  ],
  links: [
    {
      href: "https://kila.ac.in",
      target: "_blank",
      children: "KILA",
    },
    {
      href: "https://www.kila.ac.in/about-us/",
      target: "_blank",
      children: "About Us",
    },
    // {
    //   href: "https://www.creative-tim.com/blog/?ref=npr-footeradmin",
    //   target: "_blank",
    //   children: "Blog",
    // },
    // {
    //   href: "https://www.creative-tim.com/license?ref=npr-footeradmin",
    //   target: "_blank",
    //   children: "Licenses",
    // },
  ],
};
export default textProps;
