import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { authHeader } from '../../_helpers';
import moment from 'moment'
// import {internalIpV6, internalIpV4} from 'internal-ip';


// import { userActions } from '../../_actions';
import axios from "axios";
import { Skeleton, List,Modal } from 'antd';
// components
// import SidebarAdmin from "components/Sidebar/SidebarAdmin.js";
// import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
// import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
import WeekCardChartJS from "components/Cards/Admin/WeekCardChartJS.js";
import CardFullTable from "components/Cards/Admin/CardFullTable.js";
// texts as props
// import sidebar from "_texts/admin/sidebar/sidebar.js";
// import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
// import headerstatcards from "_texts/admin/headers/headerstatcards.js";
// import cardchartjsdashboard1 from "_texts/admin/cards/cardchartjsdashboard1.js";
// import cardchartjsdashboard2 from "_texts/admin/cards/cardchartjsdashboard2.js";
import { Chart, Dataset } from 'react-rainbow-components';
import Button from "components/Elements/Button.js";
import { errorHandler } from "error/errorHandler";
import { toast } from 'react-toastify';


const apiUrl = process.env.REACT_APP_API_URL;


export default function Dashboard() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const user = useSelector(state => state.authentication.user);

 
  

  
  const cardchartjsdashboard1 = {
    title: "Mail Delivery ",
    subtitle: "Status",
    color: "lightBlue",
    chart: {
      type: "line",
      data: {
        labels: ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
          {
            label: new Date().getFullYear(),
            backgroundColor: "#4c51bf",
            borderColor: "#4c51bf",
            data: [65, 78, 66, 44, 56, 67, 75],
            fill: false,
            tension: 0.4,
          },
          {
            label: new Date().getFullYear() - 1,
            backgroundColor: "#fff",
            borderColor: "#fff",
            data: [40, 68, 86, 74, 56, 60, 87],
            fill: false,
            tension: 0.4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            labels: {
              color: "#FFF",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            position: "average",
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
            ticks: {
              color: "rgba(255,255,255,.7)",
            },
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
            },
            ticks: {
              color: "rgba(255,255,255,.7)",
            },
            grid: {
              drawBorder: false,
              borderWidth: 2,
              borderDash: [3],
              borderDashOffset: [3],
              color: "rgba(255, 255, 255, 0.15)",
            },
          },
        },
      },
    },
  };

  function logoutUser(e) {
    e.preventDefault();
    const { name, id } = e.target;
    const { confirm } = Modal;
    confirm({
      // icon: <ExclamationCircleOutlined />,
      content: <span>Are You Sure</span>,
      onOk() {
    // setState(state => ({ ...state, ['visible']: true }));
    axios.post(`${apiUrl}/api/auth/force-signinOut`, {id}, {
      headers: authHeader()
    }).then(response => {
      let filteredArray = onlineUsers.filter(item => item.id !== id)
     setOnlineUsers(filteredArray);
      toast.success(`Force Logout ${name}`, {
        autoClose: true
      })

    })
      .catch((error) => {
        errorHandler(error);
      });
    },
    onCancel() {
      console.log('Cancel');
    },
  });


  }
  const [response, setResponse] = useState("");


  const [daterangeAttributes, setDateRangeAttributes] = useState({
    dateRange: 'Select dates',
    isOpen: false,
    value: null,
    title: 'Select Date',
    maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
  })
  const { dateRange, isOpen, value, title, maxDate } = daterangeAttributes;
  function formatDates(dates) {
    if (dates) {
      const startDay = new Intl.DateTimeFormat().format(dates[0]);
      // var dateoptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      // const maxdate=new Date(dates[0].setDate( dates[0].getDate() + 6 )).toLocaleDateString("en-US", dateoptions)

      // setDateRangeAttributes(inputs => ({ ...daterangeAttributes, 
      //   ['maxDate']: maxdate,

      // }));
      if (dates.length > 1) {
        const endDay = new Intl.DateTimeFormat().format(dates[1]);
        return `${startDay} - ${endDay}`;
      }
      return startDay;
    }
    return '';
  }

  function handleDatePickerChange(value) {

    const formatedDate = formatDates(value);
    if (value.length > 1) {
      setDateRangeAttributes(inputs => ({
        ...daterangeAttributes,
        ['value']: value,
        ['dateRange']: formatedDate,
        ['title']: formatedDate,
        ['isOpen']: false,

      }));

    } else {
      setDateRangeAttributes(inputs => ({
        ...daterangeAttributes,
        ['value']: value,
        ['dateRange']: formatedDate,
        ['title']: formatedDate,

      }));

      // alert(dateRange)
    }
  }
  function handleDatepickerClose() {
    setDateRangeAttributes(inputs => ({
      ...daterangeAttributes,
      ['isOpen']: false,


    }));

  }
  function handleDatepickerModal() {
    setDateRangeAttributes(inputs => ({
      ...daterangeAttributes,
      ['value']: null,
      ['title']: "Select range of dates",
      ['isOpen']: true,

    }));


  }
  const [attributes, setAttributes] = useState({
    dates: '',
    sent: '',
    open: '',
    bounce: '',
    spam: '',
    dropped: '',
    invalid: '',
    unsubscribe: '',
  });
  const { dates, sent, open, bounce, spam, dropped, invalid, unsubscribe } = attributes;
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  var today = new Date();

  const cardchartjsdashboard2 = {
    title: (dateRange.length > 20) ? `Mail Statitics  ${dateRange}` : ` Mail Statitics from ${new Date(today.setDate(today.getDate() - 6)).toLocaleDateString("en-US", options)} To  ${new Date().toLocaleDateString("en-US", options)} `,
    subtitle: "Mail Delivery Status",
    input: {
      title: title,
      isOpen: isOpen,
      value: value,
      dateRange: dateRange,
      variant: "double",
      selectionType: "range",
      maxDate: maxDate,
      onChange: handleDatePickerChange,
      onRequestClose: handleDatepickerClose,
      datepickerModal: handleDatepickerModal
    },
    chart: {
      type: "bar",
      data: {
        // labels: [new Date().toLocaleDateString("en-US", options), new Date(new Date().setDate( new Date().getDate() - 1 )).toLocaleDateString("en-US", options),  new Date(new Date().setDate( new Date().getDate() - 2 )).toLocaleDateString("en-US", options), new Date(new Date().setDate( new Date().getDate() - 3 )).toLocaleDateString("en-US", options), new Date(new Date().setDate( new Date().getDate() - 4 )).toLocaleDateString("en-US", options), new Date(new Date().setDate( new Date().getDate() - 5 )).toLocaleDateString("en-US", options), new Date(new Date().setDate( new Date().getDate() - 6 )).toLocaleDateString("en-US", options)],
        labels: dates,

        datasets: [
          {
            label: " Send",
            // backgroundColor: "#ed64a6",
            backgroundColor: "#4c51bf",
            borderColor: "#ed64a6",
            // data: [30, 78, 56, 34, 100, 45, 13],
            data: sent,
            fill: true,
            barThickness: 20,
          },
          {
            label: " Opened",
            fill: false,
            backgroundColor: "#10bb94",
            borderColor: "#10bb94",
            // data: [27, 68, 86, 74, 10, 4, 87],
            data: open,
            barThickness: 20,
          },
          {
            label: " Bounce",
            fill: false,
            backgroundColor: "#fde68a",
            borderColor: "#fde68a",
            // data: [27, 68, 86, 74, 10, 4, 87],
            data: bounce,
            barThickness: 20,
          },


          {
            label: " Spam",
            fill: false,
            // backgroundColor: "#4c51bf",
            backgroundColor: "#eb3f81",
            borderColor: "#eb3f81",
            // data: [27, 68, 86, 74, 10, 4, 87],
            data: spam,
            barThickness: 20,
          },
          {
            label: " Dropped",
            fill: false,
            // backgroundColor: "#4c51bf",
            backgroundColor: "#ff917a",
            borderColor: "#ff917a",
            // data: [27, 68, 86, 74, 10, 4, 87],
            data: dropped,
            barThickness: 20,
          },
          {
            label: " Invalid",
            fill: false,
            // backgroundColor: "#4c51bf",
            backgroundColor: "#ef4444",
            borderColor: "#ef4444",
            // data: [27, 68, 86, 74, 10, 4, 87],
            data: invalid,
            barThickness: 20,
          },
          {
            label: " Unsubscribe",
            fill: false,
            // backgroundColor: "#4c51bf",
            backgroundColor: "#a8a5a8",
            borderColor: "#a8a5a8",
            // data: [27, 68, 86, 74, 10, 4, 87],
            data: unsubscribe,
            barThickness: 20,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            labels: {
              color: "rgba(0,0,0,.4)",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            position: "average",
          },
        },
        scales: {
          x: {
            display: false,
            title: {
              display: true,
            },
            ticks: {
              color: "rgba(0,0,0,.4)",
            },
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
            },
            ticks: {
              color: "rgba(0,0,0,.4)",
            },
            grid: {
              drawBorder: false,
              borderDash: [2],
              color: "rgba(33, 37, 41, 0.3)",
            },
          },
        },
      },
    },
  };

  useEffect(() => {


    if (localStorage.getItem('user') || user.hasOwnProperty('accessToken')) {
      axios.post(`${apiUrl}/api/mail/summary`, { dateRange: dateRange }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        // headers: authHeader()
      })
        .then((response) => {

          setAttributes(inputs => ({
            ...inputs,
            ['dates']: response.data.date,
            ['sent']: response.data.sent,
            ['open']: response.data.open,
            ['bounce']: response.data.bounce,
            ['spam']: response.data.spam,
            ['dropped']: response.data.dropped,
            ['invalid']: response.data.invalid,
            ['unsubscribe']: response.data.unsub,
          }));

          setResponse(response.data);
        });

    }


  }, [dateRange]);

  const [transactions, setTransactions] = useState([])
  const [onlineUsers, setOnlineUsers] = useState([])
  const [onlineUsersHead, setHead] = useState([])
 
  const cardTransaction = {
    title: "Transaction Details",
    color: "white",
    head: ["User", "Today Mail", "Today SMS", "Total Mail", "Total SMS"],
    body: transactions
  }
  const cardOnline = {
    title: "Online Users",
    color: "white",
    head: onlineUsersHead,
    body:
      onlineUsers
    // [
    //   [
    //   {
    //     image: "/notus-pro-react/static/media/bootstrap.bd712487.jpg",
    //     text: "Argon Design System",
    //   },  
    //   { color: "orange", text: "pending" },
    //   "3 January 2021 10:01 00"
    // ],
    // ]  

  }
  useEffect(() => {
    if (localStorage.getItem('user') || user.hasOwnProperty('accessToken')) {
      if (localStorage.getItem('user') && user.hasOwnProperty('accessToken')) {
        axios.post(`${apiUrl}/api/report/userTransactions`, {}, {
          headers: authHeader()
        }).then(response => {

          console.log(response.data);
          let transaction = [];
          response.data.map((prop, key) => {
            // alert(prop.p_mail)
            transaction.push([
              {
                image: prop.profile_image,
                text: prop.name,
              }, prop.today_mail_send, prop.today_sms_send, prop.total_mail_send, prop.total_sms_send
            ])

          })
          setTransactions(
            transaction
          );
        });

        axios.post(`${apiUrl}/api/report/onlineUsers`, {}, {
          headers: authHeader()
        }).then(response => {

          console.log(response.data);
          let usersList = [];
          response.data.map((prop, key) => {
            // alert(prop.p_mail)
            if(user.roles.includes('ADMIN')){
              usersList.push(
                [
                  {
                    image: prop.profile_image,
                    text: prop.name,
                  },
  
                  { color: "teal", text: "Online" },
                  moment(prop.last_login).format("Do MMMM YYYY, h:mm a"),
                  prop.login_ip,
                  `Device : ${(prop.device.platform_product===null ? "Desktop":prop.device.platform_product)}  OS : ${prop.device.platform_os.family}   Browser ${prop.device.platform_name} `,
                 
                  <Button children="Logout" color="amber" size="sm" name={prop.name} id={prop.id} onClick={logoutUser} />
                ]
              )
            }
            else{
              usersList.push(
                [
                  {
                    image: prop.profile_image,
                    text: prop.name,
                  },
  
                  { color: "teal", text: "Online" },
                  moment(prop.last_login).format("Do MMMM YYYY, h:mm a"),
                ]
              )
            }
            

          })
          setOnlineUsers(
            usersList
          )
        });
        if(user.roles.includes('ADMIN')){
          const head = ["User", "Status", "Last Login","IP","Device","Action"]
          setHead(head)
        }
        else{
          const head = ["User", "Status", "Last Login"]
          setHead(head)
        }
      }

    }

  }, []);


  const containerStyles = {
    maxWidth: 800,
  };
  const [chartTransactions, setChartTransactions] = useState([{
    month:[],
    monthValue:[]
  }])
  const { month, monthValue } = chartTransactions;
  useEffect(() => {
    if (localStorage.getItem('user') && user.hasOwnProperty('accessToken')) {
      axios.post(`${apiUrl}/api/report/chartTransactions`, {}, {
        headers: authHeader()
      }).then(response => {

        console.log(response.data);
        var month=[];
        var value = [];
        response.data.map((prop, key) => {
          month.push(prop.month)
          value.push(parseInt(prop.value))
        })
        setChartTransactions(
          {month : month,
            monthValue :value}
          );
      });
      console.log(chartTransactions)
    }

  }, [user]);
  
  const labels = month;
  const values = monthValue

  const smslabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const smsvalues = [18, 42, 58, 50, 19, 0, 24, 50, 33, 20, 5, 35]


  return (
    <>
      
      {user.roles.includes('ADMIN') &&
        <div className="flex flex-wrap">

          <div className="w-full xl:w-12/12 px-4">
            {!response &&
              <div
                className={
                  "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white"

                }
              >
                <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                  <div className="flex flex-wrap items-center">
                    <Skeleton loading={true} active >
                      <List.Item.Meta
                        // avatar={<Avatar  />}
                        title={<a ></a>}
                        description={''}
                      />
                      {''}
                    </Skeleton></div></div></div>}
            {response && <WeekCardChartJS {...cardchartjsdashboard2} />}

          </div>

        </div>
      }
      <div className="flex flex-wrap">

        <div className="w-full xl:w-6/12 px-4">
          {!transactions &&
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white"

              }
            >
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <Skeleton loading={true} active >
                    <List.Item.Meta
                      // avatar={<Avatar  />}
                      title={<a ></a>}
                      description={''}
                    />
                    {''}
                  </Skeleton></div></div></div>}
          {transactions && <CardFullTable {...cardTransaction} />}
        </div>

        <div className="w-full xl:w-6/12 px-4">
          {!onlineUsers &&
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white"

              }
            >
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <Skeleton loading={true} active >
                    <List.Item.Meta
                      // avatar={<Avatar  />}
                      title={<a ></a>}
                      description={''}
                    />
                    {''}
                  </Skeleton></div></div></div>}
          {onlineUsers && <CardFullTable {...cardOnline} />}
        </div>


      </div>
      {/* <p>
      It's <time dateTime={response}>{response}</time>
    </p>  */}
      {!user.roles.includes('ADMIN') &&
        <div className="flex flex-wrap">

          <div className="w-full xl:w-12/12 px-4">
            {!response &&
              <div
                className={
                  "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white"

                }
              >
                <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                  <div className="flex flex-wrap items-center">
                    <Skeleton loading={true} active >
                      <List.Item.Meta
                        // avatar={<Avatar  />}
                        title={<a ></a>}
                        description={''}
                      />
                      {''}
                    </Skeleton></div></div></div>}
            {response && <WeekCardChartJS {...cardchartjsdashboard2} />}

          </div>

        </div>
      }
      {/* {!user.roles.includes('ADMIN') &&
        <div className="flex flex-wrap">

          
<div className="w-full xl:w-6/12 px-4">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white "

              }
            >
              <div className="px-6 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-lg text-blueGray-500">
                      Mail Status
                    </h3>
                  </div>
                </div>
              </div>

              <div className="rainbow-p-vertical_large px-4 py-4">
                <div
                  style={containerStyles}
                  className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto"
                >
                  <Chart labels={labels} type="bar" lassName="rainbow-m-horizontal_xx-large rainbow-m-top_x-large" disableXAxisGridLines
                disableYAxisGridLines>
                    <Dataset key="Mail" title="Mail" values={values} backgroundColor="#01b6f5" />
                  </Chart>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full xl:w-6/12 px-4">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white "

              }
            >
              <div className="px-6 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-lg text-blueGray-500">
                      SMS Status
                    </h3>
                  </div>
                </div>
              </div>

              <div className="rainbow-p-vertical_large px-4 py-4">
                <div
                  style={containerStyles}
                  className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto"
                >
                  <Chart labels={smslabels} type="bar" lassName="rainbow-m-horizontal_xx-large rainbow-m-top_x-large" disableXAxisGridLines
                disableYAxisGridLines>
                    <Dataset key="SMS" title="SMS" values={smsvalues} backgroundColor="#10bb94" />
                  </Chart>
                </div>
              </div>
            </div>
          </div>

        </div>
      } */}
    </>
  );
}
