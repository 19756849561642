import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
// import {  useSelector } from 'react-redux';
// components
import CardLoginOtp from "components/Cards/Auth/CardLoginOtp.js";

import FooterAuth from "components/Footers/Auth/FooterAuth.js";
// texts
import footerauth from "_texts/auth/footers/footerauth.js";

export default function LoginOtp() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const maskEmailsPhones = require('mask-email-phone');
  // const user = useSelector(state => state.authentication.user);
  const user = JSON.parse(localStorage.getItem('user'));
  const [otp, setOtp] = useState("");

  function handleChangeOtp(e) {
    setOtp(e.target.value)
  };
  
  const textProps = {
    title: "Verify OTP",
    subtitle: (!localStorage.getItem('user')) ? "" :  user.otpMethod === 1 ? "Enter the OTP recived in your mobile" : "Enter the OTP recived in your Email" ,
    mobileMask:(!localStorage.getItem('user')) ? "" :  user.otpMethod === 1 ? maskEmailsPhones(user.mobile) : "" ,
    email:(!localStorage.getItem('user')) ? "" :  user.otpMethod === 2 ? maskEmailsPhones(user.email) : "",
    // title:"sign in with credentials",
    button: {
      fullWidth: true,
      color: "teal",
      children: "Verify",
    },
    resendButton: {
      // fullWidth: true,
      color: "dark",
      children: "Resend",
    },
    forgotPassword: {
      href: "#pablo",
    },
    createAccount: {
      href: "#pablo",
    },
    checkbox: {
      label: "Remember me",
    },
    inputs: [
      {
        // label: "OTP",
        input: {
          type: "text",
          placeholder: "Enter 6 Digit OTP",
          name: "otp",
          value:otp,
          onChange:handleChangeOtp,

        },
      },
    ],
  };
  const inputData = {
    inputVal: {
      otp: otp,
    }
  };
  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">

          <div
            className="absolute top-0 w-full h-full bg-light-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                // "url(" + require("assets/img/login-otp1.png").default + ")",
                "url(" + require("assets/img/loginbg.png").default + ")",
            }}
          >
          </div>

          <div className="container mx-auto px-2 h-full">
            <div className="flex  h-full">
              <div className="w-full lg:w-4/12 px-2">
              {/* <CardOtpVerify {...props} /> */}
              <CardLoginOtp {...textProps} {...inputData} />
              </div>
            </div>
          </div>
          <FooterAuth {...footerauth} />
        </section>
      </main>
    </>
  );
}
