import { toast } from 'react-toastify';

export function  errorHandler  (error)
{
    if (error.response.status === 500) {
    //   toast.error(`${error.response.data.message}`, {
    //   });
      toast.error('Something Went Wrong. Server Error Occured', {
    });
    }
    if (error.response.status === 401) {
      toast.error(`Unauthorized Please login`, {
        // position: "top-center",
        // autoClose: true,
        // hideProgressBar: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // draggable: true,
        // progress: 0,
      });
    }
    if (error.response.status === 403) {
      toast.error(`Token Expired, Please login`, {
        // position: "top-center",
        // autoClose: true,
        // hideProgressBar: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // draggable: true,
        // progress: 0,
      });
    }


    return Promise.reject({ ...error })
  }