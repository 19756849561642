import React,{ useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {  useSelector } from 'react-redux';
import { authHeader } from '../../_helpers';
import { errorHandler } from "error/errorHandler";
import { toast } from 'react-toastify';



// import { userActions } from '../../_actions';
import axios from "axios";
import { Skeleton, List,Spin } from 'antd';
// components

import CardProfileFullPage from 'components/Cards/ECommerce/CardProfileFullPage'
import CardSettingsLarge from "components/Cards/Admin/CardSettingsLarge"



const apiUrl=process.env.REACT_APP_API_URL;



export default function Profile() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const user = useSelector(state => state.authentication.user);
  
  
  

  const [userProfile, setUserProfile] = useState("");

  const [Loader, setLoader] = useState(false);
  const [attributes, setAttributes] = useState({
    id: '',
    name: '',
    email: '',
    mobile: '',
    username: '',
    landline: '',
    designation : '',
    password:'',
    conf_password:''
  });
  const { id,name, email, mobile, username, landline,designation,password,conf_password} = attributes;
  function handleChange(e) {

    const { name, value } = e.target;
      setAttributes(attributes => ({ ...attributes, [name]: value }));
  }
  function handleProfileUpdate(e) {
    try {
      var dataObj;
      if(password!==''){
        if(password.length<8){
          return  toast.error("Password Should be 8 Charecter", {
            
          });
        }
        else{
          if(password!==conf_password){
            return  toast.error("Password Does not match", {
              
            });
          }
          else{
            dataObj={
              name:name,
              email:email,
              mobile:mobile,
              landline:landline,
              designation:designation,
              password:password
            }
          }
        }
      }
      else{
        dataObj={
          name:name,
          email:email,
          mobile:mobile,
          landline:landline,
          designation:designation
        }
      }
      // if(action==='send'){
      axios.put(`${apiUrl}/api/users/enduserPassword/${id}`,
        // name:name,
        // email:email,
        // mobile:mobile,
        // landline:landline
        dataObj
      , {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then((response) => {
          toast.success("Profile updated", {
            
          });

        }).catch(error => {
            setLoader(false);
            errorHandler(error);

          });
        } catch (error) {
          console.log(error.response); // this is the main part. Use the response property from the error object
          setLoader(false);
          return error.response;
         
        }
  }
  
  useEffect(() => {
    
    if (localStorage.getItem('user') || user.hasOwnProperty('accessToken')) {
      axios.get(`${apiUrl}/api/users/enduser/${user.id}`, { },  {
        headers: authHeader()
      })
        .then((response) => {
         
          setAttributes(inputs => ({ ...inputs, 
            ['id']: response.data.id,
            ['name']: response.data.name,
            ['email']: response.data.email,
            ['username']: response.data.username,
            ['designation']: response.data.designation,
            ['mobile']: response.data.mobile,
            ['landline']: response.data.landline,
           
          }));
       
          setUserProfile(response.data);
        });

        
    
      }
      

  }, []);
  const cardsettingslarge = {
    title: "Profile Settings",
    button: { children: "Save change", size: "sm", color: "lightBlue", onClick:handleProfileUpdate },
    forms: [
      {
        title: "Profile Details",
        inputs: [
          {
            label: "First Name",
            width: 4,
            input: {
              placeholder: "First Name",
              type: "text",
              defaultValue: "Lucky",
              disabled:true,
              value:name,
              name:'name',
              onChange:handleChange
            },
          },
          {
            label: "Designation",
            width: 4,
            input: {
              placeholder: "Designation",
              type: "text",
              defaultValue: "Designation",
              disabled:true,
              value:designation,
              name:'designation',
              onChange:handleChange
            },
          },
          {
            label: "Username",
            width: 4,
            input: {
              placeholder: "Username",
              type: "text",
              defaultValue: "lucky.jesse",
              value:username,
              name:'username',
              disabled:true,
              onChange:handleChange
            },
          },
         
          
          
        ],
      },
      {
        title: "Contact Information",
        inputs: [
          {
            label: "Email Address",
            width: 4,
            input: {
              placeholder: "Email",
              type: "email",
              defaultValue: "jesse@example.com",
              value:email,
              name:'email',
              disabled:true,
              onChange:handleChange
            },
          },
          {
            label: "Mobile",
            width: 4,
            input: {
              placeholder: "Mobile",
              type: "text",
              defaultValue: "",
              value:mobile,
              name:'mobile',
              disabled:true,
              onChange:handleChange
            },
          },
          {
            label: "Land Line",
            width: 4,
            input: {
              placeholder: "Land Line",
              type: "text",
              defaultValue: "",
              value:landline,
              name:'landline',
              disabled:true,
              onChange:handleChange
            },
          },
         
        ],
      },
      {
        title: "Reset Password",
        inputs: [
          {
            label: "New Password",
            width: 6,
            input: {
              placeholder: "Password",
              type: "password",
              // defaultValue: "jesse@example.com",
              // value:password,
              name:'password',
              value:password,
              onChange:handleChange
            },
          },
          {
            label: "Confirm password",
            width: 6,
            input: {
              placeholder: "Password",
              type: "password",
              // defaultValue: "jesse@example.com",
              // value:conf_password,
              value:conf_password,
              name:'conf_password',
              onChange:handleChange
            },
          },
         
        ],
      },
    
    ],
  }
  const cardprofilefullpage = {
    image: (!userProfile) ? "" : userProfile.profile_image,
    name:  (!userProfile) ? "" : userProfile.name,
    location: "",
    
    buttons: [
      // { children: "Edit", color: "lightBlue", size: "sm" },
      // { children: "Message", color: "dark", size: "sm" },
    ],
    achievements: [
      {
        icon: "fas fa-envelope-open-text",
        text: (!userProfile) ? "" : userProfile.username,
      },
      { icon: "fas fa-mobile-alt", text: (!userProfile) ? "" : userProfile.mobile, },
      { icon: "fas fa-briefcase", text: (!userProfile) ? "" : userProfile.designation,},
    ],
  }
  
 
  
  return (
    <>
      {/* <SidebarAdmin  />
      <div className="relative md:ml-64 bg-blueGray-100">
        <NavbarSearchUser {...navbarsearchuserDashboard} />
        <HeaderStatCards {...headerstatcards} /> */}
        {/* <div className="px-4 md:px-10 mx-auto w-full -mt-24"> */}
      
          <div className="flex flex-wrap">
           
            <div className="w-full xl:w-8/12 px-4 py-6">
              {!userProfile &&   
               <div
               className={
                 "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white" 
      
               }
             >
               <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                 <div className="flex flex-wrap items-center">
              <Skeleton loading={true} active >
                <List.Item.Meta
                  // avatar={<Avatar  />}
                  title={<a ></a>}
                  description={''}
                />
                {''}
              </Skeleton>
              </div>
              </div>
              </div>}
              {/* {response && <WeekCardChartJS {...cardchartjsdashboard2} />} */}
             {/* {userProfile && <CardProfileFullPage {...cardprofilefullpage} />}  */}
             <Spin spinning={Loader} tip="Updating...">
              <CardSettingsLarge {...cardsettingslarge} />
            </Spin>
            </div>
            <div className="w-full lg:w-4/12 px-4 py-12">
              <CardProfileFullPage {...cardprofilefullpage} />
              
    
    
            </div>
            
          </div>
         
          

    
           
            
          
         
         
    </>
  );
}
