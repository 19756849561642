import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import axios from "axios";
import { errorHandler } from "error/errorHandler";
import 'antd/dist/antd.css';

// components
// import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { DateTimePicker, HelpText } from "react-rainbow-components";
import { authHeader } from '../../../_helpers';
import Heading2 from "components/Headings/Heading2.js";
import Hero1 from "components/Sections/Presentation/Heroes/Hero1"
// import Accordion from "components/Accordions/Accordion"
import Button from "../../Elements/Button.js";
import Input from "../../Elements/Input.js";
// import Select from "../../Elements/Select.js";
import Checkbox from "components/Elements/Checkbox";
import DropdownButton from "../../Dropdowns/DropdownButton.js";
import ChipInput2 from "../../Elements/ChipInput2.js";
import Badge from "../../Elements/Badge.js";



import { MultiSelect } from "react-multi-select-component";


import { Upload, message, Spin, Select, Card, Tooltip } from 'antd';
import { Form, InputNumber, Popconfirm, Table, Typography, Tag } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Drawer } from 'react-rainbow-components';

import { Accordion, AccordionSection, Chip } from 'react-rainbow-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faUsers, faCog } from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import platform from 'platform';

import MailSendSpinner from "animations/MailSendSpinner";
import MailVerifySpinner from "animations/MailVerifySpinner";
import moment from 'moment'


import { read, utils, writeFile } from 'xlsx';





const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `User ${i}`,
    email_pri: `sample${i}@gmail.com`,
    email_sec: `sample${i + 1}@gmail.com`,
    attachment_name: `London Park no. ${i}.pdf`,

  });
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};



const editorConfiguration = {
  // plugins: [ Bold, Italic, Underline, Strikethrough, Code, Subscript, Superscript ],

  toolbar: [
    // 'heading',
    // 'bold',
    // 'italic',

    // 'underline', 'strikethrough', 'code', 'subscript', 'superscript',
    // '|',
    // 'fontfamily',
    // 'fontsize',
    // 'link',
    // 'highlight',
    // 'bulletedList',
    // 'numberedList',
    // 'insertTable',
    // 'uploadImage',
    // 'blockQuote',
    // 'todoList',
    // 'alignment',
    // 'horizontalLine',
    // 'findAndReplace',
    // 'undo',
    // 'redo'
    'heading', '|',
    'fontfamily', 'fontsize', '|',
    'alignment', '|',
    'fontColor', 'fontBackgroundColor', '|',
    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
    'link', '|',
    'outdent', 'indent', '|',
    'bulletedList', 'numberedList', 'todoList', '|',
    // 'code', 'codeBlock', '|',
    'highlight', 'selectAll',
    'horizontalLine', 'findAndReplace', '|',
    'insertTable', '|',
    'uploadImage', 'blockQuote', '|',
    'undo', 'redo',

  ],

  //   alignment: {
  //     options: [ 'left', 'right','center','justify' ]
  // },
  shouldNotGroupWhenFull: false,
  // table: {
  //   defaultHeadings: { rows: 1, columns: 1 },
  //   contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  // },
  // alignment: {
  //   options: ['left', 'right']
  // },
};
const { Option, OptGroup } = Select;


export default function CardBulkEmail() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const user = useSelector(state => state.authentication.user);


  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;

  const handleImport = ($event) => {
    const files = $event.target.files;

    let cnt = 0;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          rows.map((prop, key) => {
            console.log('att' + prop.email_pri)
            if ((selectedEmailtype == 1 && (prop.attachment_name == '' || prop.attachment_name == null || prop.attachment_name == 'undefined')) || (prop.email_pri == '' || prop.email_pri == null || prop.email_pri == 'undefined')) {
              setTablePreview(false);
              setTableLoading(false)
              message.error("excel not valid, Mandatory Fields are missing");
              cnt++;
            }

          })

          if (cnt == 0) {
            setData(rows)
            setTablePreview(true);
            setTableLoading(false)
          }




        }

      }
      reader.readAsArrayBuffer(file);
    }
  }


  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      email_pri: '',
      email_sec: '',
      attachment_name: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      width: '5%',
      editable: true,
      classNamecol: 'green'
    },
    {
      title: 'Email Primary',
      dataIndex: 'email_pri',
      width: '10%',
      editable: true,
      classNamecol: 'green'
    },
    {
      title: 'Email Secondary',
      dataIndex: 'email_sec',
      width: '10%',
      editable: true,
      classNamecol: 'green'
    },
    {
      title: 'Attachment 1',
      dataIndex: 'attachment_name',
      width: '5%',
      editable: false,
      classNamecol: 'green',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Attachment 2',
      dataIndex: 'attachment_name2',
      width: '5%',
      editable: false,
      classNamecol: 'green',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Attachment 3',
      dataIndex: 'attachment_name3',
      width: '5%',
      editable: false,
      classNamecol: 'green',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Var 1',
      dataIndex: 'variable1',
      width: '5%',
      editable: false,
      classNamecol: 'green',
      render: (text) => <span style={{color:"grey"}}>{text}</span>,
    },
    {
      title: 'Var 2',
      dataIndex: 'variable2',
      width: '5%',
      editable: false,
      classNamecol: 'green',
      render: (text) => <span style={{color:"grey"}}>{text}</span>,
    },
    {
      title: 'Var 3',
      dataIndex: 'variable3',
      width: '5%',
      editable: false,
      classNamecol: 'green',
      render: (text) => <span style={{color:"grey"}}>{text}</span>,
    },
    {
      title: 'Attachment Status',
      dataIndex: 'attachment_status',
      width: '5%',
      editable: false,
      classNamecol: 'green',

      render: (text) => <span style={{ color: (text === 1 ? "green" : text === 0 ? "#d9534f" : "#f0ad4e") }}>{(text === 1 ? "Uploaded" : "Pending")}</span>,

    },
    {
      title: 'Attachment Status 2',
      dataIndex: 'attachment_status2',
      width: '5%',
      editable: false,
      classNamecol: 'green',

      render: (text) => <span style={{ color: (text === 1 ? "green" : text === 0 ? "#d9534f" : "#f0ad4e") }}>{(text === 1 ? "Uploaded" : "Pending")}</span>,

    },
    {
      title: 'Attachment Status 3',
      dataIndex: 'attachment_status3',
      width: '5%',
      editable: false,
      classNamecol: 'green',

      render: (text) => <span style={{ color: (text === 1 ? "green" : text === 0 ? "#d9534f" : "#f0ad4e") }}>{(text === 1 ? "Uploaded" : "Pending")}</span>,

    },
    {
      title: 'Primary Status',
      dataIndex: 'email_pri_status',
      width: '5%',
      editable: false,
      classNamecol: 'green',
      render: (text) => <span style={{ color: (text === 1 ? "green" : text === 0 ? "#d9534f" : text === 2 ? "#d9534f" : "#f0ad4e") }}>{(text === 1 ? "Valid" : text === 0 ? "Invalid" : text === 2 ? "Blocked" : "Pending")}</span>,
    },
    {
      title: 'Secondary Status',
      dataIndex: 'email_sec_status',
      width: '5%',
      editable: false,
      classNamecol: 'green',
      render: (text) => <span style={{ color: (text === 1 ? "green" : text === 0 ? "#d9534f" : text === 2 ? "#d9534f" : "#f0ad4e") }}>{(text === 1 ? "Valid" : text === 0 ? "Invalid" : text === 2 ? "Blocked" : "Pending")}</span>,
    },
    // {
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => save(record.key)}
    //           style={{
    //             marginRight: 8,
    //           }}
    //         >
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
    //         Edit
    //       </Typography.Link>
    //     );
    //   },
    // },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        // className:col.classNamecol
      }),
    };
  });







  let max_file_size = user.privillege.attachmentSize;
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  // max_file_size = (max_file_size * 1024 * 1024);
  // console.log(max_file_size);
  const widths = {
    1: "lg:w-1/12",
    2: "lg:w-2/12",
    3: "lg:w-3/12",
    4: "lg:w-4/12",
    5: "lg:w-5/12",
    6: "lg:w-6/12",
    7: "lg:w-7/12",
    8: "lg:w-8/12",
    9: "lg:w-9/12",
    10: "lg:w-10/12",
    11: "lg:w-11/12",
    12: "lg:w-12/12",
  };

  const [inputs, setInputs] = useState({
    subject: '',
    to: '',
    cc: '',
    bcc: '',
    emailPaste: '',
  });
  const { subject, to, cc, bcc, emailPaste } = inputs;
  const [checkbox, setCheckbox] = useState({
    primary: true,
    secondary: false,
  });
  const { primary, secondary } = checkbox;
  function handleCheckboxChange(e) {

    const { name, checked } = e.target;

    setCheckbox(Checkbox => ({ ...Checkbox, [name]: checked }));
  }
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const handleAddressListopen = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const onCloseModal = (e) => {
    e.preventDefault();
    setShowModal(false);
    setPrimaryMailList([])
    setSecondaryMailList([])
    setSelectedInstitution([])
    setSelectedDistrict([])
    setSelectedInstType([])
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: 0 }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: 0 }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: 0 }));
    setValidEmailList([])
    setInvalidEmailList([])
    setBlockedEmailList([])
  };


  const [Loader, setLoader] = useState(false);
  const [VerifyLoader, setVerifyLoader] = useState(false);
  // const [value, setValue] = useState('');
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [blackList, setBlackList] = useState(false);
  const [CCitems, setCCItems] = useState([]);
  const [CCerror, setCCError] = useState(null);
  const [BCCitems, setBCCItems] = useState([]);
  const [BCCerror, setBCCError] = useState(null);

  const [mailCount, setmailCount] = useState(0);
  // const [CCmailCount, setCCmailCount] = useState(0);
  // const [BCCmailCount, setBCCmailCount] = useState(0);
  const [CCchecked, setccChecked] = useState(false);

  const [date, setDate] = useState(null);
  const inputStyles = {
    maxWidth: 320,
    display: 'inline-block',
    // paddingeft: 20,
    paddingRight: 20,



  };

  const updateState = (object, link, status, key) => {
    const newState = data.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.key === key) {
        // console.log("inside");
        return { ...obj, link: link, attachment_status: status };

      }

      // 👇️ otherwise return the object as is
      return obj;
    });

    setData(newState);
    console.log(data)
  };

  const updatedataMail = (key, status, type) => {

    const newState = data.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.key === key) {
        // console.log("email and staus"+email_pri)
        return { ...obj, type: status }

      }
      return obj;
    });

    setData(newState);

  };

  let downloadFile = (file) => {
    message.success("file downloaded.")
    window.open(`${apiUrl}/files/` + file, '_blank', 'noreferrer');
  }
  let downloadSample = (file) => {
    message.success("file downloaded.")
    window.open(`${apiUrl}/files/excel_template.xlsx`, '_blank', 'noreferrer')
  }




  const { Dragger } = Upload;
  const [selectedFile, setSelectedFile] = useState([]);
  const uploadprops = {
    name: 'file',
    multiple: true,
    action: `${apiUrl}/upload`,
    listType: "picture",
    // maxCount: user.privillege.attachmentLimit,
    FileList: selectedFile,
    // customRequest(){},
    beforeUpload(file) {
      console.log(file)
      // alert(file.type)
      var fileExt = file.name.split('.').pop();
      console.log('bbbbb' + blacklistExt)
      const isBlacklist = blacklistExt.some(item => '.' + fileExt === item);
      // const isBlacklist = file.type === 'application/json';
      if (isBlacklist) {
        message.error(`You can't upload  file ${blacklistExt.join(',')} extension files}`);
      }
      let isLt2M = true;


      if (!isLt2M) {
        message.error('File size too large maximum Total File must be ' + user.privillege.attachmentSize + ' MB');
      }

      const isList = data.some(item => file.name === item.attachment_name)
      const isList2 = data.some(item => file.name === item.attachment_name2)
      const isList3 = data.some(item => file.name === item.attachment_name3)
      // if (!isList || !isList2 || !isList3) {

      //   message.error("Selected file not in excel list, ");
      // }


      // return isJpgOrPng && isLt2M;
      // return (isLt2M && !isBlacklist || Upload.LIST_IGNORE) && (isList || isList2 || isList3);
      return (isLt2M && !isBlacklist || Upload.LIST_IGNORE) ;
    },
    onRemove(file) {
      // if (data.some(item => file.response.filename === item.link)) {
      //   let index = data.findIndex(element => {
      //     if (element.link === file.response.filename) {
      //       return true;
      //     }
      //   });

      //   updateState(file.name, file.response.filename, null, data[index].key)

      // } else {
      //   message.error("not in list");
      // }

      var dataObj;
      let dataArray=[];
      data.map((element, index) => {

        
        if (element.link === file.response.filename ) {
         dataObj={
          key:element.key,
          name:element.name,
          email_pri:element.email_pri,
          email_sec:element.email_sec,
          email_sec_status:element.email_sec_status,
          email_pri_status:element.email_pri_status,
          attachment_name:element.attachment_name,
          attachment_name2:element.attachment_name2,
          attachment_name3:element.attachment_name3,
          link:file.response.filename,
          link2:element.link2,
          link3:element.link3,
          attachment_status:null,
          attachment_status2:element.attachment_status2,
          attachment_status3:element.attachment_status3,
          variable1:element.variable1,
          variable2:element.variable2,
          variable3:element.variable3,

         }
        }
        else if (element.link2 === file.response.filename ) {
          dataObj={
           key:element.key,
           name:element.name,
           email_pri:element.email_pri,
           email_sec:element.email_sec,
           email_sec_status:element.email_sec_status,
           email_pri_status:element.email_pri_status,
           attachment_name:element.attachment_name,
           attachment_name2:element.attachment_name2,
           attachment_name3:element.attachment_name3,
           link2:file.response.filename,
           link:element.link,
           link3:element.link3,
           attachment_status:element.attachment_status,
           attachment_status2:null,
           attachment_status3:element.attachment_status3,
           variable1:element.variable1,
           variable2:element.variable2,
           variable3:element.variable3,
 
          }
         }
         else if (element.link3 === file.response.filename ) {
          dataObj={
           key:element.key,
           name:element.name,
           email_pri:element.email_pri,
           email_sec:element.email_sec,
           email_sec_status:element.email_sec_status,
           email_pri_status:element.email_pri_status,
           attachment_name:element.attachment_name,
           attachment_name2:element.attachment_name2,
           attachment_name3:element.attachment_name3,
           link:element.link,
           link2:element.link,
           link3:file.response.filename,
           attachment_status:element.attachment_status,
           attachment_status2:element.attachment_status2,
           attachment_status3:null,
           variable1:element.variable1,
           variable2:element.variable2,
           variable3:element.variable3,
 
          }
         }
        else{
          dataObj={
            key:element.key,
            name:element.name,
            email_pri:element.email_pri,
            email_sec:element.email_sec,
            email_sec_status:element.email_sec_status,
            email_pri_status:element.email_pri_status,
            attachment_name:element.attachment_name,
            attachment_name:element.attachment_name,
            attachment_name2:element.attachment_name2,
            attachment_name3:element.attachment_name3,
            link:element.link,
            link2:element.link2,
            link3:element.link3,
            attachment_status:element.attachment_status,
            attachment_status2:element.attachment_status2,
            attachment_status3:element.attachment_status3,
            variable1:element.variable1,
            variable2:element.variable2,
            variable3:element.variable3,

           }
        }
        dataArray.push(dataObj)
      })
      setData(dataArray)



      const newFilelist = selectedFile.filter((files) => files.name !== file.name);
      setSelectedFile(newFilelist)
      const body = { "file": file.response.filename };
      axios.post(`${apiUrl}/remove`, body, {
        headers: { "Access-Control-Allow-Origin": "*" }
      })
        .then((response) => {
          console.log(response.data);

        })
    },
    onChange(info) {
      setWait(true)

      console.log(info.file.size, fileSize);
      // console.log("total size", info.file.size + fileSize);
      // if(info.file.size+fileSize < max_file_size){
      const { status } = info.file;
      console.log(status)
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
    
        // console.log("info full"+info.file.response.filename)
        setSelectedFile([...selectedFile, { name: info.file.name, file: info.file.response.filename }])
        setFilesize(fileSize + info.file.size)
        setWait(false)
        console.log(selectedFile)
        message.success(`${info.file.name} file uploaded successfully.`);
        // if (data.some(item => info.file.name === item.attachment_name)) {
        //   let index = data.findIndex(element => {
        //     if (element.attachment_name === info.file.name) {
        //       return true;
        //     }
        //   });
        //   console.log('Maria is at index: ' + data[index].key);
        //   updateState("Download", info.file.response.filename, 1, data[index].key)


        // } else {
        //   message.error("not in list");
        // }
        var dataObj;
        console.log(data)
        let dataArray=[];
        data.map((element, index) => {
          if (element.attachment_name === info.file.name ) {
           dataObj={
            key:element.key,
            name:element.name,
            email_pri:element.email_pri,
            email_sec:element.email_sec,
            email_sec_status:element.email_sec_status,
            email_pri_status:element.email_pri_status,
            attachment_name:element.attachment_name,
            attachment_name2:element.attachment_name2,
            attachment_name3:element.attachment_name3,
            link:info.file.response.filename,
            link2:element.link2,
            link3:element.link3,
            attachment_status:1,
            attachment_status2:element.attachment_status2,
            attachment_status3:element.attachment_status3,
            variable1:element.variable1,
            variable2:element.variable2,
            variable3:element.variable3,

           }
          }
          else if (element.attachment_name2 === info.file.name ) {
            dataObj={
             key:element.key,
             name:element.name,
             email_pri:element.email_pri,
             email_sec:element.email_sec,
             email_sec_status:element.email_sec_status,
             email_pri_status:element.email_pri_status,
             attachment_name:element.attachment_name,
             attachment_name2:element.attachment_name2,
             attachment_name3:element.attachment_name3,
             link:element.link,
             link2:info.file.response.filename,
             link3:element.link3,
             attachment_status:element.attachment_status,
             attachment_status2:1,
             attachment_status3:element.attachment_status3,
             variable1:element.variable1,
             variable2:element.variable2,
             variable3:element.variable3,
 
            }
           }
           else if (element.attachment_name3 === info.file.name ) {
            dataObj={
             key:element.key,
             name:element.name,
             email_pri:element.email_pri,
             email_sec:element.email_sec,
             email_sec_status:element.email_sec_status,
             email_pri_status:element.email_pri_status,
             attachment_name:element.attachment_name,
             attachment_name2:element.attachment_name2,
             attachment_name3:element.attachment_name3,
             link:element.link,
             link2:element.link2,
             link3:info.file.response.filename,
             attachment_status:element.attachment_status,
             attachment_status2:element.attachment_status2,
             attachment_status3:1,
             variable1:element.variable1,
             variable2:element.variable2,
             variable3:element.variable3,
 
            }
           }
          else{
            dataObj={
              key:element.key,
              name:element.name,
              email_pri:element.email_pri,
              email_sec:element.email_sec,
              email_sec_status:element.email_sec_status,
              email_pri_status:element.email_pri_status,
              attachment_name:element.attachment_name,
              attachment_name2:element.attachment_name2,
              attachment_name3:element.attachment_name3,
              link:element.link,
              link2:element.link2,
              link3:element.link3,
              attachment_status:element.attachment_status,
              attachment_status2:element.attachment_status2,
              attachment_status3:element.attachment_status3,
              variable1:element.variable1,
              variable2:element.variable2,
              variable3:element.variable3,
  
             }
          }
          
          dataArray.push(dataObj)
        })
        setData(dataArray)
         

      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        setWait(false)
      }

    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },

    // showUploadList: {
    //   showDownloadIcon: true,
    //   // downloadIcon: 'download ',
    //   showRemoveIcon: true,
    //   // removeIcon: <Button onClick={e => console.log(e, 'custom removeIcon event')} />,
    // },
  };

  const [selectedExcelFile, setSelectedExcelFile] = useState([]);
  const uploadpropsExcel = {
    name: 'file',
    multiple: false,
    action: `${apiUrl}/upload`,
    listType: "picture",
    maxCount: 1,
    FileList: selectedExcelFile,
    // customRequest(){},
    beforeUpload(file) {
      console.log(file)
      // alert(file.type)
      var fileExt = file.name.split('.').pop();
      console.log('bbbbb' + blacklistExt)
      const isBlacklist = blacklistExt.some(item => '.' + fileExt === item);
      // const isBlacklist = file.type === 'application/json';
      if (isBlacklist) {
        message.error(`You can't upload  file ${blacklistExt.join(',')} extension files}`);
      }
      let isLt2M;
      if (fileSize > 0) {
        isLt2M = (file.size / 1024 / 1024 + fileSize / 1024 / 1024) < max_file_size;
      }
      else {
        isLt2M = (file.size / 1024 / 1024) < max_file_size;
      }

      // const isLt2M1 = (file.size / 1024 / 1024) < max_file_size;
      // alert(file.size / 1024 / 1024)
      if (!isLt2M) {
        message.error('File size too large maximum Total File must be ' + user.privillege.attachmentSize + ' MB');
      }
      // return isJpgOrPng && isLt2M;
      return isLt2M && !isBlacklist || Upload.LIST_IGNORE;
    },
    onRemove(file) {
      console.log(file)
      // console.log(info.file, info.fileList);
      // const body = new FormData();
      // body.append('file', file.name);
      const newFilelist = selectedExcelFile.filter((files) => files.name !== file.name);
      setSelectedExcelFile(newFilelist)
      const body = { "file": file.response.filename };
      axios.post(`${apiUrl}/remove`, body, {
        headers: { "Access-Control-Allow-Origin": "*" }
      })
        .then((response) => {
          console.log(response.data);

        })
    },
    onChange(info) {
      setWait(true)

      console.log(info.file.size, fileSize);
      // console.log("total size", info.file.size + fileSize);
      // if(info.file.size+fileSize < max_file_size){
      const { status } = info.file;
      console.log(status)
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        // console.log("info full"+info.file.response.filename)
        setSelectedExcelFile([...selectedExcelFile, { name: info.file.name, file: info.file.response.filename }])
        // setFilesize(fileSize + info.file.size)
        setWait(false)
        console.log(selectedExcelFile)
        message.success(`${info.file.name} file uploaded successfully.`);
        setTablePreview(true);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        setWait(false)
      }

    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },

    // showUploadList: {
    //   showDownloadIcon: true,
    //   // downloadIcon: 'download ',
    //   showRemoveIcon: true,
    //   // removeIcon: <Button onClick={e => console.log(e, 'custom removeIcon event')} />,
    // },
  };

  function onChangeDate(date) {
    // alert(date)
    var beginningTime = moment(moment(date).format("YYYY-MM-DD HH:mm"), 'YYYY-MM-DD HH:mm');
    var endTime1 = moment(moment(moment().format("YYYY-MM-DD HH:mm"), 'YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
    var endTime2 = moment(moment(moment().add(48, 'hours').format("YYYY-MM-DD HH:mm"), 'YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
    // console.log("Beg Time = "+beginningTime.toString());
    // console.log("End Time1 = " +endTime1.toString());
    // console.log("End Time2 = "+endTime2.toString());
    const dateTrue = beginningTime.isBetween(endTime1, endTime2);
    if (dateTrue) {
      setDate(date);
    }
    else {
      toast.warn("Maximum Schedule time is 48 hours", {
        // position: toast.POSITION.TOP_CENTER
      });
      setDate('');
    }

  }
  function resetDate() {
    setDate('');
  }

  const toggleChecked = (e) => {
    e.preventDefault();
    setccChecked(value => !value)
  };

  const [BCCchecked, setbccChecked] = useState(false);
  const togglebccChecked = (e) => {
    e.preventDefault();
    setbccChecked(value => !value)
  };

  const handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      const { name } = evt.target;
      if (name === 'to') {
        var toVal = { mail: to.trim(), name: to.trim() };
        if (toVal && isValid(toVal, name)) {
          setItems([...items, ...toVal])
          setmailCount(mailCount + 1)
          console.log(items);
          // setValue('')

        }
      }
      if (name === 'cc') {
        var ccVal = { mail: cc.trim(), name: cc.trim() };
        if (ccVal && isValid(ccVal, name)) {
          setCCItems([...CCitems, ...ccVal])
          setmailCount(mailCount + 1)
          console.log(CCitems);
          // setValue('')
        }
      }
      if (name === 'bcc') {
        var bccVal = { mail: bcc.trim(), name: bcc.trim() };
        if (bccVal && isValid(bccVal, name)) {
          setBCCItems([...BCCitems, ...bccVal])
          setmailCount(mailCount + 1)
          console.log(BCCitems);
          // setValue('')
        }
      }
      setInputs(inputs => ({ ...inputs, [name]: '' }));

    }
  };



  const handleDelete = evt => {
    evt.preventDefault();
    let id = evt.target.id;
    const { name } = evt.target;
    if (name === 'to')
      setItems(items.filter(i => i.email !== id))
    if (name === 'cc')
      setCCItems(CCitems.filter(i => i.email !== id))
    if (name === 'bcc')
      setBCCItems(BCCitems.filter(i => i.email !== id))
    setmailCount(mailCount - 1)
    toast.warn(id + "  Removed from the list!", {
      // position: toast.POSITION.TOP_CENTER
    });

  };
  const handlePaste = evt => {
    evt.preventDefault();
    toast.warn("Direct copy paste facility disabled use 'Copy/Paste' Button to paste mail IDs", {

    });
    return false;

    // var paste = evt.clipboardData.getData("text");
    // var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    // const { name, value } = evt.target;
    // if (emails) {
    //   var toBeAdded = emails.filter(email => !isInList(email, name));
    //   if (name == 'to')
    //     setItems([...items, ...toBeAdded])
    //   if (name == 'cc')
    //     setCCItems([...CCitems, ...toBeAdded])
    //   if (name == 'bcc')
    //     setBCCItems([...BCCitems, ...toBeAdded])

    //   setmailCount(mailCount + toBeAdded.length)
    //   toast.success(toBeAdded.length + " Recipients Added to the list", {
    //     // position: toast.POSITION.TOP_CENTER
    //   });
    //   // const missing = emails.filter((i => a => a !== toBeAdded[i] || !++i)(0));
    //   // toast.warn(missing.length+" not added", {
    //   //   // position: toast.POSITION.BOTTOM_LEFT
    //   // });

    // }
  };
  const [mailCheckCount, setMailCheckCount] = useState({
    validCount: 0,
    invalidCount: 0,
    blockedCount: 0,

  });
  const { validCount, invalidCount, blockedCount } = mailCheckCount;
  const [validEmailList, setValidEmailList] = useState([]);
  const [invalidEmailList, setInvalidEmailList] = useState([]);
  const [blockedEmailList, setBlockedEmailList] = useState([]);
  const handlePasteDraweropen = (e) => {
    e.preventDefault();
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: [] }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: [] }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: [] }));
    setValidEmailList([])
    setInvalidEmailList([])
    setBlockedEmailList([])
    setPrimaryMailList([]);
    setInputs(inputs => ({ ...inputs, ['emailPaste']: '' }));
    setVisible(true);
  };
  const handlePasteDrawerclose = (e) => {
    // e.preventDefault();
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: [] }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: [] }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: [] }));
    setValidEmailList([])
    setInvalidEmailList([])
    setBlockedEmailList([])
    setPrimaryMailList([]);
    setInputs(inputs => ({ ...inputs, ['emailPaste']: '' }));
    setVisible(false);
    setVerifyLoader(false)
  };

  const handleBulkPaste = evt => {

    setLoader(true)
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");

    if (paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g)) {
      let lowercasetext = paste.toLowerCase();
      setInputs(inputs => ({ ...inputs, ['emailPaste']: lowercasetext }));
      setLoader(false)

    }
    else {
      toast.warn(" not valid string", {
        // position: toast.POSITION.BOTTOM_LEFT
      });
      setLoader(false)
    }


  };
  const handleBulkPasteVerify = evt => {
    const cancelTokenSource = axios.CancelToken.source();
    setVerifyLoader(true)
    evt.preventDefault();
    // var paste = evt.clipboardData.getData("text");
    var paste = emailPaste;
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: [] }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: [] }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: [] }));
    setValidEmailList([])
    setInvalidEmailList([])
    setBlockedEmailList([])
    setPrimaryMailList([]);

    if (paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g)) {

      axios.post(`${apiUrl}/api/masters/emailValidationBulk`, { emails: paste }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader(),
        cancelToken: cancelTokenSource.token
      })
        .then((response) => {
          console.log("invalid" + response.data.invalidEmails)
          setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: response.data.validEmails.length }));
          setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: response.data.invalidEmails.length }));
          setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: response.data.blockedEmails.length }));
          setValidEmailList(response.data.validEmails)
          setInvalidEmailList(response.data.invalidEmails)
          setBlockedEmailList(response.data.blockedEmails)
          setInputs(inputs => ({ ...inputs, ['emailPaste']: '' }));
          setVerifyLoader(false)
          setPrimaryMailList(response.data.validEmails);

        }).catch(error => {
          setVerifyLoader(false)
          cancelTokenSource.cancel();
        });



    }
    else {
      toast.warn(" not valid string", {
        // position: toast.POSITION.BOTTOM_LEFT
      });
      setVerifyLoader(false)
    }


  };

  // const singleVerifyhandleKeyDown = evt => {
  //   if (["Enter", "Tab", ","].includes(evt.key)) {
  //     evt.preventDefault();

  //     const { value } = evt.target;
  //     setLoader(true)
  //     axios.post(`${apiUrl}/api/masters/emailValidationBulk`, { emails: [value] }, {
  //       // headers: { "Access-Control-Allow-Origin": "*" }
  //       headers: authHeader()
  //     })
  //       .then((response) => {
  //         setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: response.data.validEmails.length }));
  //         setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: response.data.invalidEmails.length }));
  //         setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: response.data.blockedEmails.length }));
  //         setValidEmailList(response.data.validEmails)
  //         setInvalidEmailList(response.data.invalidEmails)
  //         setBlockedEmailList(response.data.blockedEmails)
  //         setLoader(false)
  //         setPrimaryMailList(response.data.validEmails);
  //       });

  //   }
  // }
  // function singleVerify(email, name) {
  //   let error = null;
  //   setLoader(true)
  //   axios.post(`${apiUrl}/api/masters/emailValidationBulk`, { emails: [email] }, {
  //     // headers: { "Access-Control-Allow-Origin": "*" }
  //     headers: authHeader()
  //   })
  //     .then((response) => {
  //       setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: response.data.validEmails.length }));
  //       setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: response.data.invalidEmails.length }));
  //       setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: response.data.blockedEmails.length }));
  //       if (response.data.validEmails.length > 0) {
  //         error = `${email} is not a valid email address.`;
  //       }
  //       if (response.data.blockedEmails.length > 0) {
  //         error = `${email} is Blocked.`;
  //       }
  //       setLoader(false)
  //     });
  //   if (error) {
  //     // this.setState({ error });
  //     if (name === 'to')
  //       setError(error)
  //     if (name === 'cc')
  //       setCCError(error)
  //     if (name === 'bcc')
  //       setBCCError(error)
  //     toast.error("Ohh Error Occured!", {
  //       toastId: 1
  //     });
  //     return false;
  //   }

  //   return true;
  // }
  function handleBulkAdd(e) {
    e.preventDefault();

    if (PrimarymailList.length > 0) {
      setLoader(true);
      const { name } = e.target;
      let mailArray = [];
      PrimarymailList.map((prop, key) => {

        if (isValid(prop, name)) {
          // mailArray.push(prop);
          mailArray.push({ email: prop, name: "" });
        }
      })
      if (mailArray.length > 0) {
        if (name === 'cc') {
          setccChecked(true)
          setCCItems([...CCitems, ...mailArray]);
        }

        if (name === 'bcc') {
          setbccChecked(true)
          setBCCItems([...BCCitems, ...mailArray]);
        }

        if (name === 'to') {
          setItems([...items, ...mailArray]);
        }



        setmailCount(mailCount + mailArray.length)

        // if (mailArray.length > 0)
        toast.success(mailArray.length + " Recipients Added to the list", {

        });
      }
      setLoader(false);
      setPrimaryMailList([])
      setVisible(false)
      setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: 0 }));
      setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: 0 }));
      setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: 0 }));
      setValidEmailList([])
      setInvalidEmailList([])
      setBlockedEmailList([])

    }
  }
  function handleAdd(e) {

    e.preventDefault();
    setLoader(true);
    if (PrimarymailList.length > 0 || SecondarymailList.length > 0) {
      const { name } = e.target;

      let mailArray = [];
      let primarymailArray = [];
      let secondarymailArray = [];
      if (PrimarymailList.length > 0 && primary) {
        PrimarymailList.map((prop, key) => {

          if (isValid(prop.email, name)) {
            mailArray.push({ email: prop.email, name: `${(prop.inst_name ? prop.inst_name : "")} - ${(prop.inst_type_name ? prop.inst_type_name : "")}` });
            // console.log(prop.inst_type_name)
            // mailArray.push(prop.email);
            primarymailArray.push(prop.email);
          }
        })
      }
      if (SecondarymailList.length > 0 && secondary) {
        SecondarymailList.map((prop, key) => {

          if (isValid(prop.email, name)) {
            mailArray.push({ email: prop.email, name: `${(prop.inst_name ? prop.inst_name : "")} - ${(prop.inst_type_name ? prop.inst_type_name : "")}` });
            // mailArray.push(prop.email);
            secondarymailArray.push(prop.email);
          }
        })
      }
      if (mailArray.length > 0) {


        let listArray = [];
        if (institution) {
          selectedDistrict.map((prop, key) => {
            // console.log('Jassssssssssss'+PrimarymailList)

            if (PrimarymailList.some(item => prop.value === item.district)) {
              let title = prop.label;
              let des = '';
              let inst = [];
              let instList = [];

              selectedInstType.map((props, key) => {
                if (PrimarymailList.some(item => prop.value === item.district && props.value === item.inst_type)) {

                  des = des + '  \n' + props.label;

                  // if (selectedInstitution.some(instItem => prop.value === instItem.district_id && props.value === instItem.inst_type_id)) {

                  // }
                  selectedInstitution.map((instItem, key) => {
                    if (prop.value === instItem.district_id && props.value === instItem.inst_type_id) {
                      instList.push(instItem.value);
                      des = des + '  \n ( ' + instItem.label + ')';
                    }

                  })

                  inst.push(props.value)

                }
              })
              // console.log('my newww'+selectedInstitution)
              // selectedInstitution.map((props, key) => {
              //   instList.push(props.value);
              //   des = des + '  \n ( ' + props.label + ')';
              // })
              if (primary && primarymailArray.length > 0) {
                des = des + '  \n (Primary Email ' + primarymailArray.length + ')';
              }
              if (secondary && secondarymailArray.length > 0) {
                des = des + '  ' + '\n (Secondary  Email ' + secondarymailArray.length + ')';
              }

              const obj = {
                title: title,
                // id: prop.value + inst,
                id: infoList.length,
                description: des,
                button: {
                  children: "Remove",
                  size: "sm",
                  // id: prop.value,
                  id: infoList.length,
                  district: prop.value,
                  insttype: inst,
                  field: name,
                  // institutionList: instList,
                  name: inst,
                  color: "red",
                  onClick: handleRemoveMailList

                }
              };

              listArray.push(obj);
            }

          })
        }
        if (stakeholder) {

          let title;
          let des;
          let inst = [];
          selectedstakeType.map((props, key) => {
            // alert(props.value)
            console.log('Jassssssssssss' + PrimarymailList)
            // if (PrimarymailList.some(item => props.value === item.desig)) {
            // alert(props.value)
            title = props.label;

            des = props.label;
            if (primary && primarymailArray.length > 0) {
              des = des + '  \n (Primary Email ' + primarymailArray.length + ')';
            }
            if (secondary && secondarymailArray.length > 0) {
              des = des + '  ' + '\n (Secondary  Email ' + secondarymailArray.length + ')';
            }

            inst.push(props.value)
            const obj = {
              title: title,
              // id: prop.value + inst,
              id: infoList.length,
              description: des,
              button: {
                children: "Remove",
                size: "sm",
                // id: prop.value,
                id: infoList.length,
                // district: prop.value,
                insttype: inst,
                field: name,
                // institutionList: instList,
                name: inst,
                color: "red",
                onClick: handleRemoveMailList

              }
            };

            listArray.push(obj);

            // }
          })

          // selectedDistrict.map((prop, key) => {

          //   if (PrimarymailList.some(item => prop.value === item.district)) {
          //     let title = prop.label;
          //     let des = '';
          //     let inst = [];
          //     let instList = [];

          //     selectedstakeType.map((props, key) => {
          //       if (PrimarymailList.some(item => prop.value === item.district && props.value === item.desig)) {

          //         des = des + '  ' + props.label;


          //         inst.push(props.value)

          //       }
          //     })
          //     selectedStakeholder.map((props, key) => {
          //       instList.push(props.value);
          //     })
          //     if (primary && primarymailArray.length > 0) {
          //       des = des + '  \n (Primary Email ' + primarymailArray.length + ')';
          //     }
          //     if (secondary && secondarymailArray.length > 0) {
          //       des = des + '  ' + '\n (Secondary  Email ' + secondarymailArray.length + ')';
          //     }

          //     const obj = {
          //       title: title,
          //       // id: prop.value + inst,
          //       id: infoList.length,
          //       description: des,
          //       button: {
          //         children: "Remove",
          //         size: "sm",
          //         // id: prop.value,
          //         id: infoList.length,
          //         district: prop.value,
          //         insttype: inst,
          //         field: name,
          //         // institutionList: instList,
          //         name: inst,
          //         color: "red",
          //         onClick: handleRemoveMailList

          //       }
          //     };

          //     listArray.push(obj);
          //   }

          // })
        }

        setInfolist([...infoList, ...listArray]);

        console.log(infoList);
        console.log("final" + mailArray);

        if (name === 'cc') {
          setccChecked(true)
          setCCItems([...CCitems, ...mailArray]);
        }

        if (name === 'bcc') {
          setbccChecked(true)
          setBCCItems([...BCCitems, ...mailArray]);
        }

        if (name === 'to') {
          setItems([...items, ...mailArray]);
        }
        setmailCount(mailCount + mailArray.length)
        setPrimaryMailList([])
        setSecondaryMailList([])
        setSelectedInstitution([])
        setSelectedDistrict([])
        setSelectedInstType([])
        setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: 0 }));
        setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: 0 }));
        setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: 0 }));
        setValidEmailList([])
        setInvalidEmailList([])
        setBlockedEmailList([])
        setSelectedTpmsUsers([])


      }






      setTimeout(() => {

        if (mailArray.length > 0)

          toast.success(mailArray.length + " Recipients Added to the list", {

          });
        setLoader(false);

        setShowModal(false)
      }, 1000);


    }
    else {
      setLoader(false);
      toast.warn("Please select atleast one Recipients", {

      });
    }
  }
  const heroprops = {
    heading2: {
      color: "lightBlue",
      subtitle: "Email ID Validator",
      title: "Paste/Type Email Address to Validate",
      description:
        "Onces you have typed/pasted your email addresses, Please allow us a few moments to validate your email addresses",
      alignment: "center",
      whiteContent: false,
    },
    input: { leftIcon: "fa fa-envelope", name: "maillist", onPaste: { handleBulkPaste }, placeholder: "Email", border: "border" },
    button: [{ color: "teal", children: "Add to", fullWidth: true, onClick: handleBulkAdd, name: 'to' }, { color: "light", children: "Add to CC", fullWidth: true, onClick: handleBulkAdd, name: 'cc', }, { color: "dark", children: "Add to BCC", fullWidth: true, onClick: handleBulkAdd, name: 'bcc' }],
  }
  const hero1props = {
    heading3: {
      color: "lightBlue",
      subtitle: "with burned soles",
      title: "I've risen with the soles burned",
      description:
        "I've fought hard to get out of the hole I was in. I've dusted myself, and exactly like a dancer, I have danced with the fear, you should have filmed me.",
      alignment: "center",
    },
    infoCards: [
      {
        icon: "fas fa-exclamation-circle",
        iconColor: "lightBlue",
        title: `${invalidCount} Invalid Emails`,
        // description:` ${[...invalidEmailList]}`,
      },
      // {
      //   icon: "fas fa-retweet",
      //   iconColor: "lightBlue",
      //   title: "Duplicate Emails",
      //   // description:`${invalidCount} `,
      // },
      {
        icon: "fas fa-ban",
        iconColor: "red",
        title: `${blockedCount} Blocked Emails`,
        // description:`${blockedEmailList} `,
      },
      {
        icon: "fas fa-fingerprint",
        iconColor: "emerald",
        title: `${validCount} Valid Emails`,
        // description:`${[...validEmailList]}`,
      },
    ],

  }


  function isValid(email, name) {
    setBlackList(false);
    let error = null;
    // isInBlackList(email);

    if (isInList(email, name)) {
      error = `${email} has already been added.`;
    }


    if (blackList) {
      error = `${email} in blacklist.`;
    }


    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      // this.setState({ error });
      if (name === 'to')
        setError(error)
      if (name === 'cc')
        setCCError(error)
      if (name === 'bcc')
        setBCCError(error)
      toast.error("Ohh Error Occured!", {
        toastId: 1
      });
      return false;
    }

    return true;
  }

  function isInList(email, name) {

    // return items.includes(email) || CCitems.includes(email) || BCCitems.includes(email)
    return items.some(item => item.email === email) || CCitems.some(item => item.email === email) || BCCitems.some(item => item.email === email)
  }

  // function isInBlackList(email) {

  //   blackListEmail(email)
  //     .then((res) => {
  //       res.data.map((prop, key) => {

  //         if (prop.email)
  //           setBlackList(true);
  //         toast.warn("" + email + "in Black List, Please Remove it", {

  //         });
  //         // console.log(blackList)
  //       })
  //     })
  //     .catch((error) => {
  //       errorHandler(error);
  //       setBlackList(false);
  //     })
  //   //  alert(blackList);
  //   return blackList;
  // }

  function isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }


  function handleChange(e) {

    let { name, value } = e.target;
    if (name === 'to')
      setError(false)
    if (name === 'cc')
      setCCError(false)
    if (name === 'bcc')
      setBCCError(false)
    if (name === 'emailPaste') {
      value = value.toLowerCase();
    }



    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  const [fromaddOptions, setFromaddOptions] = useState([]);
  const [selectedFromAdd, setSelectedFromadd] = useState([1]);
  const [contactPersonOptions, setContactPersondOptions] = useState([]);
  const [selectedContactPerson, setSelectedContactPerson] = useState([]);

  const [emailtypeOptions, setEmailtypeOptions] = useState([{ "value": 1, "label": "With Attachment" }, { "value": 2, "label": "Without Attachment" }]);
  const [selectedEmailtype, setSelectedEmailtype] = useState([1]);

  // const [salutationOptions, setSalutationOptions] = useState([{ "value": 1, "label": "Dear (value from Excel)" }, { "value": 2, "label": "Dear Candidate" }, { "value": 3, "label": "Dear Sir/Madam" }, { "value": 4, "label": "Respected Sir/Madam" }, { "value": 5, "label": "Respected (value from Excel)" }, { "value": 6, "label": "Honourable Sir/Madam" }, { "value": 7, "label": "Honourable (value from Excel)" }]);
  const [salutationOptions, setSalutationOptions] = useState([]);
  
  const [selectedSalutation, setSelectedSalutation] = useState(1);

  const [tablePreview, setTablePreview] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);


  const handleContactPersonChange = value => {
    console.log(`selected ${value}`);
    setSelectedContactPerson(value);
  };


  const cardEmailForm = {
    title: "Compose Email",
    display: false,
    button: [
      // {
      //   children: "Schedule Now",
      //   size: "sm",
      //   id: "schedule",
      //   name: "schedule",
      //   color: "teal",

      // },

      {
        children: "Verify",
        size: "sm",
        id: "verifyExcel",
        name: "verifyExcel",
        color: "teal",

      },
      {
        children: "Send Now",
        size: "sm",
        id: "send",
        name: "send",
        color: "indigo",

      },

    ],

    modalButton: [

    ],

    forms: [
      {
        title: "From Address & Contact Person Details",
        inputs: [
          {
            label: "From Address *",
            width: 6,
            display: true,
            singleSelect: {
              placeholder: "From Address",
              option: fromaddOptions,
              onChange: setSelectedFromadd,
              defaultValue: 1,
              value: selectedFromAdd


            },

          },
          {
            label: "Authorised Person for Communication *",
            width: 6,
            display: true,
            singleSelect: {
              placeholder: "Authorised Person for Communication (Maximum 2)",
              option: contactPersonOptions,
              onChange: handleContactPersonChange,
              value: selectedContactPerson,
              mode: 'multiple',
              allowClear: true,


            },

          },
        ]
      },
      {
        title: "Email Details",
        inputs: [
          {
            label: "Email Type *",
            width: 6,
            display: true,
            singleSelect: {
              placeholder: "Email Type",
              option: emailtypeOptions,
              onChange: setSelectedEmailtype,
              defaultValue: 1,
              value: selectedEmailtype


            },

          },
          {
            label: "Salutaion *",
            width: 6,
            display: true,
            singleSelect: {
              placeholder: "Salutaion",
              option: salutationOptions,
              onChange: setSelectedSalutation,
              defaultValue: 1,
              value: selectedSalutation


            },

          },







        ],
      },
      {
        title: "Beneficiary Details",
        inputs: [
          {
            label: "Mail List Excel",
            width: 6,
            display: true,
            uploadProps: uploadpropsExcel,
            browse: {
            },

          },
          {
            label: "Mail List Excel sample Download",
            width: 6,
            display: true,

            button: {
              children: "Download",
              size: "sm",
              id: "download_excelsample",
              name: "download_excelsample",
              color: "indigo",
              onClick: downloadSample
            },

          },


        ]
      },
      {
        title: "Mail Content",
        inputs: [
          {
            label: "Subject *",
            width: 12,
            display: true,
            input: {
              placeholder: "Subject",
              type: "text",
              name: "subject",
              value: subject,
              onChange: handleChange,
              // rightIcon: "fas fa-envelope-open",

            },
          },
          {
            label: "Body *",
            width: 12,
            display: true,
            CKediter: {
              placeholder: "About me",
              type: "textarea",
              // defaultValue: "A beautiful Dashboard for React & Tailwind CSS.",
              rows: 4,
            },
          },
          {
            label: "Email List Users Preview ",
            width: 12,
            display: (tablePreview),
            table: {
              data: "Email List Users Preview ",
              loading: true,
              // option: emailtypeOptions,
              // onChange: setSelectedEmailtype,
              // defaultValue:1,
              // value: selectedEmailtype


            },

          },
          {
            label: "Attachments",
            width: 12,
            display: (selectedEmailtype == 1 ? true : false),
            uploadProps: uploadprops,
            file: {
            },

          },

        ],
      },
      // {
      //   title: "Contact Details",
      //   inputs: [
      //     {
      //       label: "Contact Person ",
      //       width: 12,
      //       singleSelect: {
      //         placeholder: "Contact Person",
      //         option:contactPersonOptions,
      //         onSelect:setSelectedContactPerson,
      //         // onChange:handleSingleSelect,
      //         value:selectedContactPerson


      //       },

      //     },
      //   ]
      // },
    ],
  };




  const [content, setContent] = useState('');
  const [buttonVal, setbuttonVal] = useState('');
  const [wait, setWait] = useState(false);

  const [fileSize, setFilesize] = useState(0);
  // const handleSelectedFile = file => {
  //   // files.map(file => (
  //   // setSelectedFile([...selectedFile, file.name]);
  //   const body = new FormData();
  //   body.append('file', file);
  //   axios.post(`${apiUrl}/upload`, body, {
  //     headers: { "Access-Control-Allow-Origin": "*" }
  //   })
  //     .then((response) => {
  //       console.log(response.data);

  //     })

  // }




  // const baseStyle = {
  //   flex: 1,
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   padding: '20px',
  //   borderWidth: 2,
  //   borderRadius: 2,
  //   borderColor: '#eeeeee',
  //   borderStyle: 'dashed',
  //   backgroundColor: '#fafafa',
  //   color: '#bdbdbd',
  //   outline: 'none',
  //   transition: 'border .24s ease-in-out'
  // };

  // const activeStyle = {
  //   borderColor: '#2196f3'
  // };

  // const acceptStyle = {
  //   borderColor: '#00e676'
  // };

  // const rejectStyle = {
  //   borderColor: '#ff1744'
  // };
  // const {
  //   getRootProps,
  //   getInputProps,
  //   acceptedFiles,
  //   fileRejections,
  //   isDragActive,
  //   isDragAccept,
  //   isDragReject,
  //   // onDrop
  // } = useDropzone({
  //   accept: 'image/*,audio/*,application/*,video/*', maxFiles: 4,
  //   onDrop: acceptedFiles => {
  //     acceptedFiles.map(file => (
  //       handleSelectedFile(file)

  //     ));
  //   }
  // });


  // const style = useMemo(() => ({
  //   ...baseStyle,
  //   ...(isDragActive ? activeStyle : {}),
  //   ...(isDragAccept ? acceptStyle : {}),
  //   ...(isDragReject ? rejectStyle : {})
  // }), [
  //   isDragActive,
  //   isDragReject,
  //   isDragAccept
  // ]);
  // const removeFile = (file) => (e) => {
  //   e.preventDefault();
  //   console.log('removeFile...')
  //   acceptedFiles.splice(acceptedFiles.indexOf(file), 1)
  //   console.log(acceptedFiles)
  // }




  const toastId = React.useRef(null);

  function handleExcelVerify(e) {
    const cancelTokenSource = axios.CancelToken.source();
    setVerifyLoader(true);
    e.preventDefault();
    let primaryMaillist = [];
    let secondaryMaillist = [];
    let primaryMailstatus = null;
    let secondaryMailstatus = null;
    if (data.length > 0) {
      const newState = data.map(obj => {
        if (isEmail(obj.email_pri)) {
          primaryMaillist.push(obj.email_pri)
          primaryMailstatus = 1;
        }
        else {
          primaryMailstatus = 0;

        }
        if (isEmail(obj.email_sec)) {
          secondaryMaillist.push(obj.email_sec)
          secondaryMailstatus = 1;
        }
        else {
          secondaryMailstatus = 0;
        }

        return { ...obj, email_pri_status: primaryMailstatus, email_sec_status: secondaryMailstatus };

      });

      setData(newState);

      const mailListall = primaryMaillist.concat(secondaryMaillist);

      axios.post(`${apiUrl}/api/masters/emailValidationBulk`, { emails: mailListall, type: 2 }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader(),
        cancelToken: cancelTokenSource.token
      }).then((response) => {
        let primaryMailstatus = null;

        const newState1 = data.map(obj => {
          const isValid = response.data.validEmails.some(item => obj.email_pri === item)
          const isInvalid = response.data.invalidEmails.some(item => obj.email_pri === item.mail)
          const isBlackList = response.data.blockedEmails.some(item => obj.email_pri === item.mail)

          const isValid2 = response.data.validEmails.some(item => obj.email_sec === item)
          const isInvalid2 = response.data.invalidEmails.some(item => obj.email_sec === item.mail)
          const isBlackList2 = response.data.blockedEmails.some(item => obj.email_sec === item.mail)


          if (isValid) {
            primaryMailstatus = 1;
          }
          if (isBlackList) {
            primaryMailstatus = 2;
          }
          if (isInvalid) {
            primaryMailstatus = 0;
          }

          if (isValid2) {
            secondaryMailstatus = 1;
          }
          if (isBlackList2) {
            secondaryMailstatus = 2;
          }
          if (isInvalid2) {
            secondaryMailstatus = 0;
          }

          return { ...obj, email_pri_status: primaryMailstatus, email_sec_status: secondaryMailstatus };

        })
        setData(newState1);

      })
    }




    setVerifyLoader(false);
    console.log(data);
  }



  function handleEmailSubmit(e) {
    scrollToTop();
    setLoader(true);
    e.preventDefault();
    // console.log(selectedFile);
    let action = e.target.id;


    let body = '';
    let valid = false;
    const device = {
      platform_name: platform.name, // 'IE' ( your users browser name )
      platform_version: platform.version,
      platform_os: platform.os,
      platform_product: platform.product,
    }
    console.log(device)


    if (action === 'send') {
      if ((content && subject) && (content !== '' && subject !== '') && (selectedFromAdd) && (selectedEmailtype) && (data.length > 0) && (selectedContactPerson.length > 0)) {
        valid = true;
      }

      // setbuttonVal("Sending....");
      body = {
        "content": content, "subject": subject, "from_id": selectedFromAdd, "contactPerson": selectedContactPerson, "list": data, "status": 2, "file": selectedFile, "device": device, "emailType": selectedEmailtype, "salutation": selectedSalutation
      }
    }


    if (valid) {


      toastId.current = toast.info("Request Processing. Please Wait..", { autoClose: false, closeButton: false });
      setbuttonVal(action);
      try {
        // if(action==='send'){
        axios.post(`${apiUrl}/api/mail/sendmailBulk`, body, {
          // headers: { "Access-Control-Allow-Origin": "*" }
          headers: authHeader()
        })
          .then((response) => {
            console.log(response.data);
            setbuttonVal('');
            if (response.data.status === 'success') {


              setSelectedFile([])
              setInputs(inputs => ({ ...inputs, ['subject']: '' }));
              setContent('');
              setSelectedContactPerson([]);

              setTimeout(() => {
                setLoader(false);

                toast.update(toastId.current,
                  {
                    type: toast.TYPE.SUCCESS, autoClose: 5000, closeButton: false,
                    render: `Your Email Request Processed Successfully`,
                    icon: ({ theme, type }) =>
                      // <img src="/Po" alt="..." className={"rounded-sm shadow-sm w-450-px "}/>,
                      <i className="fas fa-shipping-fast"></i>,
                    className: 'rotateY animated'
                  });
                window.location.reload();
              }, 14000);

            }
            else {
              // toast.error(`${response.data.message}`, {
              //   // position: toast.POSITION.TOP_CENTER
              // });
              // toast.update(toastId.current, { type: toast.TYPE.ERROR, autoClose: 5000 });
            }


          })
          .catch(error => {
            setLoader(false);
            setTimeout(() => {
              toast.dismiss(toastId.current);

              // console.log(error.response.data)
              errorHandler(error);
              setbuttonVal('');
            }, 2000);

          });
        localStorage.removeItem('forwardMail');
      } catch (error) {
        console.log(error.response); // this is the main part. Use the response property from the error object
        setLoader(false);
        return error.response;

      }

    }

    // }
    // }
    else {
      // toast.update(toastId.current, { type: toast.TYPE.WARN, autoClose: 5000, render: 'Please check all mandatory fields are filled' });
      toast.warning(`Please check all mandatory fields are filled`, {
        autoClose: true
      });
      setLoader(false);

    }




  }

  const [addressType, setAddressType] = useState({
    institution: '',
    stakeholder: '',
    others: '',
    tpms: '',
    blockwise: '',

  });
  const { institution, stakeholder, others, tpms, blockwise } = addressType;
  function handleAddressTypeChange(e) {
    e.preventDefault();
    const { name, id } = e.target;
    // alert(id);
    setAddressType(addressType => ({ addressType, [name]: id }));
    setPrimaryMailList([])
    setSecondaryMailList([])
    setSelectedInstitution([])
    setSelectedDistrict([])
    setSelectedInstType([])
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['validCount']: 0 }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['invalidCount']: 0 }));
    setMailCheckCount(mailCheckCount => ({ ...mailCheckCount, ['blockedCount']: 0 }));
    setValidEmailList([])
    setInvalidEmailList([])
    setBlockedEmailList([])
    // console.log(addressType)
  }

  const Buttonprops = {
    title: "Address Types",
    // button:addressGroups
    button: [{
      children: "LSGI Institution",
      color: "indigo",
      outline: true,
      id: 'institution',
      name: 'institution',
      value: institution,
      onClick: handleAddressTypeChange
    },
    {
      children: "KILA Stakeholders",
      color: "indigo",
      outline: true,
      id: 'stakeholder',
      name: 'stakeholder',
      value: stakeholder,
      onClick: handleAddressTypeChange

    },
    {
      children: "TPMS Users",
      color: "indigo",
      outline: true,
      id: 'tpms',
      name: 'tpms',
      value: tpms,
      onClick: handleAddressTypeChange
    },
    // {
    //   children: "Others",
    //   color: "indigo",
    //   outline: true,
    //   id: 'others',
    //   name: 'others',
    //   value: others,
    //   onClick: handleAddressTypeChange
    // }
    {
      children: "Block Wise",
      color: "indigo",
      outline: true,
      id: 'blockwise',
      name: 'blockwise',
      value: blockwise,
      onClick: handleAddressTypeChange
    }
    ]
  };






  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([{ "value": 77, "label": "India " }]);
  const [countryLoading, setCountryLoading] = useState(false);

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState([{ "value": 13, "label": "Kerala" }]);
  const [stateLoading, setStateLoading] = useState(false);

  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [stakeLevel, setStakeLevel] = useState(false);

  const [instTypeOptions, setInstTypeOptions] = useState([]);
  const [selectedInstType, setSelectedInstType] = useState([]);
  const [instTypeLoading, setInstTypeLoading] = useState(false);

  const [instituteOptions, setInstituteOptions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const [instituteLoading, setInstitutionLoading] = useState(false);

  const [blockOptions, setBlockOptions] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState([]);

  const [stakeTypeOptions, setStakeTypeOptions] = useState([]);
  const [selectedstakeType, setSelectedStakeType] = useState([]);
  const [stakeTypeLoading, setStakeTypeLoading] = useState(false);

  const [stakeholderOptions, setStakeholderOptions] = useState([]);
  const [selectedStakeholder, setSelectedStakeholder] = useState([]);
  const [stakeholderLoading, setStakeholderLoading] = useState(false);

  const [tpmsUserTypeOptions, settpmsUserTypeOptions] = useState([]);
  const [selectedtpmUserType, setSelectedTpmsUserType] = useState([]);

  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const [tpmsUsersOptions, setTpmsUsersOptions] = useState([]);
  const [selectedTpmsUsers, setSelectedTpmsUsers] = useState([]);

  const [PrimarymailList, setPrimaryMailList] = useState([]);
  const [SecondarymailList, setSecondaryMailList] = useState([]);



  function stakeTypeChange(selected) {
    // e.preventDefault();

    if (selected.length === 1 || selected.length === 0) {
      setSelectedStakeType(selected)
    }
    else {
      toast.warn("Only one can select at a time!", {
        // position: toast.POSITION.TOP_CENTER
      });
    }

  }
  const [blacklistExt, setblacklistExt] = React.useState([])
  useEffect(() => {
    axios.post(`${apiUrl}/api/config/user-privilleges`, {}, {
      // headers: { "Access-Control-Allow-Origin": "*" }
      headers: authHeader()
    }).then(response => {
      // console.log(response.data);
      setFromaddOptions(
        response.data
      );
    });

    axios.post(`${apiUrl}/api/masters/salutations`, {}, {
      // headers: { "Access-Control-Allow-Origin": "*" }
      headers: authHeader()
    }).then(response => {
      // console.log(response.data);
      setSalutationOptions(
        response.data
      );
    });
    axios.get(`${apiUrl}/api/config/global-settings`).then(response => {
      // setLocationOptions(response.data);

      response.data.result.map((prop, key) => {
        if (prop.config_key === 'blacklist_extensions') {
          setblacklistExt(prop.config_value.split(','))
        }

      })
    });

    axios.post(
      `https://tpms.kila.ac.in/api/contactPersonsList`, {})
      .then((response) => {
        if (response.data.status === "TRUE") {
          setContactPersondOptions(
            response.data.users
          );
          if (user.roles.includes('ADMIN')) {
            setContactPersondOptions(currentArray => [...currentArray, { label: "Promotional", value: 0, url: "https://tpms.kila.ac.in/images/kilaUsers", profile_pic: "avatar.png" }])
          }
        }

        console.log(response.data);

      }).catch(error => {
        errorHandler(error);
      });


  }, []);

  useEffect(() => {
    if (showModal) {
      axios.get(`${apiUrl}/api/masters/country`).then(response => {

        // console.log(response.data);
        setCountryOptions(
          response.data
        );
      });

      settpmsUserTypeOptions([{ "label": "KILA Employee", "value": 4 }])
    }
  }, [showModal]);
  useEffect(() => {
    if (showModal && selectedtpmUserType) {
      axios.get(`${apiUrl}/api/masters/locations`).then(response => {
        setLocationOptions(response.data);
      });
    }
  }, [selectedtpmUserType, showModal]);

  useEffect(() => {
    if (showModal && selectedtpmUserType && selectedLocation) {
      let locArray = [];
      selectedLocation.map((prop, key) => {
        locArray.push(prop.value);
      })
      axios.post(
        `https://tpms.kila.ac.in/api/kilaUsersList`, { location: locArray })
        .then((response) => {
          if (response.data.status === "TRUE") {
            setTpmsUsersOptions(
              response.data.users
            );
          }

          console.log(response.data);

        }).catch(error => {
          errorHandler(error);
        });
    }
  }, [selectedtpmUserType, showModal, selectedLocation]);
  useEffect(() => {
    if (selectedtpmUserType && selectedLocation && selectedTpmsUsers) {
      setPrimaryMailList([]);
      setSecondaryMailList([]);
      setValidEmailList([])
      setInvalidEmailList([])
      setBlockedEmailList([])
      if (selectedTpmsUsers.length > 0) {
        let usersArray = [];
        selectedTpmsUsers.map((prop, key) => {
          usersArray.push(prop.value);
        })

        axios.post(`https://tpms.kila.ac.in/api/kilaUsersDetailsList`, { users: usersArray })
          .then((response) => {
            if (response.data.status === "TRUE") {
              // console.log(response.data)
              let PrimaryitemsArray = [];
              let SecondaryitemsArray = [];

              response.data.users.map((prop, key) => {
                // alert(prop.pmail)
                if (prop.email_pri && prop.email_pri !== null && prop.p_mail !== '')
                  PrimaryitemsArray.push({ email: prop.email_pri, inst_name: `${prop.prefix}${prop.name}`, inst_type_name: prop.locName });
                if (prop.email_sec && prop.email_sec !== null && prop.email_sec !== '')
                  SecondaryitemsArray.push({ email: prop.email_sec, inst_name: `${prop.prefix}${prop.name}`, inst_type_name: prop.locName });

              })
              if (PrimaryitemsArray.length > 0 && primary) {
                axios.post(`${apiUrl}/api/masters/emailValidation`, { emails: PrimaryitemsArray, mail_type: 'Primary', addressType: "tpms" }, {
                  // headers: { "Access-Control-Allow-Origin": "*" }
                  headers: authHeader()
                })
                  .then((response) => {
                    console.log("invalid" + response.data.invalidEmails)
                    setValidEmailList(response.data.validEmails)
                    if (response.data.invalidEmails.length > 0)
                      setInvalidEmailList(response.data.invalidEmails)
                    if (response.data.blockedEmails.length > 0)
                      setBlockedEmailList(response.data.blockedEmails)
                    setPrimaryMailList(response.data.validEmails);

                  });
              }
              if (SecondaryitemsArray.length > 0 && secondary) {
                axios.post(`${apiUrl}/api/masters/emailValidation`, { emails: SecondaryitemsArray, mail_type: 'Secondary', addressType: "tpms" }, {
                  // headers: { "Access-Control-Allow-Origin": "*" }
                  headers: authHeader()
                })
                  .then((response) => {
                    console.log("invalid" + response.data.invalidEmails.length)
                    console.log("blocked" + response.data.blockedEmails.length)
                    if (response.data.validEmails.length > 0) {
                      if (validEmailList.length > 0) {
                        setValidEmailList([...validEmailList, response.data.validEmails])
                      }
                      else {
                        setValidEmailList(response.data.validEmails)
                      }

                    }

                    if (response.data.invalidEmails.length > 0) {
                      if (invalidEmailList.length > 0) {
                        setInvalidEmailList([...invalidEmailList, response.data.invalidEmails])
                      }
                      else {
                        setInvalidEmailList(response.data.invalidEmails)
                      }
                      // setInvalidEmailList([...invalidEmailList, response.data.invalidEmails])
                    }

                    if (response.data.blockedEmails.length > 0) {
                      if (blockedEmailList.length > 0) {
                        setBlockedEmailList([...blockedEmailList, response.data.blockedEmails])
                      }
                      else {
                        setBlockedEmailList(response.data.blockedEmails)
                      }
                      // setBlockedEmailList([...blockedEmailList, response.data.blockedEmails])
                    }


                    setSecondaryMailList(response.data.validEmails);
                  });
              }


              // setPrimaryMailList(PrimaryitemsArray);
              // setSecondaryMailList(SecondaryitemsArray);

            }

          }).catch(error => {
            errorHandler(error);
          });
      }
      console.log(PrimarymailList)
    }


    else {

    }

    // alert(mailList)
  }, [selectedTpmsUsers, selectedtpmUserType, checkbox]);



  useEffect(() => {
    if (selectedCountry && showModal) {
      let countryArray = [];
      selectedCountry.map((prop, key) => {
        countryArray.push(prop.value);
      })
      axios.post(`${apiUrl}/api/masters/state`, { country: countryArray }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then((response) => {
          setStateOptions(
            response.data
          );

        });
      setStateOptions(
        []
      );
    }
    else {

    }
  }, [selectedCountry, showModal]);

  useEffect(() => {
    if (selectedState && showModal) {
      let stateArray = [];
      selectedState.map((prop, key) => {
        stateArray.push(prop.value);
      })
      axios.post(`${apiUrl}/api/masters/district`, { state: stateArray }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then((response) => {
          setDistrictOptions(
            response.data
          );
          setDistrictLoading(false);
        });
      setDistrictOptions(
        []
      );
    }
    else {

    }
  }, [selectedCountry, selectedState, showModal]);
  useEffect(() => {
    if (institution) {
      setInstitutionLoading(true)
      setInstituteOptions(
        []
      );
      setInstituteOptions(
        []
      );
      setSelectedInstitution([])
      setSelectedInstType([])
      // if (selectedState && selectedCountry && selectedDistrict) {
      axios.post(`${apiUrl}/api/masters/instType`, {}, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then((response) => {
          setInstTypeOptions(
            response.data
          );

          setInstitutionLoading(false)
          // setInstTypeOptions([[...instTypeOptions],{inst_type_id:99,inst_type:'All'}])

        });
      setInstTypeOptions(
        []
      );
    }
    if (blockwise && selectedDistrict.length > 0) {
      let districtArray = [];
      selectedDistrict.map((prop, key) => {
        districtArray.push(prop.value);
      })
      setInstitutionLoading(true)
      setInstituteOptions(
        []
      );

      setSelectedInstitution([])
      setSelectedInstType([])
      // if (selectedState && selectedCountry && selectedDistrict) {
      axios.post(`${apiUrl}/api/masters/blockList`, {
        inst_type: 2,
        district: districtArray
      }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then((response) => {
          setBlockOptions(
            response.data
          );

        });
      setBlockOptions(
        []
      );
    }
    // else {

    // }
  }, [selectedCountry, selectedState, selectedDistrict]);

  useEffect(() => {
    setInstitutionLoading(true)
    setInstituteOptions(
      []
    );
    setSelectedInstitution([])
    if (selectedDistrict && selectedInstType) {

      if (selectedDistrict.length > 0 && selectedInstType.length > 0 && selectedBlock.length === 0) {

        let districtArray = [];
        selectedDistrict.map((prop, key) => {
          districtArray.push(prop.value);
        })

        let insttypeArray = [];
        selectedInstType.map((prop, key) => {
          insttypeArray.push(prop.value);
        })
        axios.post(`${apiUrl}/api/masters/institution`, { district: districtArray, inst_type: insttypeArray }, {
          // headers: { "Access-Control-Allow-Origin": "*" }
          headers: authHeader()
        })
          .then((response) => {
            setInstituteOptions(
              response.data
            );
            setInstitutionLoading(false)

          });
        setInstituteOptions(
          []
        );
      }

    }
    if (selectedDistrict.length > 0 && selectedBlock.length > 0) {

      let districtArray = [];
      selectedDistrict.map((prop, key) => {
        districtArray.push(prop.value);
      })
      let insttypeArray = [5];
      let blockArray = [5];
      // 
      selectedBlock.map((prop, key) => {
        blockArray.push(prop.value);
      })
      axios.post(`${apiUrl}/api/masters/institutionBlockwise`, { district: districtArray, inst_type: insttypeArray, block_id: blockArray }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then((response) => {

          setInstituteOptions(
            response.data
          );
          setInstitutionLoading(false)

        });
      setInstituteOptions(
        []
      );
    }


  }, [selectedCountry, selectedState, selectedDistrict, selectedInstType, selectedBlock]);
  useEffect(() => {
    setInstitutionLoading(true)
    setInstituteOptions(
      []
    );
    setSelectedInstitution([])
    if (selectedDistrict && selectedInstType) {

      if (selectedDistrict.length > 0 && selectedInstType.length > 0) {

        let districtArray = [];
        selectedDistrict.map((prop, key) => {
          districtArray.push(prop.value);
        })
        let insttypeArray = [];
        selectedInstType.map((prop, key) => {
          insttypeArray.push(prop.value);
        })
        axios.post(`${apiUrl}/api/masters/institution`, { district: districtArray, inst_type: insttypeArray }, {
          // headers: { "Access-Control-Allow-Origin": "*" }
          headers: authHeader()
        })
          .then((response) => {
            setInstituteOptions(
              response.data
            );
            setInstitutionLoading(false)

          });
        setInstituteOptions(
          []
        );
      }

    }
    else {

    }
  }, [selectedCountry, selectedState, selectedDistrict, selectedInstType]);
  useEffect(() => {
    if (selectedState && selectedCountry && selectedDistrict && selectedInstType && selectedInstitution) {
      setPrimaryMailList([]);
      setSecondaryMailList([]);
      setValidEmailList([])
      setInvalidEmailList([])
      setBlockedEmailList([])
      if (selectedInstitution.length > 0) {
        let instArray = [];
        selectedInstitution.map((prop, key) => {
          instArray.push(prop.value);
        })

        axios.post(`${apiUrl}/api/masters/institutionDetails`, { institution: instArray }, {
          // headers: { "Access-Control-Allow-Origin": "*" }
          headers: authHeader()
        })
          .then((response) => {


            // console.log(response.data)
            let PrimaryitemsArray = [];
            let SecondaryitemsArray = [];

            response.data.map((prop, key) => {
              // alert(prop.pmail)
              if (prop.p_mail && prop.p_mail !== '0' && prop.p_mail !== '')
                PrimaryitemsArray.push({ email: prop.p_mail, district: prop.district_id, inst_type: prop.inst_type_id, inst_id: prop.reg_inst_id, district_name: prop.district_name, inst_type_name: prop.inst_type_name, inst_name: prop.inst_name });
              if (prop.s_mail && prop.s_mail !== '0' && prop.s_mail !== '')
                SecondaryitemsArray.push({ email: prop.s_mail, district: prop.district_id, inst_type: prop.inst_type_id, inst_id: prop.reg_inst_id, district_name: prop.district_name, inst_type_name: prop.inst_type_name, inst_name: prop.inst_name });

            })
            if (PrimaryitemsArray.length > 0 && primary) {
              axios.post(`${apiUrl}/api/masters/emailValidation`, { emails: PrimaryitemsArray, mail_type: 'Primary', addressType: "lsgi" }, {
                // headers: { "Access-Control-Allow-Origin": "*" }
                headers: authHeader()
              })
                .then((response) => {
                  console.log("invalid" + response.data.invalidEmails)
                  setValidEmailList(response.data.validEmails)
                  if (response.data.invalidEmails.length > 0)
                    setInvalidEmailList(response.data.invalidEmails)
                  if (response.data.blockedEmails.length > 0)
                    setBlockedEmailList(response.data.blockedEmails)
                  setPrimaryMailList(response.data.validEmails);

                });
            }
            if (SecondaryitemsArray.length > 0 && secondary) {
              axios.post(`${apiUrl}/api/masters/emailValidation`, { emails: SecondaryitemsArray, mail_type: 'Secondary', addressType: "lsgi" }, {
                // headers: { "Access-Control-Allow-Origin": "*" }
                headers: authHeader()
              })
                .then((response) => {
                  console.log("invalid" + response.data.invalidEmails.length)
                  console.log("blocked" + response.data.blockedEmails.length)
                  if (response.data.validEmails.length > 0) {
                    if (validEmailList.length > 0) {
                      setValidEmailList([...validEmailList, response.data.validEmails])
                    }
                    else {
                      setValidEmailList(response.data.validEmails)
                    }

                  }

                  if (response.data.invalidEmails.length > 0) {
                    if (invalidEmailList.length > 0) {
                      setInvalidEmailList([...invalidEmailList, response.data.invalidEmails])
                    }
                    else {
                      setInvalidEmailList(response.data.invalidEmails)
                    }
                    // setInvalidEmailList([...invalidEmailList, response.data.invalidEmails])
                  }

                  if (response.data.blockedEmails.length > 0) {
                    if (blockedEmailList.length > 0) {
                      setBlockedEmailList([...blockedEmailList, response.data.blockedEmails])
                    }
                    else {
                      setBlockedEmailList(response.data.blockedEmails)
                    }
                    // setBlockedEmailList([...blockedEmailList, response.data.blockedEmails])
                  }


                  setSecondaryMailList(response.data.validEmails);
                });
            }


            // setPrimaryMailList(PrimaryitemsArray);
            // setSecondaryMailList(SecondaryitemsArray);



          });
        console.log(PrimarymailList)
      }

    }
    else {

    }

    // alert(mailList)
  }, [selectedInstitution, selectedInstType, checkbox]);


  useEffect(() => {
    if (stakeholder) {
      setInstitutionLoading(true)
      setInstituteOptions(
        []
      );
      setInstituteOptions(
        []
      );
      setSelectedInstitution([])
      setSelectedStakeType([])
      // if (selectedState && selectedCountry && selectedDistrict) {
      axios.post(`${apiUrl}/api/masters/stakeType`, {}, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then((response) => {
          setStakeTypeOptions(
            response.data
          );
          setInstitutionLoading(false)
          // setInstTypeOptions([[...instTypeOptions],{inst_type_id:99,inst_type:'All'}])

        });
      setStakeTypeOptions(
        []
      );
    }

    // }
    // else {

    // }
  }, [stakeholder]);
  useEffect(() => {
    setStakeholderLoading(true)
    setStakeholderOptions(
      []
    );
    setSelectedStakeholder([])
    if (selectedstakeType) {

      if (selectedstakeType.length > 0 && selectedstakeType.length < 14) {

        let districtArray = [];
        if (selectedDistrict.length > 0) {
          selectedDistrict.map((prop, key) => {
            districtArray.push(prop.value);
          })
        }
        else {
          districtArray = [0];
        }


        let insttypeArray = [];
        selectedstakeType.map((prop, key) => {
          insttypeArray.push(prop.value);

        })
        axios.post(`${apiUrl}/api/masters/stakeLevel`, { type: insttypeArray }, {
          // headers: { "Access-Control-Allow-Origin": "*" }
          headers: authHeader()
        })
          .then((response) => {
            response.data.map((prop, key) => {
              // alert(prop.level)
              if (prop.level === 'State') {

                setStakeLevel(true)

              }
              else {
                setStakeLevel(false)
              }
            })
            // setStakeholderLoading(false)

          });
        axios.post(`${apiUrl}/api/masters/stakeholder`, { district: districtArray, inst_type: insttypeArray }, {
          // headers: { "Access-Control-Allow-Origin": "*" }
          headers: authHeader()
        })
          .then((response) => {
            setStakeholderOptions(
              response.data
            );
            setStakeholderLoading(false)

          });
        setStakeholderOptions(
          []
        );
      }

    }
    else {

    }
  }, [selectedCountry, selectedState, selectedDistrict, selectedstakeType]);
  useEffect(() => {
    if (stakeLevel)
      setSelectedDistrict([])
  }, [stakeLevel])
  useEffect(() => {
    if (selectedState && selectedCountry && selectedstakeType && selectedStakeholder) {
      setValidEmailList([])
      setInvalidEmailList([])
      setBlockedEmailList([])
      setPrimaryMailList([]);
      setSecondaryMailList([]);
      if (selectedStakeholder.length > 0) {
        let instArray = [];
        selectedStakeholder.map((prop, key) => {
          instArray.push(prop.value);
        })

        axios.post(`${apiUrl}/api/masters/stakeholderDetails`, { institution: instArray }, {
          // headers: { "Access-Control-Allow-Origin": "*" }
          headers: authHeader()
        })
          .then((response) => {


            // console.log(response.data)
            let PrimaryitemsArray = [];
            let SecondaryitemsArray = [];

            response.data.map((prop, key) => {
              // alert(prop.pmail)
              if (prop.p_mail && prop.p_mail !== '0' && prop.p_mail !== '')
                PrimaryitemsArray.push({ email: prop.p_mail, district: prop.district, desig: prop.desig, person_id: prop.person_id, district_name: prop.district_name, inst_type_name: prop.inst_type_name, inst_name: prop.name });
              if (prop.s_mail && prop.s_mail !== '0' && prop.s_mail !== '')
                SecondaryitemsArray.push({ email: prop.s_mail, district: prop.district, desig: prop.desig, person_id: prop.person_id, district_name: prop.district_name, inst_type_name: prop.inst_type_name, inst_name: prop.name });

            })
            if (PrimaryitemsArray.length > 0 && primary) {
              axios.post(`${apiUrl}/api/masters/emailValidation`, { emails: PrimaryitemsArray, mail_type: 'Primary', addressType: "stakeholder" }, {
                // headers: { "Access-Control-Allow-Origin": "*" }
                headers: authHeader()
              })
                .then((response) => {
                  console.log("invalid" + response.data.invalidEmails)
                  setValidEmailList(response.data.validEmails)
                  if (response.data.invalidEmails.length > 0)
                    setInvalidEmailList(response.data.invalidEmails)
                  if (response.data.blockedEmails.length > 0)
                    setBlockedEmailList(response.data.blockedEmails)
                  setPrimaryMailList(response.data.validEmails);

                });
            }
            if (SecondaryitemsArray.length > 0 && secondary) {
              axios.post(`${apiUrl}/api/masters/emailValidation`, { emails: SecondaryitemsArray, mail_type: 'Secondary', addressType: "stakeholder" }, {
                // headers: { "Access-Control-Allow-Origin": "*" }
                headers: authHeader()
              })
                .then((response) => {
                  console.log("invalid" + response.data.invalidEmails)
                  setValidEmailList([...validEmailList, response.data.validEmails])
                  if (response.data.invalidEmails.length > 0) {
                    if (invalidEmailList.length > 0) {
                      setInvalidEmailList([...invalidEmailList, response.data.invalidEmails])
                    }
                    else {
                      setInvalidEmailList(response.data.invalidEmails)
                    }
                  }

                  if (response.data.blockedEmails.length > 0) {
                    if (blockedEmailList.length > 0) {
                      setBlockedEmailList([...blockedEmailList, response.data.blockedEmails])
                    }
                    else {
                      setBlockedEmailList(response.data.blockedEmails)
                    }
                  }

                  setSecondaryMailList(response.data.validEmails);
                });
            }


            // setPrimaryMailList(PrimaryitemsArray);
            // setSecondaryMailList(SecondaryitemsArray);



          });
        console.log(PrimarymailList)
      }

    }
    else {

    }

    // alert(mailList)
  }, [selectedStakeholder, selectedstakeType]);



  const [infoList, setInfolist] = useState([]);
  let props = '';
  props = {
    icon: "fas fa-mail-bulk",
    color: "indigo",
    title: `Selected Mail List`,
    subtitle: `Total Recipients:`,
    count: `${mailCount}`,
    // count:0,
    description: infoList

  }
  useEffect(() => {
    if (infoList.length > 0 && mailCount > 0) {
      props = {
        icon: "fas fa-mail-bulk",
        color: "indigo",
        title: `Mail List  - Total Recipients:`,
        count: `${mailCount}`,
        // count:0,
        description: infoList

      }
    }

  }, [infoList])
  useEffect(() => {

    let forwardMailid = (!localStorage.getItem('forwardMail')) ? "" : localStorage.getItem('forwardMail');
    if (forwardMailid) {
      axios.get(`${apiUrl}/api/mail/mail_trasactions/${forwardMailid}`, {
      }, {
        headers: authHeader()
      }).then(response => {
        let mystyle = {
          color: "red",
          fontWeight: "bold"
        }
        let files = '';
        let fileArray = [];
        response.data.result.attachment.map((prop, key) => {
          console.log("aaaaaaaaaaaa" + prop.name)
          if (prop.fileStatus) {
            files = `${files} <a href="${apiUrl}/files/${prop.file}">${prop.name}</a><br>`;
          }
          else {
            files = `${files} <a href="#" style=${mystyle}>${prop.name} (FIle Removed)</a><br>`;
          }
          fileArray.push({ name: prop.name, file: prop.file })
        })
        var toList = [];
        var ccList = [];
        var bccList = [];
        if (response.data.result.to.length > 0) {
          response.data.result.to.map((prop, key) => {
            toList.push(prop.email);
          })
        }
        if (response.data.result.cc.length > 0) {
          response.data.result.cc.map((prop, key) => {
            ccList.push(prop.email);
          })
        }
        if (response.data.result.bcc.length > 0) {
          response.data.result.bcc.map((prop, key) => {
            bccList.push(prop.email);
          })
        }

        let fwdMailContent = `<p><br><br><br>---------- Forwarded message ---------<br>From: ${response.data.result.from_address.from_name} < ${response.data.result.from_address.from_mail} ><br>
            Date: ${response.data.result.date}<br>
            Subject: ${response.data.result.subject}<br>
            To: ${toList.join(',')}
            ${response.data.result.cc.length > 0 ? `<br>
              CC: ${response.data.result.cc.length > 0 ? ccList.join(',') : ''}` : ''}
              ${response.data.result.bcc.length > 0 ? `<br>
              BCC: ${response.data.result.bcc.length > 0 ? bccList.join(',') : ''}` : ''}
            <br><br><br>
            ${response.data.result.body_content}<br><br>${files}</p>`;
        setContent(fwdMailContent)
        setSelectedFile(fileArray)
        console.log(response.data.result)
      })
        .catch((error) => {
          errorHandler(error);
        });
    }

  }, [])

  // useEffect(() => {
  //   if (items || CCitems || BCCitems ) {
  //     if(items && items.lenth>0)
  //    setmailCount(items.length)
  //    if(CCitems && CCitems.lenth>0)
  //    setCCmailCount(CCitems.length)
  //    if(BCCitems && CCitems.lenth>0)
  //    setBCCmailCount(BCCitems.length)
  //   }

  // }, [items,CCitems,BCCitems])

  function handleRemoveMailList(e) {
    // const { name, id } = e.target;
    // alert(e.currentTarget.getAttribute('district'))
    const district = e.currentTarget.getAttribute('district')
    const id = e.currentTarget.getAttribute('id')
    const instType = e.currentTarget.getAttribute('insttype').split(',')
    // const institutionList = e.currentTarget.getAttribute('institutionList').split(',')
    const field = e.currentTarget.getAttribute('field')

    // console.log(instType)
    axios.post(`${apiUrl}/api/masters/institution`, { district: [district], inst_type: instType }, {
      // axios.post('http://localhost:4000/api/masters/institutionMailDetails', { district: [district], inst_type: instType,institution: institutionList }, {
      headers: authHeader()
    })
      .then((response) => {
        // setmailCount(mailCount - 1)
        let filterArray = [];
        response.data.map((prop, key) => {
          // alert(prop.p_mail)
          filterArray.push(prop.p_mail)

        })
        // filterArray.map((prop, key) => {
        //   if(field==="to"){
        //     if (items.some(item => prop.p_mail === item)) {
        //       alert()
        //       setmailCount(mailCount - 1)
        //     }
        //   }
        //   if(field==="cc"){
        //     if (CCitems.some(item => prop === item)) {
        //       setmailCount(mailCount - 1)
        //     }
        //   }
        //   if(field==="bcc"){
        //     if (BCCitems.some(item => prop === item)) {
        //       setmailCount(mailCount - 1)
        //     }
        //   }

        // })

        console.log("itms  " + [...items]);

        if (field === "to") {
          const newArr = items.filter(f => !filterArray.includes(f));
          console.log('neww arra' + newArr);
          setItems(newArr);
        }

        if (field === "cc") {
          const newArrCC = CCitems.filter(f => !filterArray.includes(f));
          console.log('neww arraCC' + newArrCC);
          setCCItems(newArrCC);
        }

        if (field === "bcc") {
          const newArrBCC = BCCitems.filter(f => !filterArray.includes(f));
          console.log('neww arraCC' + newArrBCC);
          setBCCItems(newArrBCC);
        }



        setmailCount(items.length + CCitems.length + BCCitems.length)
        // const newPeople = infoList.filter((person) => person.id !== district+''+instType);
        // console.log(infoList);
        // alert(district+''+instType)
        // const newList = list.filter((item) => item.id !== id);

        //   setList(newList);
        // const newPeople = infoList.filter((person) => { person.id !== '11';

        // setInfolist(infoList.filter((person) => person.id !== district+''+instType));
        setInfolist(infoList.filter((person) => person.id !== id));

        console.log("infoooolist new" + JSON.stringify(infoList));




      });


  }





  return (
    <>

      <div className="w-full xl:w-12/12 px-4">
        <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
          <div className="mb-0 p-6 pb-0">
            <div className="text-center flex justify-between items-center">
              <h6 className="text-xl font-bold mb-0">{cardEmailForm.title}</h6>
              <div className="text-center flex  items-center">
                {cardEmailForm.modalButton.map((prop, key) => (
                  <>
                    <Button key={key} {...prop} />
                  </>
                ))}
              </div>
              <>
                <Drawer
                  id="drawer-3"
                  header=""
                  size="large"
                  isOpen={visible}
                  onRequestClose={handlePasteDrawerclose}
                >

                  <div className="flex-auto px-6 pb-6 pt-0">


                    {/* <div className="flex flex-wrap -mx-4"> */}


                    <Spin size="small" spinning={VerifyLoader} indicator={<MailVerifySpinner />} >
                      <div className="py-2 bg-blueGray-100">
                        <div className="my-2 container mx-auto px-4">
                          {heroprops.heading2 && <Heading2 {...heroprops.heading2} />}
                          <div className="mx-auto px-4 relative w-full lg:w-7/12 w-10/12">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
                              <div className="px-5 py-3 flex-auto">
                                {/* <form className="my-0" > */}
                                <div className="flex flex-wrap -mx-4">
                                  <div className="px-4 relative w-full sm:w-8/12 pt-4">
                                    <Input onPaste={handleBulkPaste} name="emailPaste" value={emailPaste} onChange={handleChange} />
                                  </div>
                                  <div className="px-4 relative w-full sm:w-4/12 pt-4">
                                    <Button key={'key'} children="Verify" color="pink" onClick={handleBulkPasteVerify} />
                                  </div>


                                  {(validCount > 0) && visible &&
                                    heroprops.button.map((prop, key) => (
                                      <div className="px-4 sm:pl-0 relative w-full sm:w-4/12 text-right pt-4">
                                        <Button key={key} {...prop} />
                                      </div>
                                    ))}

                                </div>
                                {/* </form> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Spin>

                    {(validCount > 0 || invalidCount > 0 || blockedCount > 0) && visible && <Hero1 {...hero1props} />}
                    {/* {(validCount > 0 || invalidCount > 0) && visible && <Accordion {...Accordionprops} />} */}
                    {(validCount > 0 || invalidCount > 0 || blockedCount > 0) && visible &&
                      <div className="rainbow-m-around_xx-large">
                        <Accordion>
                          {/* {validCount > 0 && visible &&
                              <AccordionSection
                                  // icon={<FontAwesomeIcon icon={faCog} className="rainbow-color_brand" />}
                                  label="Valid Emails"
                              >
                                  A rainbow is a meteorological phenomenon that is caused by reflection, refraction
                                  and dispersion of light in water droplets resulting in a spectrum of light appearing
                                  in the sky.
                              </AccordionSection>   
                          } */}
                          {invalidCount > 0 && visible &&
                            <AccordionSection
                              // icon={<FontAwesomeIcon icon={faUsers} className="rainbow-color_brand" />}
                              label="Invalid Emails"
                            >
                              {invalidEmailList.map((prop, key) => (
                                <Card
                                  style={{ marginTop: 16 }}
                                  type="inner"
                                  title={<b>{prop.mail}</b>}
                                  extra={<b>{prop.status}</b>}
                                >
                                  <b>Status : </b> {prop.reason}<br></br>
                                  <b>Last Updated : </b>  {prop.updatedAt}
                                </Card>
                              ))}
                            </AccordionSection>
                          }
                          {blockedCount > 0 && visible &&
                            <AccordionSection
                              // icon={<FontAwesomeIcon icon={faUser} className="rainbow-color_brand" />}
                              label="Blocked Emails"
                            >
                              {blockedEmailList.map((prop, key) => (
                                <Card
                                  style={{ marginTop: 16 }}
                                  type="inner"
                                  title={<b>{prop.mail}</b>}
                                  extra={<b>{prop.status}</b>}
                                >
                                  <b>Status : </b> {prop.reason}<br></br>
                                  <b>Last Updated : </b> {prop.updatedAt}
                                </Card>
                              ))}
                            </AccordionSection>
                          }
                        </Accordion>
                      </div>
                    }


                  </div>
                </Drawer>
              </>

              {showModal ? (
                <>
                  <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                  >
                    <div className="relative w-auto my-6 mx-auto max-w-sm modal-popup">
                      {/*content*/}
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                          <h3 className="text-3xl font-semibold">
                            Address List
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={onCloseModal}
                          >
                            <span className="bg-transparent text-red opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                              ×
                            </span>
                          </button>
                        </div>

                        {/*body*/}
                        <Spin spinning={Loader} >
                          {/* <form autoComplete="none"> */}
                          <div className="relative  p-6 flex-auto">
                            {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed"> */}
                            {Buttonprops &&
                              Buttonprops.button.map((inputProp, inputKey) => (

                                <Button key={inputKey} onClick={inputProp.onclick}  {...inputProp} />

                              ))}
                            <div className="relative  p-6 flex-auto">
                              {stakeholder &&
                                <div >
                                  <MultiSelect
                                    options={stakeTypeOptions}
                                    value={selectedstakeType}
                                    // onChange={setSelectedStakeType}
                                    onChange={stakeTypeChange}
                                    labelledBy={"Select Stakeholder Category"}
                                    overrideStrings={{ selectSomeItems: "Select Stakeholder Category" }}
                                    hasSelectAll={false}
                                  />


                                </div>
                              }
                              {/* {(stakeholder || institution || tpms) && */}
                              {(stakeholder || institution || blockwise) &&

                                <div>
                                  <MultiSelect
                                    options={countryOptions}
                                    value={selectedCountry}
                                    onChange={setSelectedCountry}
                                    labelledBy={"Select Country"}
                                    isLoading={countryLoading}
                                    disabled={true}
                                    overrideStrings={{ selectSomeItems: "Select Country" }}
                                  />

                                  <MultiSelect
                                    options={stateOptions}
                                    value={selectedState}
                                    onChange={setSelectedState}
                                    labelledBy={"Select State"}
                                    isLoading={stateLoading}
                                    disabled={true}
                                    overrideStrings={{ selectSomeItems: "Select State" }}
                                  />
                                  <MultiSelect
                                    options={districtOptions}
                                    value={selectedDistrict}
                                    onChange={setSelectedDistrict}
                                    labelledBy={"Select District"}
                                    isLoading={districtLoading}
                                    overrideStrings={{ selectSomeItems: "Select District" }}
                                    disabled={stakeLevel}
                                  />
                                </div>
                              }
                              {blockwise &&
                                <div>
                                  <MultiSelect
                                    options={blockOptions}
                                    value={selectedBlock}
                                    onChange={setSelectedBlock}
                                    labelledBy={"Select Block"}
                                    // isLoading={instTypeLoading}
                                    overrideStrings={{ selectSomeItems: "Select Block" }}
                                  />
                                  <MultiSelect
                                    options={instituteOptions}
                                    value={selectedInstitution}
                                    onChange={setSelectedInstitution}
                                    labelledBy={"Select Institution"}
                                    isLoading={instituteLoading}
                                    overrideStrings={{ selectSomeItems: "Select Institution" }}
                                  />
                                </div>
                              }
                              {institution &&
                                <div>
                                  <MultiSelect
                                    options={instTypeOptions}
                                    value={selectedInstType}
                                    onChange={setSelectedInstType}
                                    labelledBy={"Select Inst Type"}
                                    isLoading={instTypeLoading}
                                    overrideStrings={{ selectSomeItems: "Select Inst Type" }}
                                  />
                                  <MultiSelect
                                    options={instituteOptions}
                                    value={selectedInstitution}
                                    onChange={setSelectedInstitution}
                                    labelledBy={"Select Institution"}
                                    isLoading={instituteLoading}
                                    overrideStrings={{ selectSomeItems: "Select Institution" }}
                                  />
                                </div>
                              }
                              {stakeholder &&
                                <div >
                                  <MultiSelect
                                    options={stakeholderOptions}
                                    value={selectedStakeholder}
                                    onChange={setSelectedStakeholder}
                                    labelledBy={"Select Stakeholder"}
                                    overrideStrings={{ selectSomeItems: "Select Stakeholder" }}
                                  />


                                </div>
                              }
                              {tpms &&
                                <div >
                                  <MultiSelect
                                    options={tpmsUserTypeOptions}
                                    value={selectedtpmUserType}
                                    onChange={setSelectedTpmsUserType}
                                    labelledBy={"Select User Type"}
                                    overrideStrings={{ selectSomeItems: "Select User Type" }}
                                  />
                                  <MultiSelect
                                    options={locationOptions}
                                    value={selectedLocation}
                                    onChange={setSelectedLocation}
                                    labelledBy={"Select Location"}
                                    overrideStrings={{ selectSomeItems: "Select Location" }}
                                  />
                                  <MultiSelect
                                    options={tpmsUsersOptions}
                                    value={selectedTpmsUsers}
                                    onChange={setSelectedTpmsUsers}
                                    labelledBy={"Select User"}
                                    overrideStrings={{ selectSomeItems: "Select User" }}
                                  />


                                </div>
                              }




                              <Checkbox defaultChecked name="primary" onChange={handleCheckboxChange} key="primary" label="Primary Email" />
                              <Checkbox key="secondary" name="secondary" onChange={handleCheckboxChange} label="Secondary Email" />


                            </div>
                            {PrimarymailList && PrimarymailList.length > 0 &&
                              <h4>Primary Mail Count  <Badge children={PrimarymailList.length} color="teal" /></h4>
                            }
                            {SecondarymailList && SecondarymailList.length > 0 &&
                              <h4>Secondary Mail Count  <Badge children={SecondarymailList.length} color="teal" /></h4>
                            }
                            {/* {(PrimarymailList.length > 0 || invalidEmailList.length > 0 || blockedEmailList.length  > 0) &&  */}
                            {(invalidEmailList.length > 0 || blockedEmailList.length > 0) &&

                              <Accordion>
                                {invalidEmailList.length > 0 &&
                                  <AccordionSection
                                    // icon={<FontAwesomeIcon icon={faUsers} className="rainbow-color_brand" />}
                                    // label={invalidEmailList.length + " Invalid Emails"}
                                    label={<> <div className="text-center flex justify-between items-center">{"Invalid Emails "}<div className="rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">
                                      Count <Chip className="rainbow-m-around_medium" label={invalidEmailList.length} variant={"error"} />
                                    </div></div></>}
                                  >
                                    {invalidEmailList.map((prop, key) => (
                                      <>
                                        {/* {prop.mail!='' || prop.mail!=null && */}

                                        <b>{prop.type} Email : </b>  {prop.mail}  ({prop.status})<br></br>
                                        {prop.inst_name}, ({prop.inst_type_name}   {prop.district_name}) <br></br>
                                        <b>Status : </b> {prop.reason} <b>Last Updated : </b>  {prop.updatedAt}<br></br>

                                        <hr></hr>
                                      </>
                                      // } 

                                      // <Card
                                      //   style={{ marginTop: 16 }}
                                      //   type="inner"
                                      //   title={<b>{prop.mail}</b>}
                                      //   extra={<b>{prop.status}</b>}
                                      // >
                                      //   <b>Status : </b> {prop.reason}<br></br>
                                      //  <b>Last Updated : </b>  {prop.updatedAt}
                                      // </Card>
                                    ))}
                                  </AccordionSection>
                                }
                                {blockedEmailList.length > 0 &&
                                  <AccordionSection
                                    // icon={<FontAwesomeIcon icon={faUser} className="rainbow-color_brand" />}
                                    // label={blockedEmailList.length + " Blocked Emails"}
                                    label={<> <div className="text-center flex justify-between items-center">{"Blocked Emails "}<div className="rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">
                                      Count <Chip className="rainbow-m-around_medium" label={blockedEmailList.length} variant={"error"} />
                                    </div></div></>}
                                  >
                                    {blockedEmailList.map((prop, key) => (
                                      // <Card
                                      //   style={{ marginTop: 16 }}
                                      //   type="inner"
                                      //   title={<b>{prop.mail}</b>}
                                      //   extra={<b>{prop.status}</b>}
                                      // >
                                      //  <b>Status : </b> {prop.reason}<br></br>
                                      //  <b>Last Updated : </b> {prop.updatedAt}
                                      // </Card>
                                      <>
                                        {/* {prop.mail!='' || prop.mail!=null && */}
                                        <>
                                          <b>{prop.type} Email : </b>  {prop.mail}  ({prop.status})<br></br>
                                          {prop.inst_name}, ({prop.inst_type_name}   {prop.district_name}) <br></br>
                                          <b>Status : </b> {prop.reason}  <b>Last Updated : </b>  {prop.updatedAt}<br></br>

                                          <hr></hr>
                                        </>
                                        {/* } */}
                                      </>
                                    ))}
                                  </AccordionSection>
                                }
                              </Accordion>
                            }

                          </div>
                          {/* </form> */}
                        </Spin>

                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">


                          <button
                            className=" text-white bg-teal-500 border-teal-500 active:bg-teal-600 active:border-teal-600 font-bold uppercase text-xs px-3 py-2 shadow hover:shadow-md rounded-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            name="to"
                            onClick={handleAdd}
                            disabled={Loader}
                          >
                            Add To
                          </button>
                          <button
                            className="text-blueGray-800 bg-blueGray-200 border-blueGray-200 active:bg-blueGray-300 active:border-blueGray-300 font-bold uppercase text-xs px-3 py-2 shadow hover:shadow-md rounded-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            name="cc"
                            onClick={handleAdd}
                            disabled={Loader}
                          // onClick={() => setShowModal(false)}
                          >
                            Add CC
                          </button>
                          <button
                            className="text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900 font-bold uppercase text-xs px-3 py-2 shadow hover:shadow-md rounded-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            name="bcc"
                            onClick={handleAdd}
                            disabled={Loader}
                          // onClick={() => setShowModal(false)}
                          >
                            Add BCC
                          </button>
                          <button
                            className="text-red-500 background-transparent font-bold uppercase text-xs px-3 py-2 shadow hover:shadow-md rounded-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={onCloseModal}
                            disabled={Loader}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-75 fixed inset-0 z-40 bg-white"></div>
                </>
              ) : null}







            </div>
          </div>
          {/* <MailSendSpinner /> */}
          <Spin spinning={Loader} indicator={<MailSendSpinner />}>
            <div className="flex-auto px-6 pb-6 pt-0">
              <form encType="multipart/form-data" >
                {cardEmailForm.forms.map((prop, key) => (

                  <div key={key}>
                    <h6 className="mt-6 mb-2 font-bold">{prop.title}</h6>
                    <hr className="mb-6 border-b-1 border-blueGray-200" />
                    <div className="flex flex-wrap -mx-4">
                      {prop.inputs &&
                        prop.inputs.map((inputProp, inputKey) => (
                          <div
                            key={inputKey}
                            className={"w-full px-4 " + widths[inputProp.width]}
                          >
                            <div className="relative w-full mb-3">
                              {inputProp.label && inputProp.display && (
                                <>
                                  <label
                                    className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                                    htmlFor="grid-password"
                                  >
                                    {inputProp.label}
                                    {inputProp.file && inputProp.display && <span className="px-2 py-4 pt-2">
                                      <HelpText
                                        title="Attachment Lits"
                                        text={<p >Maximum Upload {user.privillege.attachmentLimit} files, Maximum Total Attachment Size {user.privillege.attachmentSize} MB</p>}
                                      /></span>}

                                  </label>
                                  {/* {(inputProp.input  ? 
                                  <HelpText
                                  variant="question"
                                  title="Message Title"
                                  text={<p style={{ width: '250px' }}>{inputProp.input.tooltip}</p>}
                              /> : "")} */}
                                </>
                              )}
                              {/* {inputProp.input && <Tooltip title={(inputProp.input.tooltip ? inputProp.input.tooltip : "")} color='#108ee9' key='#108ee9'>
                              {inputProp.input && <Input {...inputProp.input} />}
                              </Tooltip> } */}
                              {inputProp.input && <Tooltip title={(inputProp.input.tooltip ? inputProp.input.tooltip : "")} color='#108ee9' key='#108ee9'>

                                {inputProp.input && <Input {...inputProp.input} />}
                              </Tooltip>}

                              {/* {inputProp.select && <Select {...inputProp.select} />} */}
                              {/* {inputProp.CKediter && <CKEditer {...inputProp.CKediter} />} */}
                              {
                                inputProp.CKediter &&
                                <CKEditor
                                  editor={FullEditor}
                                  config={editorConfiguration}
                                  data={content}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                    editor.editing.view.change((writer) => {
                                      writer.setStyle(
                                        "min-height",
                                        "200px",
                                        editor.editing.view.document.getRoot()
                                      );
                                    });
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setContent(data);
                                    console.log({ event, editor, data });

                                  }}
                                  onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                  }}
                                  onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                  }}
                                />
                              }

                              {inputProp.file && inputProp.display && <Dragger {...inputProp.uploadProps}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>

                              </Dragger>}
                              {inputProp.browse && inputProp.display && <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />}
                              {inputProp.button && <Button {...inputProp.button} />}
                              {inputProp.dropdown && <DropdownButton  {...inputProp.dropdown} />}

                              {inputProp.table && inputProp.display &&

                                <Form form={form} component={false}>
                                  <Spin size="small" spinning={VerifyLoader} indicator={<MailVerifySpinner />} ><Table
                                    components={{
                                      body: {
                                        cell: EditableCell,
                                      },
                                    }}
                                    bordered
                                    dataSource={data}
                                    columns={mergedColumns}
                                    // rowClassName="editable-row"
                                    rowClassName={(record, index) => {
                                      if (record.attachment_status === 1 || record.attachment_status2 === 1 || record.attachment_status3 === 1) {
                                        return "blue editable-row";
                                      }


                                      return "editable-row grey";
                                    }}
                                    pagination={{
                                      onChange: cancel,
                                    }}
                                    onRow={(record, rowIndex) => {
                                      return {
                                        onClick: (event) => { (record.attachment_status === 1 ? downloadFile(record.link) : message.error("file not uploaded.")) }, // click row
                                        onDoubleClick: (event) => { }, // double click row
                                        onContextMenu: (event) => { }, // right button click row
                                        onMouseEnter: (event) => { }, // mouse enter row
                                        onMouseLeave: (event) => { }, // mouse leave row
                                      };
                                    }}
                                    loading={tableLoading}
                                    scroll={{ x: 1300 }}
                                  />
                                  </Spin>
                                </Form>
                              }

                              {inputProp.display && inputProp.chip && <ChipInput2 key={inputKey} error={inputProp.error} items={inputProp.items} buttonClick={inputProp.buttonClick} {...inputProp.chip} />}
                              {inputProp.select && <MultiSelect
                                options={countryOptions}
                                value={selectedCountry}
                                onChange={setSelectedCountry}
                                labelledBy={"Select Country"}
                                isLoading={countryLoading}
                                // disabled={true}
                                overrideStrings={{ selectSomeItems: "Select Country" }}
                              />
                              }
                              {inputProp.singleSelect &&
                                <Select
                                  showSearch
                                  style={{ width: '100%' }}
                                  showArrow={false}
                                  size='middle'

                                  {...(inputProp.singleSelect.defaultValue ? { defaultValue: inputProp.singleSelect.defaultValue } : {})}

                                  mode={inputProp.singleSelect.mode ? inputProp.singleSelect.mode : ''}
                                  placeholder={inputProp.singleSelect.placeholder}
                                  onChange={inputProp.singleSelect.onChange}
                                  optionLabelProp="label"
                                  optionFilterProp="label"
                                // filterOption={(input, option) =>
                                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                // }
                                // filterSort={(optionA, optionB) =>
                                //   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                // }
                                >
                                  {/* {const unique = [...new Set(data.map(item => item.group))]} */}
                                  {inputProp.singleSelect.option && inputProp.singleSelect.mode === 'multiple' &&
                                    <>

                                      {/* {[...new Set(inputProp.singleSelect.option.map(item => item.locName))].map((propGroup, key) => (
                                      <OptGroup label={propGroup.locName}> */}
                                      {inputProp.singleSelect.option.map((prop, key) => (

                                        <Option

                                          disabled={
                                            inputProp.singleSelect.value.length > 1
                                              ? inputProp.singleSelect.value.includes(prop.value)
                                                ? false
                                                : true
                                              : false
                                          }

                                          key={key}
                                          value={prop.value}
                                          label={prop.label}>

                                          <div className="demo-option-label-item flex flex-wrap">
                                            <span role="img" aria-label="image">
                                              <img width="20" alt="image" height="40" src={`${prop.url}/${prop.profile_pic}`}></img>

                                            </span>
                                            <span>{prop.label}</span>
                                          </div>

                                        </Option>
                                      ))}
                                      {/* </OptGroup>
                                   ))} */}
                                    </>
                                  }

                                  {inputProp.singleSelect.option && !inputProp.singleSelect.mode &&
                                    inputProp.singleSelect.option.map((prop, key) => (

                                      <Option

                                        key={key}
                                        value={prop.value}
                                        label={prop.label}>{prop.label}
                                      </Option>
                                    ))
                                  }


                                </Select>
                              }
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
                {/* <span className="px-2 py-4 pt-2">
               <HelpText
            title="Schedule Your Mail"
            text={<p >Maximum 48 Hours you can schedule.</p>}
        />
               </span> */}



                {/* <DateTimePicker
                  formatStyle="large"
                  value={date}
                  label="Select Schedule Date and Time"
                  onChange={onChangeDate}
                  hour24
                  placeholder="Schedule Date & Time"
                  minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes(), new Date().getSeconds())}
                  maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2, 23, 59, 0)}
                  hideLabel={true}
                  className="rainbow-m-around_small"
                  style={inputStyles}
                /> */}

                {date && <Button size="sm" color="dark" children="reset Date" onClick={resetDate} />}


                {cardEmailForm.button.map((prop, key) => (
                  <>
                    {prop.name === "schedule" && <Button size={prop.size} id={prop.id} name={prop.name} color={(!items || !subject || !content || buttonVal || !date || wait) ? 'light' : prop.color} disabled={(!items || !subject || !content || buttonVal || !date || wait)} children={prop.id === buttonVal ? "Proccessing..." : prop.children} onClick={handleEmailSubmit} />}
                    {prop.name === "send" && <Button size={prop.size} id={prop.id} name={prop.name} color={(!items || !subject || !content || buttonVal || date || wait || data.lenght == 0) ? 'light' : prop.color} disabled={(!items || !subject || !content || buttonVal || date || wait || data.length == 0)} children={prop.id === buttonVal ? "Proccessing..." : prop.children} onClick={handleEmailSubmit} />}
                    {prop.name === "draft" && <Button size={prop.size} id={prop.id} name={prop.name} color={prop.color} disabled={(!buttonVal)} children={prop.id === buttonVal ? "Proccessing..." : prop.children} onClick={handleEmailSubmit} />}
                    {prop.name === "verifyExcel" && <Button size={prop.size} id={prop.id} name={prop.name} color={(data.length == 0) ? 'light' : prop.color} disabled={(data.length == 0)} children={prop.id === buttonVal ? "Proccessing..." : prop.children} onClick={handleExcelVerify} />}

                  </>
                ))}



              </form>

            </div>
          </Spin>
        </div>
      </div>


    </>
  );
}

const inputsObject = {
  // NOTE: this width only applies on large devices
  width: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  // if you wish, you can send somthing like
  // <span className="mr-2">Label Name</span><span className="text-red-500">*</span>
  // NOTE: the label tag will be auto generated
  label: PropTypes.node,
};

const inputsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Input component
  input: PropTypes.object,
});

const selectsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Select component
  select: PropTypes.object,
});

const inputsTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([inputsShape, selectsShape])
);

CardBulkEmail.defaultProps = {
  button: {},
  forms: [],
};

CardBulkEmail.propTypes = {
  title: PropTypes.string,
  // props to pass to the Button component
  button: PropTypes.object,

  forms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      inputs: inputsTypes,
    })
  ),
};
