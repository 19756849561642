const textProps = {
  copyright: {
    children: "KILA",
    href: "https://www.kila.ac.in",
    target: "_blank",
  },
  socials: [
    { icon: "twitter", link: "https://twitter.com/kilathrissur" },
    { icon: "facebook", link: "https://www.facebook.com/kilatcr" },
    { icon: "instagram", link: "https://www.instagram.com/kilatcr/" },
    { icon: "youtube", link: "https://youtube.com/kilatcr" },
    // { icon: "github", link: "https://github.com/creativetimofficial" },
  ],
  links: [
    {
      href: "https://kila.ac.in",
      target: "_blank",
      children: "KILA",
    },
    {
      href: "https://www.kila.ac.in/about-us/",
      target: "_blank",
      children: "About Us",
    },
  ],
};
export default textProps;
