import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';


// components
import TabsEmail from "components/Tabs/TabsEmail.js";
import moment from 'moment'
import axios from "axios";
import MaintenanceBlog from 'components/Headers/Blog/MaintenanceBlog';
export default function Email() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const user = useSelector(state => state.authentication.user);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [maintanance, setMaintanance] = useState(false)
  const [inputs, setInputs] = useState({
    mail_maintenance_date: '',
    mail_maintenance_help: '',

  });
  const {mail_maintenance_date, mail_maintenance_help } = inputs;
  useEffect(() => {
    axios.get(`${apiUrl}/api/config/global-settings`).then(response => {
      response.data.result.map((prop, key) => {
        if (prop.config_key === 'mail_maintenance') {
          var config = JSON.parse(prop.config_value)
          var beginningTime = moment(moment().format("YYYY-MM-DD HH:mm"), 'YYYY-MM-DD HH:mm');
          var endTime1 = moment(config.from, 'YYYY-MM-DD HH:mm');
          var endTime2 = moment(config.to, 'YYYY-MM-DD HH:mm');
          // console.log("Beg Time = "+beginningTime.toString());
          // console.log("End Time1 = " +endTime1.toString());
          // console.log("End Time2 = "+endTime2.toString());
          setMaintanance(beginningTime.isBetween(endTime1, endTime2) && config.status === 1);
          console.log(maintanance)
          // console.log("time mmmmmm"+bool1);
          setInputs(inputs => ({ ...inputs, ['mail_maintenance_date']: [moment(config.from), moment(config.to)] }));
          // setSystemStatus(config.status==1 ? true : false)
          setInputs(inputs => ({ ...inputs, ['mail_maintenance_help']: config.help_text }));
        }



      })
    });

  }, []);

  const Maintenanceprops = {
    // title: "Miami Beach is looking different at night!",
    date: `${moment(mail_maintenance_date[0]).format("Do MMMM YYYY, h:mm a")} To ${moment(mail_maintenance_date[1]).format("Do MMMM YYYY, h:mm a")}`,
    quote: "'We Are Under Maintenance'",
    quoteSource: "- We will come back soon",
    description:
    mail_maintenance_help,
    icon: "fas fa-tools",
    color: "amber",
    image: "/notus-pro-react/static/media/photo-1.002cf08f.jpg",
  }

  return (
    <>
     
      
          <div className="flex flex-wrap">
          <div className="w-full xl:w-12/12 px-4">
          {maintanance && !user.roles.includes('ADMIN') &&
              <>
                   
                    <MaintenanceBlog {...Maintenanceprops} />                   
                           
              </>

            }
            {(!maintanance || user.roles.includes('ADMIN')) &&   <TabsEmail color="pink" />}
          {/* <CardFullTable {...cardfulltable} /> */}
        
          </div>
            
          </div>
         
      
    </>
  );
}
