import React from "react";
import { useLocation } from "react-router-dom";


// components
import RolePermissionTab from "components/Tabs/RolePermissionTab";

export default function RolePermissions() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  

  return (
    <>
     
      
          <div className="flex flex-wrap">
          <div className="w-full xl:w-12/12 px-4">
          {/* <CardFullTable {...cardfulltable} /> */}
          <RolePermissionTab color="pink" />
          </div>
            
          </div>
         
      
    </>
  );
}
