import React from "react";

import lottie from "lottie-web";
import verifyEmail from "animations/email-motion-loading";

export default function Brand1() {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#brand-logo"),
          animationData: verifyEmail
        });
      }, []);
  return (
    <>
      <div id="brand-logo"></div>
    </>
  );
}
