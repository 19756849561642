import React from "react";

import lottie from "lottie-web";
import verifyEmail from "animations/404-notfound";

export default function Error404() {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#error-logo"),
          animationData: verifyEmail
        });
      }, []);
  return (
    <>
      <div className="w-screen h-screen" id="error-logo"></div>
    </>
  );
}
