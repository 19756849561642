import React from "react";
import { createPopper } from "@popperjs/core";
import { Link } from "react-router-dom";
import {  useSelector } from 'react-redux';
import { authHeader } from '../../_helpers';
import axios from 'axios';
import { toast } from 'react-toastify';

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const user = useSelector(state => state.authentication.user);
  const user_fields = JSON.parse(localStorage.getItem('user'));
  const apiUrl=process.env.REACT_APP_API_URL;
 

  function logout(e){
    e.preventDefault()
    // dispatch(userActions.logout());
    axios.post(`${apiUrl}/api/auth/signinOut`, {}, {
      headers: authHeader()
    }).then(response => {
      toast.success("Hi "+user.name + " You are succefully logout", {});
      localStorage.removeItem('user');
      localStorage.clear();
      if (response.status === 200) {
        window.location = "/login"
     }
      
    });
  }
  
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full h-full rounded-full align-middle border-none shadow-lg"
              // src={require("assets/img/team-1-800x800.jpg").default}
              src={(!localStorage.getItem('user')) ? "" : user_fields.profile_image}
            />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => e.preventDefault()}
        >
          {user.name} 
        </a>
        
        {/* <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => e.preventDefault()}
        >
          Something else here
        </a> */}
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <Link
        to="/profile"
          // href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          // onClick={(e) => e.preventDefault()}
        >
          Profile
        </Link>
        {/* <Link
       
          onClick={logout}
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
         
        >
          logout
        </Link> */}
         <a
          // href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={logout}
        >
          Logout
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
