import React from "react";
import PropTypes from "prop-types";

// components

export default function CardMailListIcon({
 
  id,
  gradient,
  title,
  description,
}) {
  
  return (
    <>
      {/* <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"> */}
        <div className="px-4 py-5 flex-auto" id={id} >
       
          {/* <div
            className={
              "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full " +
              iconBgColor[iconColor + gradientText]
            }
          >
            <i className={icon}></i>
          </div> */}
          <h6 className="text-xm font-semibold">{title}</h6>
          <p className="mt-2 mb-4 text-xs text-blueGray-400">{description}</p>
          {/* <Button {...button} /> */}
        </div>
      {/* </div> */}
    </>
  );
}
CardMailListIcon.defaultProps = {
  iconColor: "lightBlue",
  gradient: true,
};

CardMailListIcon.propTypes = {
  // icon name to be added on the <i></i> tag, for example "fa fa-heart"
  icon: PropTypes.string,
  // the background of the icon
  iconColor: PropTypes.oneOf([
    "blueGray",
    "red",
    "orange",
    "amber",
    "emerald",
    "teal",
    "lightBlue",
    "indigo",
    "purple",
    "pink",
  ]),
  // if the background should have a gradient
  gradient: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};
