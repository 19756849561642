import React, { useEffect, useState,useRef } from "react";
import { Table, Space,Modal,Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { authHeader } from '../../../_helpers';
import axios from "axios";
import { errorHandler } from "error/errorHandler";
import Button from "../../Elements/Button.js";
import Input from "../../Elements/Input.js";


const OtherUsersCardTable = () => {
    const apiUrl=process.env.REACT_APP_API_URL;
    const [state, setState] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 5,
        },
        loading: false,
      
        eventData: [],
        eventDataSummary:[],
        searchText: '',
        searchedColumn: '',

    });
    const { data, pagination, loading,searchText,searchedColumn } = state;
    
    
    let searchInput = useRef(null);
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="sm"
              color="indigo"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="sm" color="light" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="link"
              color="indigo"
              size="sm"
              onClick={() => {
                confirm({ closeDropdown: false });
               
                setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
                setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
      setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
    };
  
   const  handleReset = clearFilters => {
      clearFilters();
      setState(state => ({ ...state, ['searchText']: '' }));
     
    };
    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
            //   render: subj => `${name.first} ${name.last}`,
            // width: '30%',
            // expandable: true,
            responsive: ['lg'],
            ...getColumnSearchProps('email'),
        },
       
        
       
       
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'x',
            render: (id) => 
            <>
            {/* <Button outline={true} color="lightBlue" children="Forwad" name="Status" id={mlogid} size="sm" onClick={deliveryStatus} /> */}
            {/* <Button color="lightBlue" children="Verify" name="Status" id={reg_inst_id} size="sm" onClick={deliveryStatus} /> */}
            <Button color="red" children="Delete" name="Status" id={id} size="sm" onClick={deleteUser} />
            </>,
            responsive: ['lg'],
        },
    ];

   
    useEffect(() => {
        const { pagination } = state;
        fetch({ pagination });
    }, []);
    const handleTableChange = (pagination, filters, sorter) => {
        fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    fetch = (params = {}) => {
        setState(state => ({ ...state, ['loading']: true }));
       
        axios.post(`${apiUrl}/api/users/otherUsers`, {
            status: [2], results: params.pagination.pageSize, page: params.pagination.current,
            ...params
        }, {
            headers: authHeader()
        }).then(response => {
            setState({
                loading: false,
                data: response.data.result,
                pagination: {
                    ...params.pagination,
                    total: response.data.totalRows,

                },
                
            });

        }).catch((error) => {
            errorHandler(error);
        });
    };

    
    function deleteUser(e) {
      e.preventDefault();
      const {  id } = e.target;
      const { confirm } = Modal;
      confirm({
        // icon: <ExclamationCircleOutlined />,
        content: <span>Are You Sure</span>,
        onOk() {
      setState(state => ({ ...state, ['loading']: true }));
      // setState(state => ({ ...state, ['visible']: true }));
      axios.get(`${apiUrl}/api/users/delete-others/${id}`, {
        headers: authHeader()
      }).then(response => {
        setState(state => ({ ...state, ['loading']: false }));
        toast.success("User Deleted", {
          autoClose: true
        })
        const { pagination } = state;
        fetch({ pagination });
  
      })
        .catch((error) => {
          errorHandler(error);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  
  
    }
    const inputprops = {
      placeholder: "Search  ",
      leftIcon: "fas fa-search",
      size: "sm",
      border: "borderless",
  }
    const [searchVal, setSearchVal] = useState('');
      const [filteredResults, setFilteredResults] = useState([]);
      const searchItems = (searchValue, field) => {
          setSearchVal(searchValue)
          if (searchVal !== '') {
              const filteredData = data.filter((item) => {
                  return Object.values(item).join('').toLowerCase().includes(searchVal.toLowerCase())
              })
              setFilteredResults(filteredData)
  
          }
          else {
              setFilteredResults(data)
  
          }
  
      }
    return (
        <>
        <div className="w-full item-center xl:w-12/12 px-2">
                  <Divider><Input name="to" onChange={(e) => searchItems(e.target.value, e.target.name)} {...inputprops} /></Divider>
                </div>
            
            <Table
                columns={columns}
                rowKey={record => record.mlogid}
                dataSource={(searchVal.length > 1 ? filteredResults : data)}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                // expandable={expandable}
                expandRowByClick={true}
            />
        </>
    );

}
export default OtherUsersCardTable;
// ReactDOM.render(<CardTable />, mountNode);