import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { errorHandler } from "error/errorHandler";
import { MultiSelect } from "react-multi-select-component";
import 'antd/dist/antd.css';
// import ImgCrop from 'antd-img-crop';
import CardProfileImportPage from 'components/Cards/ECommerce/CardProfileImportPage'


// components
import Button from "../../Elements/Button.js";
import Input from "../../Elements/Input.js";
// import Select from "../../Elements/Select.js";

import { Spin, Switch,Slider, Table, Space, Tag,Select,Divider,Popconfirm,Avatar,List } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { authHeader } from '../../../_helpers';
import { Drawer } from 'react-rainbow-components';
import { Steps } from 'antd';

const { Step } = Steps;
const { Option } = Select;


export default function UsersCardTable() {
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const widths = {
    1: "lg:w-1/12",
    2: "lg:w-2/12",
    3: "lg:w-3/12",
    4: "lg:w-4/12",
    5: "lg:w-5/12",
    6: "lg:w-6/12",
    7: "lg:w-7/12",
    8: "lg:w-8/12",
    9: "lg:w-9/12",
    10: "lg:w-10/12",
    11: "lg:w-11/12",
    12: "lg:w-12/12",
  };
  const cardprofilefullpage = {
    image: "/static/media/team-1-800x800.fa5a7ac2.jpg",
    name: "Jenna Stones",
    location: "Los Angeles, California",
    // description:
    //   "An artist of considerable range, Jenna the name taken by Melbourne-raised, Brooklyn-based Nick Murphy writes, performs and records all of his own music, giving it a warm, intimate feel with a solid groove structure. An artist of considerable range.",
    // showMore: { children: "Show more", href: "#pablo" },
    buttons: [
      { children: "Import", color: "lightBlue", size: "sm" },
      { children: "Cancel", color: "dark", size: "sm" },
    ],
    achievements: [
      {
        icon: "fas fa-briefcase",
        text: "Solution Manager - Creative Tim Officer",
      },
      { icon: "fas fa-university", text: "University of Computer Science" },
    ],
  }
  const [Activetoggle, setToggle] = useState(true);
  const [Logintoggle, setLoginToggle] = useState(false);
  const [LoginMobiletoggle, setLoginMobileToggle] = useState(false);
  const [configToggle, setConfigToggle] = useState(false);
  

  const [attachmentSize, setSize] = useState();
  const [attachmentLimit, setLimit] = useState();
  const [addressAdd, setAddressAdd] = useState(true);
  const [createMode, setCreateMode] = useState(true);

  const [inputs, setInputs] = useState({
    user_id: '',
    user_off_landline: '',
    user_mobile:'',
    user_mail: '',
    user_name: '',
    designation:'',
    user_image: '',
    tpms_id:'',
    tpms_name:'',
    tpms_email:'',
    tpms_mobile:'',
    tpmsUserList:[],
  });
  const { user_id,user_off_landline, user_mail, user_mobile, designation,user_name,user_image,tpms_id,tpms_name,tpms_email,tpms_mobile,tpmsUserList } = inputs;
  function handleChangeInput(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const handleRoleChange = value => {
    console.log(`selected ${value}`);
    setSelectedRole(value);
  };

  const [fromAddOptions, setFromAddOptions] = useState([]);
  const [selectedFromAdd, setSelectedFromAdd] = useState([]);

  function handleToggleStatus(value) {
    setToggle(value);
  }
  function handleToggleLogin(value) {
    setLoginToggle(value);
  }
  function handleToggleLoginMobile(value) {
    setLoginMobileToggle(value);
  }
  function handleToggleConfig(value) {
    setConfigToggle(value);
  }
  
  function handleSizeSliderChange(value) {
    setSize(value)

  }
  function handleLimitSliderChange(value) {

    setLimit(value)
  }
  function handleLimitSliderChangeInput(e) {
    
    setLimit(e.target.value)
  }
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const cardFormUserImport = {
    title: "Search User From TPMS",

    forms: [

      {
        title: "Basic Details",

        inputs: [

          {
            label: "Email",
            width: 3,
            input: {
              placeholder: "From Address",
              type: "email",
              name: 'tpms_email',
              value: tpms_email,
              onChange: handleChangeInput
            },
          },
          {
            label: "Mobile",
            width: 3,
            input: {
              placeholder: "Mobile",
              type: "number",
              name: 'tpms_mobile',
              value: tpms_mobile,
              onChange: handleChangeInput
            },
          },

          {
            label: "Name",
            width: 3,
            input: {
              placeholder: "Name",
              type: "text",
              name: 'tpms_name',
              value: tpms_name,
              onChange: handleChangeInput
            },
          },



          {
            label: "Search",
            width: 3,
            button: {
              children: "Find User",
              size: "regular",
              color: "emerald",
            },
          },
        ],
      },
    ],
  };
  const cardFormUser = {
    title: "Add / Edit New End User",
    forms: [

      {
        title: "Basic Details",

        inputs: [
          {
            label: "Name *",
            width: 3,
            input: {
              placeholder: "Reply To",
              type: "text",
              name: 'user_name',
              value: user_name,
              onChange: handleChangeInput
            },
          },

          {
            label: "Email *",
            width: 3,
            input: {
              placeholder: "From Address",
              type: "email",
              name: 'user_mail',
              value: user_mail,
              onChange: handleChangeInput
            },
          },
          {
            label: "Mobile *",
            width: 3,
            input: {
              placeholder: "Mobile",
              type: "text",
              name: 'user_mobile',
              value: user_mobile,
              onChange: handleChangeInput
            },
          },
          {
            label: "Designation",
            width: 3,
            input: {
              placeholder: "Designation",
              type: "text",
              name: 'designation',
              value: designation,
              onChange: handleChangeInput
            },
          },
          {
            label: "Location *",
            width: 4,
            multiselect: {
              options: locationOptions,
              value: selectedLocation,
              onChange: setSelectedLocation,
              labelledBy: "Select Location",
              overrideStrings: { selectSomeItems: "Select Location" }
            },
          },
          // {
          //   label: "Designation",
          //   width: 3,
          //   multiselect: {
          //     options: locationOptions,
          //     value: selectedLocation,
          //     onChange: setSelectedLocation,
          //     labelledBy: "Select Country",
          //     overrideStrings: { selectSomeItems: "Select Location" }
          //   },
          // },
          {
            label: "Official Landline",
            width: 4,
            input: {
              placeholder: "Landline",
              type: "text",
              name: 'user_off_landline',
              value: user_off_landline,
              onChange: handleChangeInput
            },
          },
          {
            label: "Role *",
            width: 3,
            multiselect: {
              options: roleOptions,
              value: selectedRole,
              onChange: setSelectedRole,
              labelledBy: "Select Role",
              overrideStrings: { selectSomeItems: "Select Role" },
              hasSelectAll:false
            },
          },
          // {
          //   label: "Role",
          //   width: 3,
          //   singleSelect: {
          //     placeholder: "Role",
          //     option: roleOptions,
          //     onChange: handleRoleChange,
          //     // onChange:handleSingleSelect,
          //     value: selectedRole,
          //     // mode:'multiple',
          //     // allowClear:true,


          //   },
          // },
          // {
          //   label: "Image",
          //   width: 4,
          //   image: {
          //   },
          // },
          {
            label: "Status",
            width: 1,
            switch: {
              id: 1,
              name: "user_status",
              checked: Activetoggle,
              // size:"small",
              onChange: handleToggleStatus,
            },
          },
          
          

        ],
      },
      
    ],
  };
  const cardFormConfiguration = {
    title: "Set Configuration Or Use Global Configuration",
    forms: [
      {
        title: "Default Use Golbal Configuration and Permissions if you want to use custom configuration change the topggle status",
        inputs: [
          {
            label: "Use Custome Settings",
            width: 3,
            switch: {
              id: 5,
              name: "config",
              checked: configToggle,
              // size:"small",
              onChange: handleToggleConfig,
            },
            display:true
          },
        ]
      },
      {
        title: "Configuration and Permissions",

        inputs: [
          {
            label: "From Address",
            width: 6,
            name: "FromAdd",
            multiselect: {
              options: fromAddOptions,
              value: selectedFromAdd,
              onChange: setSelectedFromAdd,
              labelledBy: "Select From Address",
              overrideStrings: { selectSomeItems: "Select From Address" }
            },
            display:configToggle
          },
          {
            label: "Login using Mobile",
            width: 3,
            switch: {
              id: 6,
              name: "login_mobile",
              checked: LoginMobiletoggle,
              // size:"small",
              onChange: handleToggleLoginMobile,
            },
            display:configToggle
          },
          {
            label: "Login Outside KILA",
            width: 3,
            switch: {
              id: 5,
              name: "loginPermission",
              checked: Logintoggle,
              // size:"small",
              onChange: handleToggleLogin,
            },
            display:configToggle
          },
        
          {
            label: "Maximum Attachment Limit",
            width: 5,
            name: "attachment_limit",
            numberSlider: {
              min: 0,
              max: 100,
              // defaultValue:attachmentLimit,
              step: 1,
              // trackStyle:{
              //   backgroundColor: '#6366f1',
              // },
              // handleStyle:{
              //   backgroundColor: '#6366f1',
              // },
              onChange:handleLimitSliderChange,
              value: attachmentLimit,
            },
            display:configToggle
          },
          {
            label: "",
            width: 1,
            name: "attachment_limit",
            input: {
              placeholder: "Limit",
              type: "text",
              max:100,
              disabled:true,
              name: 'attachment_limit',
              value: `${attachmentLimit} Nos`,
              onChange: handleLimitSliderChangeInput
            },
            display:configToggle
          },
          {
            label: "Mail Size",
            width: 6,
            name: "attachment_size",
            slider: {
              // trackStyle:{
              //   backgroundColor: '#6366f1',
              // },
              marks: {
                
                2: {
                  style: {
                    color: '#35d23a',
                    
                    
                  },
                  label: <strong>2 MB</strong>,
                },
                5: {
                  style: {
                    color: '#8ed235',
                    
                    
                  },
                  label: <strong>5 MB</strong>,
                },
                
                10: {
                  style: {
                    color: '#d7f342',
                    
                    
                  },
                  label: <strong>10 MB</strong>,
                },
                15: {
                  style: {
                    color: '#e8b030',
                    
                    
                  },
                  label: <strong>15 MB</strong>,
                },
                15: {
                  style: {
                    color: '#e8b030',
                    
                    
                  },
                  label: <strong>15 MB</strong>,
                },
                
                20: {
                  style: {
                    color: '#f50',
                    
                    
                  },
                  label: <strong>20 MB</strong>,
                },
                25: {
                  style: {
                
                    color: '#f3092a',
                    
                  },
                  label: <strong>25 MB</strong>,
                },
              },
              min: 0,
              max: 25,
              // step:null, 
              value: attachmentSize,
              onChange: handleSizeSliderChange
            },
            display:configToggle

          },
          {
            label: "",
            width: 12,
            button: {
              children: "Save Changes",
              size: "sm",
              color: "indigo",
            },
            display:true
          },
        ],
      },
    ],
  };
  const cardUser = {
    title: "Users List",
    button: {
      children: "Add User",
      size: "sm",
      color: "lightBlue",
    },
    
  };
  const [show, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    axios.post(`${apiUrl}/api/users/sync-tpms-users`, {
      // type: 1, user_type: 2
    }, {
      // headers: { "Access-Control-Allow-Origin": "*" }
      headers: authHeader()
    })
      .then((response) => {
        // setJobData(response.data.jobData[0])
        toast.success(`TPMS User Sync Started `, {
          autoClose: true
        });
        console.log(response.data)
        setVisible(false);
        setConfirmLoading(false);
      })
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false);
  };

  
  function handleSearchSubmit(e) {
    setInputs(state => ({ ...state, ['tpmsUserList']: [] }));
    setListData([])
    e.preventDefault();
      axios.post(
        `https://tpms.kila.ac.in/api/KilaUserList`,
        {
          name: tpms_name,
          email: tpms_email,
          mobile: tpms_mobile,
         
        }
      ).then((response) => {
        if(response.data.status==="TRUE"){
          
          setInputs(inputs => ({
            ...inputs,
            ['tpmsUserList']: response.data.users,
            
          }));
          console.log(tpmsUserList)
          toast.success(`User Finded `, {
            autoClose: true
          });
        }
        else{
          setInputs(inputs => ({
            ...inputs,
            ['tpmsUserList']: [],
            
          }));
          toast.warning(`No users Found `, {
            autoClose: true
          });
        }
        
        console.log(response.data);
        // setInputs(inputs => ({
        //   ...inputs,
        //   ['from_id']: '',
        //   ['from_mail']: '',
        //   ['from_name']: '',
        //   ['reply_to']: ''
        // }));
        // setSelectedLocation([])

        // fetch({ pagination });
      }).catch(error => {
        errorHandler(error);
      });
    
    
  }
  function handleUserSubmit(e) {
    var loc_array = [];
    var role_array = [];
    var fromadd_array = [];

    if(configToggle){
      
      selectedFromAdd.map((prop, key) => {
        fromadd_array.push(prop.value)
      })
    }
    const { pagination } = state;
    selectedLocation.map((prop, key) => {
      loc_array.push(prop.value)
    })
    selectedRole.map((prop, key) => {
      role_array.push(prop.value)
    })
    e.preventDefault();
    if (user_name !== '' && user_mail !== '' && user_mobile !== '' && loc_array.length > 0 && role_array.length > 0  ) {
    if (user_id > 0) {
    
      axios.put(
        `${apiUrl}/api/users/enduser/${user_id}`,
        {
          user_name: user_name,
          user_mail: user_mail,
          user_mobile: user_mobile,
          designation: designation,
          // user_image:user_image,
          // tpms_id:tpms_id,
          user_off_landline: user_off_landline,
          locations: loc_array.join(),
          role: role_array.join(),
          status: !Activetoggle ? 0 : 1,
          LoginMobile: !LoginMobiletoggle ? 0 : 1,
          LoginOutside: !Logintoggle ? 0 : 1,
          customConfig: !configToggle ? 0 : 1,
          fromAdd: fromadd_array.join(),
          attachmentSize :attachmentSize,
          attachmentLimit :attachmentLimit
        }
      ).then((response) => {
        toast.success(`User updated `, {
          autoClose: true
        });
        console.log(response.data);
        handleDrawerClose();
        setSelectedLocation([])

        fetch({ pagination });
      }).catch(error => {
        errorHandler(error);
      });
    }
    else {
      axios.post(
        `${apiUrl}/api/users/enduser`,
        {
          user_name: user_name,
          user_mail: user_mail,
          user_mobile: user_mobile,
          designation: designation,
          user_image:user_image,
          tpms_id:tpms_id,
          user_off_landline: user_off_landline,
          locations: loc_array.join(),
          role: role_array.join(),
          status: !Activetoggle ? 0 : 1,
          LoginMobile: !LoginMobiletoggle ? 0 : 1,
          LoginOutside: !Logintoggle ? 0 : 1,
          customConfig: !configToggle ? 0 : 1,
          fromAdd: fromadd_array.join(),
          attachmentSize :attachmentSize,
          attachmentLimit :attachmentLimit
        }
      ).then((response) => {
        toast.success(`User Created`, {
          autoClose: true
        });
        console.log(response.data);
        handleDrawerClose();
        // setInputs(inputs => ({
        //   ...inputs,
        //   ['user_name']: '',
        //   ['user_mail']: '',
        //   ['user_mobile']: '',
        //   ['user_name']: ''
        // }));
       
        setSelectedLocation([])
        fetch({ pagination });
      }).catch(error => {
        errorHandler(error);
      });
    }
  }else{
    toast.error(`please fill all details`, {
      autoClose: true
    });

  }
  }
  function handleSubmit(e) {
    e.preventDefault();
    axios.post(
      `${apiUrl}/api/config/global-settings`,
      {
        updateData: [
          { key: 'attachment_size', value: attachmentSize, },
          { key: 'attachment_max_limit', value: attachmentLimit },
          // { key: 'mail_footer', value: footer }
        ]

      }
    ).then((response) => {
      toast.success(`Global Settings updated `, {
        autoClose: true
      });
      console.log(response.data);
    }).catch(error => {
      errorHandler(error);
    });

  }

  const toastId = React.useRef(null);
  useEffect(() => {
    axios.get(`${apiUrl}/api/masters/locations`).then(response => {
      setLocationOptions(response.data);
    });
  },[])
  useEffect(() => {
    
    axios.get(`${apiUrl}/api/config/global-settings`).then(response => {
      // setLocationOptions(response.data);
      console.log(response.data.result)
      response.data.result.map((prop, key) => {
        console.log(prop.config_key)
       if(createMode){
        if (prop.config_key === 'attachment_max_limit') {
          setLimit(prop.config_value)
        }
        if (prop.config_key === 'attachment_size') {
          setSize(prop.config_value)
        }
        if (prop.config_key === 'LoginMobile') {
          setLoginMobileToggle(parseInt(prop.config_value) === 1 ? true : false)
        }
        if (prop.config_key === 'LoginOutside') {
          setLoginToggle(parseInt(prop.config_value) === 1 ? true : false)
        }

       }
        
      })
    });

  }, [configToggle]);
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 5,
    },
    loading: false,
    visible: false,
    searchText: '',
    searchedColumn: '',

  });
  const { data, pagination, loading, visible, searchText, searchedColumn } = state;
  function editUser(e) {
    const { name, id } = e.target;
    e.preventDefault();
    setCreateMode(false)
    setcurrentState(0)
    setState(state => ({ ...state, ['visible']: true }));
    axios.get(`${apiUrl}/api/users/enduser/${id}`, {
      headers: authHeader()
    }).then(response => {
      setInputs(inputs => ({
        ...inputs,
        ['user_name']: response.data.name,
        ['user_mail']: response.data.email,
        ['user_mobile']: response.data.mobile,
        ['designation']: response.data.designation,
        ['user_id']:response.data.id,
        ['tpms_id']:response.data.tpms_id,
        ['user_off_landline']:response.data.landline,
      }));
      setToggle(response.data.status === 1 ? true : false)
      setConfigToggle(response.data.custom_config === 1 ? true : false)
      setSelectedRole(response.data.role)
      if(response.data.custom_config === 1){
        setSelectedLocation(response.data.location)
        setSelectedFromAdd(response.data.fromAddress)
        setLoginToggle(parseInt(response.data.privilege.LoginOutside) === 1 ? true : false)
        setLoginMobileToggle(parseInt(response.data.privilege.LoginMobile) === 1 ? true : false)
        setSize(response.data.privilege.attachmentSize)
        setLimit(response.data.privilege.attachmentLimit)
      }
    
      
    })


  }
  function addUserForm(e) {
    e.preventDefault();
    handleDrawerClose()
    setState(state => ({ ...state, ['visible']: true }));
    setToggle(true)
      setConfigToggle(false)
      setSelectedRole([])
     
        setSelectedLocation([])
        setSelectedFromAdd([])
        setLoginToggle(false)
        setLoginMobileToggle(false)
        
    

  }
  useEffect(() => {
    if (visible) {
      axios.get(`${apiUrl}/api/masters/userRoles`).then(response => {

        // console.log(response.data);
        setRoleOptions(
          response.data
        );
      });
    }
  }, [visible]);
  useEffect(() => {
    if (visible && selectedLocation ) {
      var locationArray=[];
      selectedLocation.map((prop, key) => {
        locationArray.push(prop.value);
      })
      axios.get(`${apiUrl}/api/config/FromaddressLists`).then(response => {
        setFromAddOptions(
          response.data
        );
      });
      if(locationArray.length>0){
      axios.get(`${apiUrl}/api/config/locationFromaddressList/${locationArray}`).then(response => {
        if(createMode)
        setSelectedFromAdd(
          response.data
        );
      });
    }
    }
  }, [selectedLocation]);
  
  function handleDrawerClose() {
    setCreateMode(true);
    setcurrentState(0)
    setState(state => ({ ...state, ['loading']: false }));
    setState(state => ({ ...state, ['visible']: false }));
    // setInputs(state => ({ ...state, ['tpmsUserList']: [] }));
    setInputs(inputs => ({
      ...inputs,
      ['user_id']: '',
      ['tpms_name']: '',
      ['tpms_email']: '',
      ['tpms_mobile']: '',
      ['user_name']: '',
      ['user_mail']: '',
      ['designation']: '',
      ['user_mobile']: '',
      ['tpmsUserList']: [],
      ['user_image']:'',
      ['tpms_id']:'',
      ['user_off_landline']:''
    }));

    setListData([])


  }
  function handleUserImport() {
    toast.success(`User Imported Successfully Note Created !`, {
      autoClose: true
    });
    tpmsUserList[0].map((prop, key) => {
      setcurrentState(currentStep+1);
      setInputs(inputs => ({
        ...inputs,
        ['user_name']: prop.prefix+' '+ prop.name,
        ['user_mail']: prop.email_pri,
        ['user_mobile']: prop.mob_pri,
        ['designation']: prop.desig_name,
        ['user_image']:prop.profile_pic,
        ['tpms_id']:prop.user_id,
        ['user_off_landline']:prop.office_phone,
      }));


    })

  }
  function handleUserImportList(e) {
  
    e.preventDefault();
    const tpms_user_id =e.target.id;
    // const newtpmsUserList = tpmsUserList[0].filter((item) => item.user_id === tpms_user_id);

    let newtpmsUserList = tpmsUserList[0].filter(function(item) {
      return item.user_id == tpms_user_id; });
    console.log(newtpmsUserList)
    newtpmsUserList.map((prop, key) => {
      axios.post(`${apiUrl}/api/users/duplicateCheckUser`, {
        email: prop.email_pri,
        mobile : prop.mob_pri
      }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then((response) => {
          if(response.data.status){
            toast.success(`User Imported Successfully Note Created !`, {
              autoClose: true
            });
            setcurrentState(currentStep+1);
            setInputs(inputs => ({
              ...inputs,
              ['user_name']: prop.prefix+' '+ prop.name,
              ['user_mail']: prop.email_pri,
              ['user_mobile']: prop.mob_pri,
              ['designation']: prop.desig_name,
              ['user_image']:prop.profile_pic,
              ['tpms_id']:prop.user_id,
              ['user_off_landline']:prop.office_phone,
            }));
          }
          else{
            toast.error(`User Email Or Mobile Already in use!`, {
              autoClose: true
            });
          }
          
        })
     


    })

  }
  

  let searchInput = useRef(null);
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="sm"
            color="indigo"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="sm" color="light" style={{ width: 90 }}>
            Cancel
          </Button>
          <Button
            type="link"
            color="indigo"
            size="sm"
            onClick={() => {
              confirm({ closeDropdown: true });

              setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
              setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
              setState(state => ({ ...state, ['loading']: false }));

            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
    setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
    setState(state => ({ ...state, ['loading']: false }));
  };

  const handleReset = clearFilters => {
    clearFilters();
    setState(state => ({ ...state, ['searchText']: '' }));
    setState(state => ({ ...state, ['loading']: false }));

  };

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   sorter: true,
    //   //   render: subj => `${name.first} ${name.last}`,
    //   // width: '30%',
    //   // expandable: true,
    //   // responsive: ['md'],
    //   fixed: 'left',
    //   ...getColumnSearchProps('id'),
    // },
   
    {
      title: '',
      dataIndex: 'profile_image',
      sorter: true,
        render: profile_image =>  <Avatar src={profile_image} />,
      // width: '30%',
      // expandable: true,
      // responsive: ['md'],
      // fixed: 'left',
      // ...getColumnSearchProps('id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      // sorter: true,
      //   render: subj => `${name.first} ${name.last}`,
      // width: '20%',
      // responsive: ['md'],
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      // sorter: true,
      //   render: subj => `${name.first} ${name.last}`,
      // width: '20%',
      // responsive: ['md'],
      ...getColumnSearchProps('designation'),
    },

    // {
    //   title: 'Username',
    //   dataIndex: 'username',
    //   // sorter: true,
    //   // responsive: ['md'],
    //   ...getColumnSearchProps('username'),
    //   // render: text => <a>{text}</a>,
    // },
    {
      title: 'Email',
      dataIndex: 'email',
      // sorter: true,
      // responsive: ['md'],
      render: text => <a>{text}</a>,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      // sorter: true,
      // responsive: ['md'],
      render: text => <a>{text}</a>,
      ...getColumnSearchProps('mobile'),
    },
    {
      title: 'status',
      dataIndex: 'status',
      // sorter: true,
      // responsive: ['lg'],
      render: text => <Tag color={text === 1 ? '#14b8a6' : '#b3b3b3'}>{text === 1 ? 'Active' : 'Disabled'}</Tag>,
      // ...getColumnSearchProps('status'),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'x',
      render: (id) =>
        <>
          {/* <Button outline={true} color="lightBlue" children="Forwad" name="Status" id={mlogid} size="sm" onClick={deliveryStatus} /> */}
          <Button color="light" children="Edit" name="Status" id={id} size="sm" onClick={editUser} />
          {/* <Button color="red" children="Delete" name="Status" id={reg_inst_id} size="sm" onClick={editAddress} /> */}
        </>,
      // responsive: ['lg'],
      fixed: 'right',
    },
  ];

  const getRandomuserParams = params => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    status: [2],
    ...params,
  });
  useEffect(() => {
    const { pagination } = state;
    fetch({ pagination });
  }, []);
  
  const [listData, setListData] = useState([])
  useEffect(() => {
    setListData({})
   
    if(tpmsUserList &&  tpmsUserList.length>0){
      tpmsUserList[0].map((prop, key) => {
        // alert(prop.name)
        // setListData([...listData,{
        //   title: prop.name,
        //   avatar: `${prop.url}/${prop.profile_pic}`,
        //   description:
        //   `${prop.desig_name}`,
        //   content:
        //   `Email : ${prop.email_pri} Mobile : ${prop.mob_pri}`,
        // }]);
        setListData(
          {
            // image: "/static/media/team-1-800x800.fa5a7ac2.jpg",
            image: `${prop.url}/${prop.profile_pic}`,
    name: `${prop.prefix} ${prop.name}`,
    location: prop.locName,
    // description:
    //   "An artist of considerable range, Jenna the name taken by Melbourne-raised, Brooklyn-based Nick Murphy writes, performs and records all of his own music, giving it a warm, intimate feel with a solid groove structure. An artist of considerable range.",
    // showMore: { children: "Show more", href: "#pablo" },
    buttons: [
      { children: "Import", color: "lightBlue", size: "sm", onClick:handleUserImport },
      { children: "Cancel", color: "dark", size: "sm", onClick:handleDrawerClose },
    ],
    achievements: [
      {
        icon: "fas fa-briefcase",
        text: prop.desig_name,
      },
      { icon: "fas fa-envelope-open-text", text: prop.email_pri },
      { icon: "fas fa-mobile-alt", text: prop.mob_pri },
    ],
          }
        )


      })
    }
      
  }, [tpmsUserList]);

  
 
  const handleTableChange = (pagination, filters, sorter) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    setState(state => ({ ...state, ['loading']: true }));

    axios.get(`${apiUrl}/api/users/enduser`, {
      results: params.pagination.pageSize, page: params.pagination.current,
      ...params
    }, {
      headers: authHeader()
    }).then(response => {
      setState({
        loading: false,
        data: response.data.result,
        pagination: {
          ...params.pagination,
          total: response.data.totalRows,

        },


      });

    }).catch((error) => {
      errorHandler(error);
    });
  };

  
  const [currentStep, setcurrentState] = useState(0)

  const onChangeStep = currentStep => {
    console.log('onChange:', currentStep);
    setcurrentState(currentStep);
  };
  const steps = [
    {
      title: 'First',
      content: 'First-content',
    },
    {
      title: 'Second',
      content: 'Second-content',
    },
    {
      title: 'Last',
      content: 'Last-content',
    },
  ];
  const inputprops = {
    placeholder: "Search  ",
    leftIcon: "fas fa-search",
    size: "sm",
    border: "borderless",
}
  const [searchVal, setSearchVal] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const searchItems = (searchValue, field) => {
        setSearchVal(searchValue)
        if (searchVal !== '') {
            const filteredData = data.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchVal.toLowerCase())
            })
            setFilteredResults(filteredData)

        }
        else {
            setFilteredResults(data)

        }

    }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12 px-4">
          <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
            <div className="mb-0 p-6 pb-0">
              <div className="text-center flex justify-between items-center">
                <h6 className="text-xl font-bold mb-0">{cardUser.title}</h6>

                <div className="text-center flex  items-center">
                  <Button {...cardUser.button} onClick={addUserForm} />
                  <Popconfirm
                    title="Are you Sure"
                    visible={show}
                    onConfirm={handleOk}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                  >
                    <Button children="Sync TPMS" size="sm" color="teal" key="all" onClick={showPopconfirm} />
                  </Popconfirm>
                </div>

              </div>
            </div>
            <div className="w-full item-center xl:w-12/12 px-2">
                  <Divider><Input name="to" onChange={(e) => searchItems(e.target.value, e.target.name)} {...inputprops} /></Divider>
                </div>
            <div className="px-4 py-2">
              <Table
                columns={columns}
                rowKey={record => record.mlogid}
                dataSource={(searchVal.length > 1 ? filteredResults : data)}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                expandRowByClick={true}
              />
            </div>
            <Drawer
              id="drawer-3"
              // header={"Add New User" }
              size="large"
              isOpen={visible}
              slideFrom="right"
              onRequestClose={handleDrawerClose}

            >
              <div className="pt-32 px-2">
               <Steps
          type="navigation"
          size="small"
          current={currentStep}
          onChange={onChangeStep}
          className="site-navigation-steps"
        >
          {createMode && 
          <Step
          title="Import "
          subTitle="TPMS User"
          status={currentStep===0 ? "process" : "finish"}
          description="Not Mandatory"
        />}
          
          <Step
            title="Add New / "
            subTitle="Edit Imported"
            status={currentStep===1 ? "process" : currentStep===0 ? "wait" : "finish"}
            description="This is a description."
          />
          <Step
            title="Configure"
            subTitle="Permission and Global settings"
            status={currentStep===2 ? "process" : currentStep===1 || currentStep===0 ? "wait" : "finish"}
            description="Complete all field"
          />
        </Steps>
        </div>
        <div className="steps-content">
          {/* {steps[current].content} */}
          {currentStep===0 && createMode && 
              <div className="flex flex-wrap">
                <div className="w-full xl:w-12/12 px-4">
                  <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
                    <div className="mb-0 p-6 pb-0">

                      <div className="text-center flex justify-between items-center">
                        <h6 className="text-xl font-bold mb-0">{cardFormUserImport.title}</h6>

                        <div className="text-center flex  items-center">
                          {/* <Button {...cardFormUser.button} onClick={() => setAddressAdd(prevState => (!prevState))} /> */}
                          {/* {cardEmailForm.modalButton.map((prop, key) => (
                  <>
                    <Button key={key} {...prop} />
                  </>
                ))} */}
                        </div>

                      </div>
                    </div>
                    {/* <Spin spinning={Loader} tip="Please Wait..."> */}
                    {addressAdd && <div className="flex-auto px-6 pb-6 pt-0">
                      <form onSubmit={handleSearchSubmit} encType="multipart/form-data" >
                        {cardFormUserImport.forms.map((prop, key) => (

                          <div key={key}>

                            <h6 className="mt-6 mb-2 font-bold">{prop.title}</h6>
                            <hr className="mb-6 border-b-1 border-blueGray-200" />
                            <div className="flex flex-wrap -mx-4">
                              {prop.inputs &&
                                prop.inputs.map((inputProp, inputKey) => (
                                  <div
                                    key={inputKey}
                                    className={"w-full px-4 " + widths[inputProp.width]}
                                  >
                                    <div className="relative w-full mb-3">
                                      {inputProp.label && (
                                        <label
                                          className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                                          htmlFor="grid-password"
                                        >
                                          {inputProp.label}
                                        </label>
                                      )}
                                      {inputProp.input && <Input {...inputProp.input} />}
                                      {inputProp.button && <Button {...inputProp.button} />}


                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}

                      </form>

                    </div>}
                    {/* </Spin> */}
                    {tpmsUserList && <List
    itemLayout="vertical"
    size="large"
    dataSource={tpmsUserList[0]}
    
    renderItem={item => (
      <List.Item
        key={item.title}
        actions={[
         <Button size="sm"  id={item.user_id} onClick={handleUserImportList} color="lightBlue" children="import" />,
         
        ]}
        // extra={
        //   <img
        //     width={272}
        //     alt="logo"
        //     src={`${item.url}/${item.profile_pic}`}
        //   />
        // }
      >
        <List.Item.Meta
          avatar={<Avatar src={`${item.url}/${item.profile_pic}`} />}
          title={<a >{item.name}</a>}
          description={item.desig_name}
        />
        {`Location : ${item.locName}, Email : ${item.email_pri}, Mobile : ${item.mob_pri}`}
      </List.Item>
    )}
  />}
    {/* {tpmsUserList.length>0 && listData &&  <div className="container py-12 mx-auto px-8 "> <CardProfileImportPage {...listData} /></div>} */}
  {/* <div className="container mx-auto px-4 -mt-64"> */}
           
          {/* </div> */}

                  </div>
                </div>
              </div>}
              {(createMode ? currentStep===1 : currentStep===0) &&<div className="flex flex-wrap">
                <div className="w-full xl:w-12/12 px-4">
                  <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
                    <div className="mb-0 p-6 pb-0">
                      <div className="text-center flex justify-between items-center">
                        <h6 className="text-xl font-bold mb-0">{cardFormUser.title}</h6>

                        <div className="text-center flex  items-center">
                          {/* <Button {...cardFormUser.button} onClick={() => setAddressAdd(prevState => (!prevState))} /> */}
                          {/* {cardEmailForm.modalButton.map((prop, key) => (
                  <>
                    <Button key={key} {...prop} />
                  </>
                ))} */}
                        </div>

                      </div>
                    </div>
                    {/* <Spin spinning={Loader} tip="Please Wait..."> */}
                    {addressAdd && <div className="flex-auto px-6 pb-6 pt-0">
                      <form  encType="multipart/form-data" >
                        {cardFormUser.forms.map((prop, key) => (

                          <div key={key}>
                            <h6 className="mt-6 mb-2 font-bold">{prop.title}</h6>
                            <hr className="mb-6 border-b-1 border-blueGray-200" />
                            <div className="flex flex-wrap -mx-4">
                              {prop.inputs &&
                                prop.inputs.map((inputProp, inputKey) => (
                                  <div
                                    key={inputKey}
                                    className={"w-full px-4 " + widths[inputProp.width]}
                                  >
                                    <div className="relative w-full mb-3">
                                      {inputProp.label && (
                                        <label
                                          className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                                          htmlFor="grid-password"
                                        >
                                          {inputProp.label}
                                        </label>
                                      )}
                                      {inputProp.input && <Input {...inputProp.input} />}
                                      {inputProp.select && <Select {...inputProp.select} />}
                                      

                                      {inputProp.switch && <Switch  {...inputProp.switch} />}
                                      {inputProp.multiselect && <MultiSelect {...inputProp.multiselect} />}
                                      {inputProp.section && <div><section dangerouslySetInnerHTML={{ __html: inputProp.section.content }} /></div>}
                                      {inputProp.slider && <Slider {...inputProp.slider} />}
                                      {inputProp.numberSlider && <Slider {...inputProp.numberSlider} tooltipPlacement="bottom" tooltipVisible />}
                                      {inputProp.button && <Button {...inputProp.button} />}
                                      {inputProp.singleSelect &&
                                <Select
                                  showSearch
                                  style={{ width: '100%' }}
                                  showArrow={false}
                                  size='middle'
                                  // mode={inputProp.singleSelect.mode ? inputProp.singleSelect.mode : ''}
                                  placeholder={inputProp.singleSelect.placeholder}
                                  optionFilterProp="children"
                                  onChange={inputProp.singleSelect.onChange}
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                  }
                                >
                                 
                                  
                                  {/* {inputProp.singleSelect.option && 
                                    inputProp.singleSelect.option.map((prop, key) => (
                                      
                                      <Option
                                     
                                        key={key} 
                                        value={prop.value}>{prop.label}
                                      </Option>
                                    ))
                                  } */}
                                  

                                </Select>
                              }


                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}

                      </form>

                    </div>}
                    {/* </Spin> */}


                  </div>
                </div>
              </div>}
              {(createMode ? currentStep===2 : currentStep===1) &&<div className="flex flex-wrap">
                <div className="w-full xl:w-12/12 px-4">
                  <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
                    <div className="mb-0 p-6 pb-0">
                      <div className="text-center flex justify-between items-center">
                        <h6 className="text-xl font-bold mb-0">{cardFormConfiguration.title}</h6>

                        <div className="text-center flex  items-center">
                          {/* <Button {...cardFormUser.button} onClick={() => setAddressAdd(prevState => (!prevState))} /> */}
                          {/* {cardEmailForm.modalButton.map((prop, key) => (
                  <>
                    <Button key={key} {...prop} />
                  </>
                ))} */}
                        </div>

                      </div>
                    </div>
                    {/* <Spin spinning={Loader} tip="Please Wait..."> */}
                    {addressAdd && <div className="flex-auto px-6 pb-6 pt-0">
                      <form onSubmit={handleUserSubmit} encType="multipart/form-data" >
                        {cardFormConfiguration.forms.map((prop, key) => (

                          <div key={key}>
                            <h6 className="mt-6 mb-2 font-bold">{prop.title}</h6>
                            <hr className="mb-6 border-b-1 border-blueGray-200" />
                            <div className="flex flex-wrap -mx-4">
                              {prop.inputs &&
                                prop.inputs.map((inputProp, inputKey) => (
                                
                                  <div
                                    key={inputKey}
                                    className={"w-full px-4 " + widths[inputProp.width]} style={inputProp.display && inputProp.name!='config' ? { display: 'block' } : { display: 'none' } } 
                                   
                                  >
                                    <div className="relative w-full mb-3">
                                      {inputProp.label && (
                                        <label
                                          className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                                          htmlFor="grid-password"
                                        >
                                          {inputProp.label}
                                        </label>
                                      )}
                                      {inputProp.input && <Input {...inputProp.input} />}
                                      {inputProp.select && <Select {...inputProp.select} />}
                                      {/* {inputProp.CKediter && <CKEditer {...inputProp.CKediter} />} */}

                                      {inputProp.switch && <Switch  {...inputProp.switch} />}
                                      {inputProp.multiselect && <MultiSelect {...inputProp.multiselect} />}
                                      {inputProp.section && <div><section dangerouslySetInnerHTML={{ __html: inputProp.section.content }} /></div>}
                                      {inputProp.slider && <Slider {...inputProp.slider} />}
                                      {inputProp.numberSlider && <Slider {...inputProp.numberSlider} tooltipPlacement="bottom" tooltipVisible />}
                                      {inputProp.button && <Button {...inputProp.button} />}
                                      {/* {inputProp.image && <ImgCrop rotate>
                                        <Upload
                                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                          listType="picture-card"
                                          fileList={fileList}
                                          onChange={onChange}
                                          onPreview={onPreview}
                                        >
                                          {fileList.length < 5 && '+ Upload'}
                                        </Upload>
                                      </ImgCrop>} */}


                                    </div>
                                  </div>
                                 
                                ))}
                            </div>
                          </div>
                        ))}

                      </form>

                    </div>}
                    {/* </Spin> */}


                  </div>
                </div>
              </div>}
              </div>
              
            </Drawer>

          </div>
        </div>


      </div>
    </>
  );
}

const inputsObject = {
  // NOTE: this width only applies on large devices
  width: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  // if you wish, you can send somthing like
  // <span className="mr-2">Label Name</span><span className="text-red-500">*</span>
  // NOTE: the label tag will be auto generated
  label: PropTypes.node,
};

const inputsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Input component
  input: PropTypes.object,
});

const selectsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Select component
  select: PropTypes.object,
});

const inputsTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([inputsShape, selectsShape])
);

UsersCardTable.defaultProps = {
  button: {},
  forms: [],
};

UsersCardTable.propTypes = {
  title: PropTypes.string,
  // props to pass to the Button component
  button: PropTypes.object,

  forms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      inputs: inputsTypes,
    })
  ),
};
