import React, { useState } from "react";
import { useLocation } from 'react-router-dom';

// components

import FooterAuth from "components/Footers/Auth/FooterAuth.js";
// texts
import footerauth from "_texts/auth/footers/footerauth.js";
import lottie from "lottie-web";
import forgetPswd from "animations/94132-forgot-password";
import CardResetPassword from "components/Cards/Auth/CardResetPassword";

export default function ForgetPswd() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#image-loader"),
      animationData: forgetPswd
    });
  }, []);


  const [email, setEmail] = useState('');
  function handleChangeemail(e) {
    setEmail(e.target.value)
  };
  const inputData = {
    inputVal: {
      email: email,
    }
  };
  const cardresetpassword = {
    image: "/notus-pro-react/static/media/linth3.41699b02.svg",
    title: "Reset Password",
    subtitle: "Enter email address below",
    input: {
      leftIcon: "fas fa-envelope",
      type: "email",
      placeholder: "Email address",
      value:email,
      onChange:handleChangeemail
    },
    button: { color: "dark", children: "Reset" },
  }
  return (
    <>
      {/* <main>
        <section className="relative w-full h-full py-40 min-h-screen">

          <div
            className="absolute top-0 w-full h-full bg-light-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/loginbg.png").default + ")",
            }}
            
          >
          </div>
          

          <div className="container mx-auto px-4 h-full">
            <div className="flex  h-full">
              <div className="w-full lg:w-4/12 px-4">
                <CardLoginSocial {...textProps} {...inputData} />
              </div>

            </div>
          
          </div>
          <FooterAuth {...footerauth} />
        </section>
      </main> */}
      <div className="p-0 flex items-center overflow-hidden relative bg-blueGray-100 min-h-screen max-h-999-px">
        <div
          className="left-0 w-80 h-full bg-cover bg-50"
          id="image-loader"
          // style={{
          //   backgroundImage:
          //     "url('" +
          //     require("assets/img/illustrations/reset.svg").default +
          //     "')",
          // }}
        ></div>
        <div className="container  mx-auto px-2">
          <div className="justify-center  flex flex-wrap -mx-2">
            <div className="px-4 relative w-full lg:w-5/12 w-full md:w-8/12">
              {/* <div id="image-loader"></div> */}
              <CardResetPassword {...cardresetpassword} {...inputData} />
            </div>
          </div>
        </div>
        <FooterAuth {...footerauth} />
      </div>
    </>
  );
}
