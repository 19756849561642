import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { history } from '../_helpers';
import { alertActions } from '../_actions';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.min.css";
import "assets/styles/custom.css";
import "assets/styles/docs.css";
import 'react-toastify/dist/ReactToastify.css';
import Login from "views/auth/Login.js";
import LoginA from "views/auth/LoginA.js";
import { PrivateRoute } from '../_components';
import Dashboard from "views/admin/Dashboard.js";
import Email from "views/communication/email/Email.js";
import Settings from "views/admin/Settings.js";
import RolePermissions from "views/admin/RolePermissions.js";
import Users from "views/users/Users";
import Profile from "views/users/Profile";
import LoginActivity from "views/users/LoginActivity";
import LoginOtp from 'views/auth/LoginOtp';
import Error404 from 'views/error/Error404';
import ForgetPswd from "views/auth/ForgetPswd.js";
import ResetPswd from 'views/auth/ResetPswd';




function App() {
    // const alert = useSelector(state => state.alert);

    const dispatch = useDispatch();
    useEffect(() => {

        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
            console.log('Location changed');
            localStorage.removeItem('forwardMail');
        });
    }, []);




    return (
        <>
            <Router history={history}>
                <ToastContainer position="bottom-center"
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    autoClose={3000} />
                <Switch>
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    {/* communication */}
                    <PrivateRoute exact path="/email" component={Email} />
                    {/* User Management */}
                    <PrivateRoute exact path="/localbodies" component={Users} />
                    <PrivateRoute exact path="/stakeholder" component={Users} />
                    <PrivateRoute exact path="/users" component={Users} />
                    <PrivateRoute exact path="/others" component={Users} />
                    <PrivateRoute exact path="/settings" component={Settings} />
                    <PrivateRoute exact path="/role" component={RolePermissions} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/login-activity" component={LoginActivity} />
                    <Route exact path="/" component={Login} />
                    <Route path="/login" component={Login} />
                    <Route path="/forget-pswd" component={ForgetPswd} />
                    <Route path="/reset/:token" component={ResetPswd} />
                    <Route path="/otp" component={LoginOtp} />
                    <Route path="/login1" component={LoginA} />
                    <Route component={Error404} />
                    
                </Switch>
                {/* <Redirect from="/" to="/dashboard" /> */}
            </Router>
        </>
    );
}

export { App };