import React from "react";

import lottie from "lottie-web";
import deliveryService from "animations/72642-errorcat";

export default function ErrorCat() {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#error-cat"),
          animationData: deliveryService
        });
      }, []);
  return (
    <>
      <div className="h-32 " id="error-cat" ></div>
    </>
  );
}
