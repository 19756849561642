/* eslint-disable react/jsx-no-target-blank */
import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Popover, Divider, List, Avatar, Skeleton, } from 'antd';
import {  Modal, Drawer } from 'react-rainbow-components';
import { FileIcon, defaultStyles } from 'react-file-icon';
import FileViewer from 'react-file-viewer';

import Button from "components/Elements/Button.js";
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from "axios";
import Input from "components/Elements/Input.js";
import moment from 'moment'
import { authHeader } from '../../../_helpers';
import { errorHandler } from "error/errorHandler";


import { onConfirm } from 'react-confirm-pro';



export default function InfoAreaIconMail({
  icon,
  iconColor,
  title,
  description,
  to,
  cc,
  bcc,
  attachment,
  date,
  mlogid,
  from,
  link,
}) {
  let newDescription = [];
  if (!Array.isArray(description)) {
    newDescription = [description];
  } else {
    newDescription = description;
  }
  const iconBgColor = {
    blueGray: "text-blueGray-500 bg-white",
    red: "text-red-500 bg-red-100",
    orange: "text-orange-500 bg-orange-100",
    amber: "text-amber-500 bg-amber-100",
    emerald: "text-emerald-500 bg-emerald-100",
    teal: "text-teal-500 bg-teal-100",
    lightBlue: "text-lightBlue-500 bg-lightBlue-100",
    indigo: "text-indigo-500 bg-indigo-100",
    purple: "text-purple-500 bg-purple-100",
    pink: "text-pink-500 bg-pink-100",
  };
 
  const inputprops = {
    placeholder: "Search Mail id ",
    leftIcon: "fas fa-search",
    size: "sm",
    border: "borderless",
  }
  
  const [isOpen, setstate] = useState(false)
  const [isOpenRecipients, setRecipientState] = useState(false)
  const [inputs, setInputs] = useState({
    url: '',
    type: '',
    filename: '',
    file: '',

  });
  // const apiUrl = process.env.REACT_APP_API_URL;
  
  const { url, type, filename,file } = inputs;
  function handleOnClick(e) {
    setstate(true);
    const url = e.currentTarget.getAttribute('url')
    const filename = e.currentTarget.getAttribute('filename')
    const file = e.currentTarget.getAttribute('file')
    const type = e.currentTarget.getAttribute('type')
    // alert(url)
    setInputs(inputs => ({ ...inputs, ['url']: url }));
    setInputs(inputs => ({ ...inputs, ['filename']: filename }));
    setInputs(inputs => ({ ...inputs, ['file']: file }));
    setInputs(inputs => ({ ...inputs, ['type']: type }));
  }

  function handleOnClose() {
    setstate(false);
    setRecipientState(false)
  }
  function recipientModel() {
    setSearchInput('');
    setFilteredToResults([]);
    setFilteredCCResults([]);
    setFilteredBCCResults([]);

    setRecipientState(true)
  }
  const mystyle = {
    color: "white",
    backgroundColor: "#597795",
    padding: "10px",
    fontFamily: "Arial",
    textAlign:"center"
  };
  const CustomUI = ({
    onSubmit,
    onCancel
  }) => (
    <div style={mystyle} className="custom-ui">
      <h3>Are you sure?</h3>
      <p>Attachementes are removed from the server, Do you want to forward without attachment?</p>
      <div>
        <button  style={{padding:"10px"}} onClick={onSubmit}>Yes</button>
        <button  style={{padding:"10px"}} onClick={onCancel}>No</button>
      </div>
    </div>
  )

  function forwardMail(e) {
    let count =0;
    axios.get(`${apiUrl}/api/mail/mail_trasactions/${e.target.value}`, {
    }, {
      headers: authHeader()
    }).then(response => {
      response.data.result.attachment.map((prop, key) => {
        if(prop.fileStatus){
          
        }
        else{
          count++;

        }

      })
      if(count>0){
        onConfirm({
          onSubmit: () => {
            localStorage.removeItem('forwardMail');
          localStorage.setItem('forwardMail', e.target.value);
          window.location.reload(false);
          },
          onCancel: () => {
            // alert("Cancel")
          },
          customUI: CustomUI,
          className: "my-custom-ui-container"
        })
      }
      else{
        localStorage.removeItem('forwardMail');
          localStorage.setItem('forwardMail', e.target.value);
          window.location.reload(false);
      }
      
          
       





        
    })
    .catch((error) => {
      errorHandler(error);
    });
    
    // history.push('/email')
  }

  const [loadingList, setLoading] = useState(false);
  const [addressdataTo, setAddressDataTo] = useState([]);
  const [addressdataCC, setAddressDataCC] = useState([]);
  const [addressdataBCC, setAddressDataBCC] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const loadMoreData = () => {
    if (loadingList) {
      return;
    }
    // setLoading(true);
    // fetch('https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo')
    //   .then(res => res.json())
    //   .then(body => {
    //     console.log("jasaaa"+body.results)
    //     setAddressData([...addressdata, ...body.results]);
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });

    setLoading(true);
    axios.get(`${apiUrl}/api/mail/mail_trasactions/${mlogid}`, {}, {
      // axios.get(`https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo`, {}, {
      // headers: authHeader()
    }).then(response => {
      // console.log("jasaaa" + response.data.result.to.length)
      setAddressDataTo(response.data.result.to);
      setAddressDataCC(response.data.result.cc);
      setAddressDataBCC(response.data.result.bcc);
      // console.log("jasaaa" + addressdataTo)
      setLoading(false);

    })
  };

  useEffect(() => {
    loadMoreData();
  }, [isOpenRecipients]);
  const [searchInput, setSearchInput] = useState('');
  const [filteredToResults, setFilteredToResults] = useState([]);
  const [filteredCCResults, setFilteredCCResults] = useState([]);
  const [filteredBCCResults, setFilteredBCCResults] = useState([]);
  const searchItems = (searchValue, field) => {
    setSearchInput(searchValue)
    if (searchInput !== '') {
      // if (field === 'to') {
      // if (searchInput !== '') {
      const filteredData = addressdataTo.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredToResults(filteredData)
      // } else {
      //   setFilteredToResults(addressdataTo)
      // }
      // }


      const filteredData1 = addressdataCC.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredCCResults(filteredData1)
      // } else {
      //   setFilteredCCResults(addressdataCC)
      // }
      // }


      const filteredData2 = addressdataBCC.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredBCCResults(filteredData2)
      // } else {
      //   setFilteredBCCResults(addressdataBCC)
      // }
      // }
    }
    else {
      setFilteredToResults(addressdataTo)
      setFilteredCCResults(addressdataCC)
      setFilteredBCCResults(addressdataBCC)
    }

  }


  // const file = 'https://t3.ftcdn.net/jpg/02/59/61/32/400_F_259613221_MDjC6TecH1vIclmxrNNsU2fI9haDIdhV.jpg'
  // const type = 'png'
  return (
    <>

      <div className="flex justify-between ">
        <div className="flex justify-start py-2">
          <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} size="large" >
            {'KILA'}
          </Avatar>
          <span className='text-base py-2 px-2'>{from}</span>

        </div>

        <div className="flex justify-end">
          <span className='text-xs py-2'>{moment(date).format("Do MMMM YYYY, h:mm a")}</span>  <span className="px-3 py-1"><i className="fas fa-print"></i></span>
          {/* <span className="px-4"><DropdownButton  {...dropdown} /></span>   */}

        </div>
      </div>


      <div className="w-full">
        {newDescription.map((prop, key) => {
          return (
            //  <div className=" w-full mb-6 shadow-lg rounded-lg bg-white">
            <div>
              <div className="flex justify-center">
                {/* <Button outline={true} onClick={recipientModel} value={mlogid} size="sm" color="lightBlue" children="Show Recipients" /> */}
              </div>

             
              <Drawer
                id="drawer-3"
                //date time format update
                // header={"Mali Delivery Status  - " + currentDateTime}
                size="large"
                isOpen={isOpenRecipients}
                onRequestClose={handleOnClose}

              >
               
                <div className="w-full item-center xl:w-12/12 px-2">
                  <Divider><Input name="to" onChange={(e) => searchItems(e.target.value, e.target.name)} {...inputprops} /></Divider>
                </div>
                <div className="flex flex-wrap">

                  <div className="w-full xl:w-4/12 px-4">

                    <span className="text-sm font-bold text-blueGray-500">To Address ({addressdataTo.length})</span>
                    {/* <Input name="to" onChange={(e) => searchItems(e.target.value, e.target.name)} {...inputprops} /> */}
                    <div
                      id="scrollableDiv"
                      style={{
                        height: 600,
                        overflow: 'auto',
                        padding: '0 16px',
                        border: '1px solid rgba(140, 140, 140, 0.35)',
                      }}
                    >
                      <InfiniteScroll
                        dataLength={addressdataTo.length}
                        next={loadMoreData}
                        hasMore={addressdataTo.length < 0}
                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                        scrollableTarget="scrollableDiv"
                      >
                        <List
                          dataSource={(searchInput.length > 1 ? filteredToResults : addressdataTo)}
                          renderItem={item => (
                            <List.Item key={item.email}>
                              <List.Item.Meta
                                // avatar={<Avatar src={item.picture.large} />}
                                title={<a >{item.email}</a>}
                              description={item.name}
                              />
                              {/* <div>Content</div> */}
                            </List.Item>
                          )}
                        />
                      </InfiniteScroll>
                    </div>
                  </div>

                  <div className="w-full xl:w-4/12 px-4">

                    <span className="text-sm font-bold text-blueGray-500">CC Address ({addressdataCC.length})</span>
                    {/* <Input name="cc" onChange={(e) => searchItems(e.target.value, e.target.name)} {...inputprops} /> */}
                    <div
                      id="scrollableDiv"
                      style={{
                        height: 600,
                        overflow: 'auto',
                        padding: '0 16px',
                        border: '1px solid rgba(140, 140, 140, 0.35)',
                      }}
                    >
                      <InfiniteScroll
                        dataLength={addressdataCC.length}
                        next={loadMoreData}
                        hasMore={addressdataCC.length < 0}
                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                        scrollableTarget="scrollableDiv"
                      >
                        <List
                          dataSource={(searchInput.length > 1 ? filteredCCResults : addressdataCC)}
                          renderItem={item => (
                            <List.Item key={item.email}>
                              <List.Item.Meta
                                // avatar={<Avatar src={item.picture.large} />}
                                title={<a >{item.email}</a>}
                              description={item.name}
                              />
                              {/* <div>Content</div> */}
                            </List.Item>
                          )}
                        />
                      </InfiniteScroll>
                    </div>
                  </div>

                  <div className="w-full xl:w-4/12 px-4">

                    <span className="text-sm font-bold text-blueGray-500">BCC Address ({addressdataBCC.length})</span>
                    {/* <Input name="bcc" onChange={(e) => searchItems(e.target.value, e.target.name)} {...inputprops} /> */}
                    <div
                      id="scrollableDiv"
                      style={{
                        height: 600,
                        overflow: 'auto',
                        padding: '0 16px',
                        border: '1px solid rgba(140, 140, 140, 0.35)',
                      }}
                    >
                      <InfiniteScroll
                        dataLength={addressdataBCC.length}
                        next={loadMoreData}
                        hasMore={addressdataBCC.length < 0}
                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                        scrollableTarget="scrollableDiv"
                      >
                        <List
                          dataSource={(searchInput.length > 1 ? filteredBCCResults : addressdataBCC)}
                          renderItem={item => (
                            <List.Item key={item.email}>
                              <List.Item.Meta
                                // avatar={<Avatar src={item.picture.large} />}
                                title={<a >{item.email}</a>}
                                description={item.name}
                              />
                              {/* <div>Content</div> */}
                            </List.Item>
                          )}
                        />
                      </InfiniteScroll>
                    </div>
                  </div>


                </div>
              </Drawer>
              
              <section
                key={key}
                className="text-sm font-light leading-relaxed px-4 mt-4 mb-4 text-blueGray-700"
                dangerouslySetInnerHTML={{ __html: prop }}
              />
            </div>


          );
        })}
        <div className="w-full xl:w-9/12 px-4">
          {attachment.length > 0 &&
            <Divider orientation="left">Attachments</Divider>
            // <Divider />
          }

          <div className="App">
            <div className="icons">
              {attachment.map((item, key) => (

                <div className="icon">
                  {item.fileStatus ?
                  <Popover title={item.name} content={item.size} >
                    {/* <div key={key} url={item.url} filename={item.name} type={item.type} onClick={handleOnClick}> */}
                    <div key={key} url={item.url} file={item.file} filename={item.file} type={item.type} onClick={handleOnClick} >

                      <FileIcon key={key} extension={item.type} {...defaultStyles[item.type]} />

                    </div>
                    {/* <div key={key} url={item.url} filename={item.name} type={item.type} >
                                <a href={'http://localhost:4000/files/'+item.name}>
                                <FileIcon key={key} extension={item.type} {...defaultStyles[item.type]} />
                                </a>
                              </div>  */}
                  </Popover>
                  :  <Popover title={item.name} content="File Removed !" >
            
                  <div key={key}  filename={item.file} type={item.type}  >

                    <FileIcon key={key} extension={item.type} {...defaultStyles[item.type]} />

                  </div>
                 
                </Popover>
                  }
                </div>

              ))}
            </div>
            <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

              <Modal
                isOpen={isOpen}
                onRequestClose={handleOnClose}
                title={filename}
                size="large"
                footer={
                  <div className="rainbow-flex rainbow-justify_end px-2">
                    <Button href={`${apiUrl}/files/` + file} color="light" children="Download" ><i className="fas fa-download px-2"></i>Download</Button>
                    {/* <Button onClick={()=> window.open('http://localhost:4000/files/'+filename, "_blank")} color="light" children="Print" ><i className="fas fa-print px-2"></i>Print</Button> */}


                  </div>
                }
              >

                <FileViewer
                            fileType={type}
                            filePath={`${apiUrl}/files/`+filename}
                            // errorComponent={CustomErrorComponent}
                            // onError={this.onError}
                            errorComponent={console.log(" There is an error ")}
                            unsupportedComponent={console.log(" I won't support it ")}
                          />


              </Modal>
            </div>
          </div>

        </div>
        <Divider orientation="left"></Divider>
        <div class="py-8 px-8 ...">
        <Button  onClick={recipientModel} value={mlogid} size="sm" color="indigo" children="Show Recipients" />
          <Button  onClick={forwardMail} value={mlogid} size="sm" color="light" children="forward" icon="fas fa-forward" />
          {/* <Button outline={true} size="sm" color="indigo" children="Make Label"/> */}
        </div>


      </div>



    </>
  );
}
InfoAreaIconMail.defaultProps = {
  iconColor: "blueGray",
  description: [],
  link: {},
};
InfoAreaIconMail.propTypes = {
  // icon name to be added on the <i></i> tag, for example "fa fa-heart"
  icon: PropTypes.string.isRequired,
  // the background of the icon
  iconColor: PropTypes.oneOf([
    "blueGray",
    "red",
    "orange",
    "amber",
    "emerald",
    "teal",
    "lightBlue",
    "indigo",
    "purple",
    "pink",
  ]),
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  to: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  cc: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  bcc: PropTypes.oneOfType([
    PropTypes.array,
    // PropTypes.arrayOf(PropTypes.string),
  ]),
  attachment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    // where the user should be redirected on click
    link: PropTypes.string.isRequired,
    // if innerLink is set to false
    // then the link will be generated as simple <a> a tag with target="_blank"
    // if not, it will be generated as a <Link> tag from react-router-dom
    innerLink: PropTypes.bool.isRequired,
  }),
};
