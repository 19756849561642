import React, { useEffect, useState,useRef } from "react";
import { Table, Spin, Space,Divider,Avatar } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { authHeader } from '../../../_helpers';
import axios from "axios";
import { errorHandler } from "error/errorHandler";
import Button from "../../Elements/Button.js";
import { Drawer } from 'react-rainbow-components';
import Input from "../../Elements/Input.js";





// const expandable = { expandedRowRender: (record => <><section
//     className="not-found-controller"
//     dangerouslySetInnerHTML={{ __html: record.body_content }}
// />
// <p>Attachment : {record.attachment}</p><hr></hr><p> To Address : {record.to}</p><p>CC : {record.cc}</p><p>BCC :{record.bcc}</p></>),
//       expandIcon: ({ expanded, onExpand, record }) =>
//         expanded ? (
//           <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
//         ) : (
//           <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
//         )
//     };



const TpmsUsersCardTable = () => {
    const apiUrl=process.env.REACT_APP_API_URL;

    const [state, setState] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 5,
        },
        loading: false,
        visible: false,
        eventData: [],
        eventDataSummary:[],
        searchText: '',
        searchedColumn: '',

    });
    const { data, pagination, loading, visible, eventData,eventDataSummary,searchText,searchedColumn } = state;
    function handleChange(name, value) {
        setState(state => ({ ...state, [name]: value }));
    }
    function deliveryStatus(e) {
        e.preventDefault();
        const { name, id } = e.target;
        setState(state => ({ ...state, ['loading']: true }));
        setState(state => ({ ...state, ['visible']: true }));
        axios.post(`${apiUrl}/api/mail/events`, {
            mlogid: id
        }, {
            headers: authHeader()
        }).then(response => {
            if (response.data.totalRows === 0) {
                toast.warn("No records found", {
                    // position: toast.POSITION.TOP_CENTER
                });
                setState(state => ({ ...state, ['eventData']: [] }));
            }
            setState(state => ({ ...state, ['eventData']: response.data.results }));
            setState(state => ({ ...state, ['eventDataSummary']: response.data.summary }));
            setState(state => ({ ...state, ['loading']: false }));

            console.log(eventData)
        })
            .catch((error) => {
                errorHandler(error);
            });


    }
    function handleDrawerClose() {

        setState(state => ({ ...state, ['loading']: false }));
        setState(state => ({ ...state, ['visible']: false }));
        setState(state => ({ ...state, ['eventData']: [] }));
        setState(state => ({ ...state, ['eventDataSummary']: [] }));


    }
    // const expandable = {
    //     expandedRowRender: (record =>


    //         <div className='bg-white'><InfoAreaIconMail  date={record.date} title={record.title} description={record.body_content} attachment={record.attachment} /></div>),
    //     expandIcon: ({ expanded, onExpand, record }) =>
    //         expanded ? (
    //             // <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
    //             <FolderOpenTwoTone onClick={e => onExpand(record, e)} />
                
    //         ) : (
    //             // <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
    //             // <FolderTwoTone onClick={e => onExpand(record, e)} />
    //             <i className="far fa-folder" onClick={e => onExpand(record, e)}> Expand</i>
    //         )
    // };
    let searchInput = useRef(null);
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="sm"
            color="indigo"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="sm" color="light" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            color="indigo"
            size="sm"
            onClick={() => {
              confirm({ closeDropdown: false });
             
              setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
              setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
    setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
  };

 const  handleReset = clearFilters => {
    clearFilters();
    setState(state => ({ ...state, ['searchText']: '' }));
   
  };

    const columns = [
      {
        title: '',
        dataIndex: 'name',
        sorter: true,
          render: (text, record,) => <Avatar src={`${record.url}/${record.profile_pic}`} />,
        // width: '30%',
        // expandable: true,
        responsive: ['lg'],
        // ...getColumnSearchProps('inst_name'),
    },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            //   render: subj => `${name.first} ${name.last}`,
            // width: '30%',
            // expandable: true,
            responsive: ['lg'],
            ...getColumnSearchProps('inst_name'),
        },
        {
            title: 'Designation',
            dataIndex: 'desig_name',
            sorter: true,
            //   render: subj => `${name.first} ${name.last}`,
            // width: '20%',
            responsive: ['lg'],
            ...getColumnSearchProps('inst_type'),
        },
        {
          title: 'Location',
          dataIndex: 'locName',
          sorter: true,
          //   render: subj => `${name.first} ${name.last}`,
          // width: '20%',
          responsive: ['lg'],
          ...getColumnSearchProps('inst_type'),
      },

        {
            title: 'Mobile',
            dataIndex: 'mob_pri',
            sorter: true,
            responsive: ['lg'],
            ...getColumnSearchProps('district_name'),
            // render: text => <a>{text}</a>,
        },
        {
            title: 'Primary Mail',
            dataIndex: 'email_pri',
            sorter: true,
            responsive: ['lg'],
            render: text => <a>{text}</a>,
            ...getColumnSearchProps('p_mail'),
        },
        {
            title: 'Secondary Mail',
            dataIndex: 'email_sec',
            sorter: true,
            responsive: ['lg'],
            render: text => <a>{text}</a>,
            ...getColumnSearchProps('s_mail'),
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'reg_inst_id',
        //     key: 'x',
        //     render: (reg_inst_id) => 
        //     <>
        //     {/* <Button outline={true} color="lightBlue" children="Forwad" name="Status" id={mlogid} size="sm" onClick={deliveryStatus} /> */}
        //     <Button color="light" children="Edit" name="Status" id={reg_inst_id} size="sm" onClick={deliveryStatus} />
        //     </>,
        //     responsive: ['lg'],
        // },
    ];

    const getRandomuserParams = params => ({
        results: params.pagination.pageSize,
        page: params.pagination.current,
        status: [2],
        ...params,
    });
    useEffect(() => {
        const { pagination } = state;
        fetch({ pagination });
    }, []);
    const handleTableChange = (pagination, filters, sorter) => {
        fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    fetch = (params = {}) => {
        setState(state => ({ ...state, ['loading']: true }));
       
        axios.post(`https://tpms.kila.ac.in/api/contactPersonsList`, {
            status: [2], results: params.pagination.pageSize, page: params.pagination.current,
            ...params
        }, {
            // headers: authHeader()
        }).then(response => {
            setState({
                loading: false,
                data: response.data.users,
                pagination: {
                    ...params.pagination,
                    total: response.data.totalRows,

                },
                eventData: eventData,
                eventDataSummary:eventDataSummary,
            });

        }).catch((error) => {
            errorHandler(error);
        });
    };

    const currentDateTime= Date().toLocaleString()
    const props = {
        color: "amber",
        icon: "fas fa-smile-beam",
        title: "Best Quality",
        description:
          "It becomes harder for us to give others a hand. We get our heart broken by people we love.",
        gradient: false,
      }
      const inputprops = {
        placeholder: "Search  ",
        leftIcon: "fas fa-search",
        size: "sm",
        border: "borderless",
    }
      const [searchVal, setSearchVal] = useState('');
        const [filteredResults, setFilteredResults] = useState([]);
        const searchItems = (searchValue, field) => {
            setSearchVal(searchValue)
            if (searchVal !== '') {
                const filteredData = data.filter((item) => {
                    return Object.values(item).join('').toLowerCase().includes(searchVal.toLowerCase())
                })
                setFilteredResults(filteredData)
    
            }
            else {
                setFilteredResults(data)
    
            }
    
        }
    return (
        <>
            <Drawer
                id="drawer-3"
                header={"Mali Delivery Status Report As On - " + currentDateTime}
                size="full"
                isOpen={visible}
                onRequestClose={handleDrawerClose}
                
            >

                <div className="flex-auto px-6 pb-6 pt-0">

                    <Spin spinning={loading} tip="Loading..." >
                  
                       


                    </Spin>


                </div>
            </Drawer>
            <div className="w-full item-center xl:w-12/12 px-2">
                  <Divider><Input name="to" onChange={(e) => searchItems(e.target.value, e.target.name)} {...inputprops} /></Divider>
                </div>
            <Table
                columns={columns}
                rowKey={record => record.mlogid}
                dataSource={(searchVal.length > 1 ? filteredResults : data)}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                // expandable={expandable}
                expandRowByClick={true}
            />
        </>
    );

}
export default TpmsUsersCardTable;
// ReactDOM.render(<CardTable />, mountNode);