import React from "react";

import lottie from "lottie-web";
import deliveryService from "animations/74877-success";

export default function Success() {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#success"),
          animationData: deliveryService
        });
      }, []);
  return (
    <>
      <div className="h-32 " id="success" ></div>
    </>
  );
}
