import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                // user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {};
            case userConstants.LOGIN_OTP_REQUEST:
                return {
                    // loggingIn: true,
                    // user: action.user
                };
            case userConstants.LOGIN_OTP_SUCCESS:
                return {
                    loggedIn: true,
                    user: action.user
                };
            case userConstants.LOGIN_OTP_FAILURE:
                return {};    
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}