import axios from 'axios'
import { authHeader } from '../_helpers';
const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl=process.env.REACT_APP_API_URL;
const axiosClient = axios.create({
    baseURL: `https://api.example.com`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  // const baseUrl="http://localhost:4000/api/";
  // export const countryList=baseUrl+"masters/country";
  export function blackListEmail(email){
    return axiosClient.post(`${apiUrl}/api/masters/blacklist`, { email }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      });
}
export function checkEmail(email) {
    let promise =axiosClient.post(`${apiUrl}/api/masters/blacklist`, { email }, {
        headers: { "Access-Control-Allow-Origin": "*" }
      }).then(function (response) {
        // check if every one is saved
        const check = response.data.every(function(contact) {
            if(contact.email===email)
            return true;
            else
            return false;
            // return (contact.email===email);
        //   return contact.email;
        });
        console.log(check);
        return check;
    });
    return promise;
  }
 
// export default {
//     blackListEmail: async function(email){
//         try{
//             const response = await axios.post('http://localhost:4000/api/masters/blacklist', { email }, {
//                 headers: { "Access-Control-Allow-Origin": "*" }
//               })
              
//               return response.data;
//         }
//         catch(error){
//             throw error;
//         }
//     },
    
    
  
// }