import React, { useState } from "react";
import { useLocation,useParams } from 'react-router-dom';

// components

import FooterAuth from "components/Footers/Auth/FooterAuth.js";
// texts
import footerauth from "_texts/auth/footers/footerauth.js";
import lottie from "lottie-web";
import forgetPswd from "animations/93385-login";
import CardChangePassword from "components/Cards/Auth/CardChangePassword";
import ErrorCat from "animations/ErrorCat.js";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;


export default function ForgetPswd() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { token } = useParams();
  const [response, setResponse] = useState({
    status:false,
    serverResponse:false,
    message:"",
    name:"",
    user_id:""

  })
  const { status, serverResponse,message,name,user_id } = response;
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#image-loader"),
      animationData: forgetPswd
    });

    if (token) {
      axios.post(`${apiUrl}/api/auth/reset-pswd`, {
        token: token
      }, {
      })
        .then((response) => {
         
            setResponse({...response,
              ['status']:true,
              ['serverResponse']:response.data.status,
              ['message']:response.data.message,
              ['name']:response.data.name,
              ['user_id']:response.data.user_id
            })
         
          console.log(response.data.status)
        })

    }

  }, []);
 
  const [inputs, setInputs] = useState({
    conf_password: '',
    password: '',
  });
  const { conf_password, password } = inputs;

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  
  const inputData = {
    inputVal: {
      password: password,
      conf_password: conf_password,
      token:token,
      name:name,
      user_id:user_id
    }
  };
  const cardresetpassword = {
    image: "/notus-pro-react/static/media/linth3.41699b02.svg",
    title: "Reset Password",
    subtitle: "Enter New Password Below",
    
    inputs: [
      {
        label: "New Password",
        width: 6,
        input: {
          placeholder: "Password",
          type: "password",
          // defaultValue: "jesse@example.com",
          // value:password,
          name:'password',
          value:password,
          onChange:handleChange
        },
      },
      {
        label: "Confirm password",
        width: 6,
        input: {
          placeholder: "Password",
          type: "password",
          // defaultValue: "jesse@example.com",
          // value:conf_password,
          value:conf_password,
          name:'conf_password',
          onChange:handleChange
        },
      },
    ],
    button: { color: "dark", children: "Reset" },
  }
  return (
    <>
      {/* <main>
        <section className="relative w-full h-full py-40 min-h-screen">

          <div
            className="absolute top-0 w-full h-full bg-light-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/loginbg.png").default + ")",
            }}
            
          >
          </div>
          

          <div className="container mx-auto px-4 h-full">
            <div className="flex  h-full">
              <div className="w-full lg:w-4/12 px-4">
                <CardLoginSocial {...textProps} {...inputData} />
              </div>

            </div>
          
          </div>
          <FooterAuth {...footerauth} />
        </section>
      </main> */}
      <div className="p-0 flex items-center overflow-hidden relative bg-blueGray-100 min-h-screen max-h-999-px">
        <div
          className=" left-0 w-80 h-full bg-cover bg-50"
          id="image-loader"
          // style={{
          //   backgroundImage:
          //     "url('" +
          //     require("assets/img/illustrations/reset.svg").default +
          //     "')",
          // }}
        ></div>
        <div className="container  mx-auto px-2">
          <div className="justify-center  flex flex-wrap -mx-2">
            <div className="px-4 relative w-full lg:w-5/12 w-full md:w-8/12">
              {/* <div id="image-loader"></div> */}
              {(status && serverResponse === true) &&
              <CardChangePassword {...cardresetpassword} {...inputData} />
              }
               {(status && serverResponse === false) &&   <div className="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg"><ErrorCat />
               <div className="text-center d text-lg text-red-500 mb-5 mt-1">
           <p>{message}</p>
         </div> 
         </div>
                }
            </div>
          </div>
        </div>
        <FooterAuth {...footerauth} />
      </div>
    </>
  );
}
