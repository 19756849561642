import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from "axios";
import { authHeader } from '../_helpers';
import { useLocation } from 'react-router-dom';

// components
import SidebarAdmin from "components/Sidebar/SidebarAdmin.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import SidebarText from "_texts/admin/sidebar/sidebar";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
// import SidebarAdmin from "components/Sidebar/SidebarAdmin1.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import footeradmin from "_texts/admin/footers/footeradmin.js";
import Maintanance from "animations/Maintanance";
import moment from 'moment'
import MaintenanceBlog from 'components/Headers/Blog/MaintenanceBlog';

import LockScreen from "react-lock-screen";
// import { Button } from 'react-rainbow-components';
import Button from 'components/Elements/Button';
import lottie from "lottie-web";
import LockScreenBg from 'animations/LockScreenBg';




function PrivateRoute({ component: Component, roles, ...rest }) {
  const user = useSelector(state => state.authentication.user);
  const user_fields = JSON.parse(localStorage.getItem('user'));

  // if(user.hasOwnProperty('accessToken')){
  //   alert();
  // }
  // else{
  //   alert()
  // }
  // const user = JSON.parse(localStorage.getItem('user'));
  const dropdownuser = {
    // image: require("assets/img/team-1-800x800.jpg").default,
    image: (!localStorage.getItem('user')) ? "" : user_fields.profile_image,
    items: [
      {

        children: (!localStorage.getItem('user')) ? "" : user.username,
      },
      {
        href: "#pablo",
        children: "Another action",
      },
      {
        href: "#pablo",
        children: "Something else here",
      },
      {
        divider: true,
      },
      {
        href: "#pablo",
        to: '/login',
        children: "Logout",
      },
    ],
  };
  const navbarsearchuserDashboard = {
    brand: {
      href: "#pablo",
      children: "",
    },
    input: {
      placeholder: "Search here",
      border: "borderless",
      type: "text",
    },
    dropdown: { ...dropdownuser },
  };
  const [transactions, setTransactions] = useState([])
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    if (localStorage.getItem('user') && user.hasOwnProperty('accessToken')) {
      axios.post(`${apiUrl}/api/report/dashboardTransactions`, {}, {
        headers: authHeader()
      }).then(response => {

        // console.log(response.data);
        setTransactions(
          response.data[0]
        );
      });
    }

  }, [user]);
  const headerstatcards = {
    color: "white",
    welcome: (!localStorage.getItem('user')) ? "" : user.name,
    last_login: (!localStorage.getItem('user')) ? "" : user_fields.last_login,
    cards: [
      // { ...cardstatsmini },
      {
        statSubtitle: "Today's Mail Send",
        statTitle: (transactions.today_mail_send === null ? "0" : transactions.today_mail_send),
        // statArrow: "up",
        // statPercent: "3.48",
        statPercentColor: "text-emerald-500",
        // statDescripiron: "Since last month",
        statIconName: "fas fa-mail-bulk",
        statIconColor: "bg-red-500",
      },
      {
        statSubtitle: "Today's SMS Send",
        statTitle: "0",
        statArrow: "down",
        statPercent: "3.48",
        statPercentColor: "text-red-500",
        statDescripiron: "Since last week",
        statIconName: "fas fa-sms",
        statIconColor: "bg-orange-500",
      },
      {
        statSubtitle: "Total Mail Send",
        statTitle: (transactions.total_mail_send === null ? "0" : transactions.total_mail_send),
        statArrow: "up",
        statPercent: "12",
        statPercentColor: "text-emerald-500",
        statDescripiron: "Since last month",
        statIconName: "far fa-chart-bar",
        statIconColor: "bg-lightBlue-500",
      },
      {
        statSubtitle: "Total SMS Send",
        statTitle: "0",
        statArrow: "down",
        statPercent: "1.10",
        statPercentColor: "text-orange-500",
        statDescripiron: "Since yesterday",
        statIconName: "fas fa-users",
        statIconColor: "bg-pink-500",
      },

    ],
  };

  const location = useLocation();
  console.log(location.pathname);
  let textProps = '';
  if (user && user.roles && user.roles.includes('ADMIN')) {
    textProps = {
      brand: {
        text: "PostMan",
        // image: require("assets/img/brand/PostMan-logo.png").default,
        link: {
          href: "#pablo",
        },
      },
      activeColor: "indigo",
      items: [
        {
          divider: true,
        },
        // {
        //   title: "Home",

        // },
        {
          icon: "fas fa-newspaper",
          text: "Dashboard",
          active: (location.pathname === '/dashboard') ? true : false,
          link: {
            to: "/dashboard",
          },
        },


        {
          divider: true,
        },
        {
          title: "User Management",
        },
        // {
        //   icon: "fas fa-newspaper",
        //   text: "Localbodies",
        //   active: (location.pathname === '/localbodies') ? true : false,
        //   link: {
        //     to: "/localbodies",
        //   },
        // },
        // {
        //   icon: "fas fa-newspaper",
        //   text: "Stakeholder",
        //   active: (location.pathname === '/stakeholder') ? true : false,
        //   link: {
        //     to: "/stakeholder",
        //   },
        // },

        // {
        //   icon: "fas fa-paint-brush",
        //   text: "Others",
        //   active: (location.pathname === '/others') ? true : false,
        //   link: {
        //     to: "/others",
        //   },
        // },
        {
          icon: "fas fa-user-circle",
          text: "users",
          active: (location.pathname === '/users') ? true : false,
          link: {
            to: "/users",
          },
        },
        {
          divider: true,
        },
        {
          title: "Communication",
        },
        {
          icon: "fas fa-mail-bulk",
          text: "Mail",
          active: (location.pathname === '/email') ? true : false,
          link: {
            to: "/email",
          },
        },
        {
          icon: "fas fa-sms",
          text: "SMS",
          active: (location.pathname === '/sms') ? true : false,
          link: {
            href: "#pablo",
          },
        },

        {
          divider: true,
        },
        {
          title: "Settings",
        },
        {
          icon: "fas fa-fingerprint",
          text: "Configurations",
          active: (location.pathname === '/settings') ? true : false,
          link: {
            // href: "#pablo",
            to: "/settings"
          },
        },
        
       
        {
          icon: "fas fa-tools",
          text: "Role",
          active: (location.pathname === '/role') ? true : false,
          link: {
            to: "/role"
          },
        },
        // {
        //   icon: "fas fa-table",
        //   text: "Permissions",
        //   active: (location.pathname === '/dashboard') ? true : false,
        //   link: {
        //     href: "#pablo",
        //   },
        // },
        {
          icon: "fas fa-user",
          text: "Profile",
          active: (location.pathname === '/profile') ? true : false,
          link: {
            to: "/profile",
          },
        },
        {
          icon: "fas fa-user",
          text: "Recent Login",
          active: (location.pathname === '/login-activity') ? true : false,
          link: {
            to: "/login-activity",
          },
        },
        // {
        //   divider: true,
        // },
        // {
        //   title: "Masters",
        // },
        // {
        //   icon: "fas fa-tv",
        //   text: "Districts",
        //   // active: (location.pathname === '/dashboard') ? true : false,

        //   link: {
        //     href: "#pablo",
        //   },
        // },

        // {
        //   icon: "fas fa-clipboard-list",
        //   text: "Other",
        //   // active: (location.pathname === '/dashboard') ? true : false,
        //   link: {
        //     href: "#pablo",
        //   },
        // },
      ],
    };
  }
  else {
    textProps = {
      brand: {
        text: "PostMan",
        // image: require("assets/img/brand/PostMan-logo.png").default,
        link: {
          href: "#pablo",
        },
      },
      activeColor: "indigo",
      // items: [
      //     {
      //     divider: true,
      //   },
      //   {
      //     title: "Home",

      //   },
      //   {
      //     icon: "fas fa-newspaper",
      //     text: "Dashboard",
      //     active: true,
      //     link: {
      //       to: "/dashboard",
      //     },
      //   },


      //   {
      //     divider: true,
      //   },

      //   user.privilleges],

      items: [
        {
          divider: true,
        },
        {
          title: "Home",

        },
        {
          icon: "fas fa-newspaper",
          text: "Dashboard",
          active: (location.pathname === '/dashboard') ? true : false,
          link: {
            to: "/dashboard",
          },
        },



        {
          divider: true,
        },
        {
          title: "Communication",
        },
        {
          icon: "fas fa-mail-bulk",
          text: "Mail",
          active: (location.pathname === '/email') ? true : false,
          link: {
            to: "/email",
          },
        },
        // {
        //   icon: "fas fa-sms",
        //   text: "SMS",
        //   link: {
        //     href: "#pablo",
        //   },
        // },

        {
          divider: true,
        },
        {
          title: "Settings",
        },


        // {
        //   icon: "fab fa-vuejs",
        //   text: "Privilleges",
        //   link: {
        //     href: "#pablo",
        //   },
        // },
        {
          icon: "fas fa-user",
          text: "Profile",
          active: (location.pathname === '/profile') ? true : false,
          link: {
            to: "/profile",
          },
        },
        {
          icon: "fas fa-user",
          text: "Recent Login",
          active: (location.pathname === '/login-activity') ? true : false,
          link: {
            to: "/login-activity",
          },
        },

      ],
    };
  }
  const Navbarprops = {
    brand: { href: location.pathname, children: `Home${location.pathname}` },
    input: { placeholder: "Search here", border: "borderless", type: "text", display: false },
    // dropdown: dropdownuser
  }
  const [maintanance, setMaintanance] = useState(false)
  const [inputs, setInputs] = useState({
    system_maintenance_date: [],
    system_maintenance_help: '',
    sms_maintenance_date: '',
    sms_maintenance_help: '',
    mail_maintenance_date: '',
    mail_maintenance_help: '',

  });
  const { system_maintenance_date, system_maintenance_help, sms_maintenance_date, sms_maintenance_help, mail_maintenance_date, mail_maintenance_help } = inputs;
  useEffect(() => {
    axios.get(`${apiUrl}/api/config/global-settings`).then(response => {
      response.data.result.map((prop, key) => {
        if (prop.config_key === 'system_maintenance') {
          var config = JSON.parse(prop.config_value)
          var beginningTime = moment(moment().format("YYYY-MM-DD HH:mm"), 'YYYY-MM-DD HH:mm');
          var endTime1 = moment(config.from, 'YYYY-MM-DD HH:mm');
          var endTime2 = moment(config.to, 'YYYY-MM-DD HH:mm');
          // console.log("Beg Time = "+beginningTime.toString());
          // console.log("End Time1 = " +endTime1.toString());
          // console.log("End Time2 = "+endTime2.toString());
          setMaintanance(beginningTime.isBetween(endTime1, endTime2) && config.status == 1);
          console.log(maintanance)
          // console.log("time mmmmmm"+bool1);
          setInputs(inputs => ({ ...inputs, ['system_maintenance_date']: [moment(config.from), moment(config.to)] }));
          // setSystemStatus(config.status==1 ? true : false)
          setInputs(inputs => ({ ...inputs, ['system_maintenance_help']: config.help_text }));
        }



      })
    });

  }, []);

  const Maintenanceprops = {
    // title: "Miami Beach is looking different at night!",
    date: `${moment(system_maintenance_date[0]).format("Do MMMM YYYY, h:mm a")} To ${moment(system_maintenance_date[1]).format("Do MMMM YYYY, h:mm a")}`,
    quote: "'We Are Under Maintenance'",
    quoteSource: "- We will come back soon",
    description:
      system_maintenance_help,
    icon: "fas fa-tools",
    color: "amber",
    image: "/notus-pro-react/static/media/photo-1.002cf08f.jpg",
  }
  const [styleState, setStylestate] = useState("")
  const getLockScreenUi = (setLock) => {
    setStylestate("react-lock-screen-custom")
    return (
      <div className="react-lock-screen__ui">
        {/* <img
          width="32"
          src="https://cdn3.iconfinder.com/data/icons/wpzoom-developer-icon-set/500/102-256.png"
          alt="lock"
        /> */}
        <LockScreenBg></LockScreenBg>
        <p>Just to be safe, we locked the screen</p>
        <Button children="Unlock" color="teal" size="sm" onClick={() => setLock(false)} />
      </div>
    );
  };

  return (
    <Route {...rest} render={props => {
      if (!localStorage.getItem('user') || !user.hasOwnProperty('accessToken')) {
        // if (!user.accessToken) {
        // if(user && !user.hasOwnProperty('accessToken')){


        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }

      // logged in so return component
      return <React.Fragment>

<LockScreen className={styleState} timeout={120000} ui={getLockScreenUi}> 
        {/* <SidebarAdmin  /> */}
        <Sidebar  {...textProps} />
        <div className="relative md:ml-64 bg-blueGray-100">
          <NavbarSearchUser {...Navbarprops} />
          {/* <AdminNavbar {...navbarsearchuserDashboard} /> */}
          <HeaderStatCards {...headerstatcards} />
          <div className="px-4 md:px-6 mx-auto w-full -mt-24">

            {maintanance && !user.roles.includes('ADMIN') &&
              <>
                    <div className="w-full xl:w-12/12 px-4">
                    <MaintenanceBlog {...Maintenanceprops} />                   
                    </div>               
              </>

            }
            {(!maintanance || user.roles.includes('ADMIN')) &&
              
            <Component {...props} />
           }
            <FooterAdmin {...footeradmin} />
      

          </div>
        </div>

        </LockScreen>
      </React.Fragment>
    }} />
  );
}

export { PrivateRoute };