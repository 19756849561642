/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// components

export default function FooterAuth({ copyright, socials, links }) {
  return (
    <>
      <footer className="w-full absolute bottom-0">
        <div className="container mx-auto px-4">
          <div className="items-center xl:justify-between flex flex-wrap -mx-4">
            <div className="px-4 relative w-full xl:w-6/12 w-full sm:w-full">
              <div className="text-sm text-blueGray-400 text-center xl:text-left py-6">
                Copyright © { new Date().getFullYear() }
                {copyright && copyright.to ? (
                  <Link
                    {...copyright}
                    className="text-blueGray-400 font-semibold ml-1"
                  />
                ) : (
                  <a
                    {...copyright}
                    className="text-blueGray-400 font-semibold ml-1"
                  />
                )}
                . All rights reserved.
              </div>
            </div>
            <div className="px-4 relative w-full xl:w-3/12 w-full sm:w-full">
            <div className="py-6">
                  {socials.map((prop, key) => {
                    return (
                      <a
                        key={key}
                        href={prop.link}
                        target="_blank"
                        className={
                          "bg-white text-" +
                          prop.icon +
                          "-regular shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 inline-flex"
                        }
                        type="button"
                      >
                        <i className={"fab fa-" + prop.icon}></i>
                      </a>
                    );
                  })}
                </div>
            </div>

            <div className="px-4 py-4 relative w-full xl:w-3/12 w-full sm:w-full">
              <ul className="justify-center xl:justify-end mx-auto flex flex-wrap list-none pl-0 mb-0">
                {links.map((prop, key) => {
                  return (
                    <li key={key}>
                      {prop && prop.to ? (
                        <Link
                          {...prop}
                          className="text-sm block py-2 px-4 bg-transparent no-underline text-blueGray-400 hover:text-blueGray-500 py-4 md:py-6 mx-auto"
                        />
                      ) : (
                        <a
                          {...prop}
                          className="text-sm block py-2 px-4 bg-transparent no-underline text-blueGray-400 hover:text-blueGray-500 py-4 md:py-6 mx-auto"
                        />
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

FooterAuth.defaultProps = {
  copyright: {},
  socials: [],
  links: [],
};

FooterAuth.propTypes = {
  // this is a link, so if you pass "to" as prop
  // it will be generated as a Link from react-router-dom
  // otherwise, it will be generated as a simple anchor tag
  copyright: PropTypes.object,
  socials: PropTypes.arrayOf(
    // this will generate an anchor with target blank to the given link
    PropTypes.shape({
      icon: PropTypes.oneOf([
        "facebook",
        "twitter",
        "instagram",
        "dribbble",
        "github",
        "youtube",
      ]),
      link: PropTypes.string,
    })
  ),
  // same as above, just that this an array of them
  links: PropTypes.arrayOf(PropTypes.object),
};
