import React from "react";
import { useLocation } from "react-router-dom";


// components
// import TabsUsers from "components/Tabs/TabsUsers.js";
import UsersTab from "components/Tabs/UsersTab";


export default function Users() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  

  return (
    <>
     
      
          <div className="flex flex-wrap">
          <div className="w-full xl:w-12/12 px-4">
          <UsersTab color="pink" />
          </div>
            
          </div>
         
      
    </>
  );
}
