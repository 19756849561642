import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { errorHandler } from "error/errorHandler";
import { MultiSelect } from "react-multi-select-component";
import 'antd/dist/antd.css';


// components
import Button from "../../Elements/Button.js";
import Input from "../../Elements/Input.js";
// import Select from "../../Elements/Select.js";
import Incrementer from "../../Elements/Incrementer.js";

import { Spin, Slider, Switch, Table, Space, Tag,Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { authHeader } from '../../../_helpers';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";





export default function CardConfigurationPermission() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { Option, OptGroup } = Select;
  const widths = {
    1: "lg:w-1/12",
    2: "lg:w-2/12",
    3: "lg:w-3/12",
    4: "lg:w-4/12",
    5: "lg:w-5/12",
    6: "lg:w-6/12",
    7: "lg:w-7/12",
    8: "lg:w-8/12",
    9: "lg:w-9/12",
    10: "lg:w-10/12",
    11: "lg:w-11/12",
    12: "lg:w-12/12",
  };

  const [Activetoggle, setToggle] = useState(true);

 
  const [addressAdd, setAddressAdd] = useState(false);
  const [inputs, setInputs] = useState({
    name:'',
    id:""
  });
  const { name,id} = inputs;
  function handleChangeInput(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  
  function handleToggleStatus(value) {
    setToggle(value);
  }
 
 

  const cardFormAddress = {
    title: "Privilege Settings",
    button: {
      children: "Add New ",
      size: "sm",
      color: "indigo",
    },
    forms: [
      {
        title: "From Address",

        inputs: [

          {
            label: "Privilege Name",
            width: 4,
            input: {
              placeholder: "From Address",
              type: "text",
              name:'name',
              value:name,
              onChange:handleChangeInput
            },
          },
         
         
          {
            label: "Status",
            width: 3,
            switch: {
              id: 1,
              name: "first",
              checked: Activetoggle,
              // size:"small",
              onChange: handleToggleStatus,
            },
          },


          {
            label: "",
            width: 12,
            button: {
              children: "Save Changes",
              size: "sm",
              color: "indigo",
            },
          },
        ],
      },
    ],
  };

  function handleAddressSubmit(e) {
    const { pagination } = state;
    e.preventDefault();
    if(id>0){
      axios.put(
        `${apiUrl}/api/permission/privileges/${id}`,
        {
          name: name,
         
       
          status:!Activetoggle ? 0 :1
        }
      ).then((response) => {
         toast.success(`From address updated `, {
                autoClose:true
              });
        console.log(response.data);
        setInputs(inputs => ({ ...inputs, 
          ['name']: '',
          ['id']: '',
         
         }));
      
        
         fetch({ pagination });
      }).catch(error => {
          errorHandler(error);
      });
    }
    else{
      axios.post(
        `${apiUrl}/api/permission/privileges`,
        {
          name: name,
         
  
          status:!Activetoggle ? 0 :1
        }
      ).then((response) => {
        toast.success(`From address Addes `, {
          autoClose:true
        });
        console.log(response.data);
        setInputs(inputs => ({ ...inputs, 
          ['name']: '',
          ['id']: '',
    
         }));
        
         fetch({ pagination });
      }).catch(error => {
          errorHandler(error);
      });
    }
  }
  

 
  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 5,
    },
    loading: false,
    visible: false,
    searchText: '',
    searchedColumn: '',

  });
  const { data, pagination, loading, visible, searchText, searchedColumn } = state;
  function editAddress(e) {
    e.preventDefault();
    setAddressAdd(true)
    const { name, id } = e.target;
    setState(state => ({ ...state, ['loading']: true }));
    axios.get(`${apiUrl}/api/permission/privileges/${id}`, {
      // params: {
      //   from_id: id
      // }, 
    }, {
      headers: authHeader()
    }).then(response => {
      if (response.data.totalRows === 0) {
        toast.warn("No records found", {
          // position: toast.POSITION.TOP_CENTER
        });
        // setState(state => ({ ...state, ['editableData']: [] }));
      }
      response.data.result.map((prop, key) => {
        setInputs(inputs => ({ ...inputs, 
        ['name']: prop.name,
        ['id']: prop.id,
       }));
       
       
       setToggle(prop.status===1 ? true : false)
     
      })
     
      setState(state => ({ ...state, ['loading']: false }));

      console.log(response.data.result)
    })
      .catch((error) => {
        errorHandler(error);
      });


  }
  
  let searchInput = useRef(null);
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="sm"
            color="indigo"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="sm" color="light" style={{ width: 90 }}>
            Cancel
          </Button>
          <Button
            type="link"
            color="indigo"
            size="sm"
            onClick={() => {
              confirm({ closeDropdown: true });

              setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
              setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
              setState(state => ({ ...state, ['loading']: false }));

            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
    setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
    setState(state => ({ ...state, ['loading']: false }));
  };

  const handleReset = clearFilters => {
    clearFilters();
    setState(state => ({ ...state, ['searchText']: '' }));
    setState(state => ({ ...state, ['loading']: false }));

  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'privilege_id',
      sorter: true,
      //   render: subj => `${name.first} ${name.last}`,
      // width: '30%',
      // expandable: true,
      // responsive: ['md'],
      fixed: 'left',
      ...getColumnSearchProps('privilege_id'),
    },
    {
      title: 'Name',
      dataIndex: 'privilege_name',
      // sorter: true,
      //   render: subj => `${name.first} ${name.last}`,
      // width: '20%',
      // responsive: ['md'],
      ...getColumnSearchProps('privilege_name'),
    },
    {
      title: 'Status',
      dataIndex: 'parent_id',
      // sorter: true,
      // responsive: ['lg'],
      render: text => <Tag color={text === null ? '#0ea5e9' : '#b3b3b3'}>{text === null ? 'Parent' : 'Child'}</Tag>,
      // ...getColumnSearchProps('status'),
    },
    {
      title: 'Redirect',
      dataIndex: 'to',
      // sorter: true,
      //   render: subj => `${name.first} ${name.last}`,
      // width: '20%',
      // responsive: ['md'],
      ...getColumnSearchProps('to'),
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      // sorter: true,
      // responsive: ['lg'],
      render: icon => <><i className={icon} /> <br></br>{icon}</>,
      // ...getColumnSearchProps('status'),
    },
    {
      title: 'Is Menu',
      dataIndex: 'isMenu',
      // sorter: true,
      // responsive: ['lg'],
      render: text => <Tag color={text === 1 ? '#0ea5e9' : '#b3b3b3'}>{text === 1 ? 'Menu' : 'Privilege'}</Tag>,
      // ...getColumnSearchProps('status'),
    },
    {
      title: 'Status',
      dataIndex: 'block',
      // sorter: true,
      // responsive: ['lg'],
      render: text => <Tag color={text === 0 ? '#14b8a6' : '#b3b3b3'}>{text === 0 ? 'Active' : 'Disabled'}</Tag>,
      // ...getColumnSearchProps('status'),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'x',
      render: (reg_inst_id) =>
        <>
          {/* <Button outline={true} color="lightBlue" children="Forwad" name="Status" id={mlogid} size="sm" onClick={deliveryStatus} /> */}
          <Button color="light" children="Edit" name="Status" id={reg_inst_id} size="sm" onClick={editAddress} />
        </>,
      // responsive: ['lg'],
      fixed: 'right',
    },
  ];

  const getRandomuserParams = params => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    status: [2],
    ...params,
  });
  useEffect(() => {
    if (localStorage.getItem('user')) {
      const { pagination } = state;
      fetch({ pagination });
  }
  }, []);
  const handleTableChange = (pagination, filters, sorter) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    setState(state => ({ ...state, ['loading']: true }));

    axios.get(`${apiUrl}/api/permission/privileges`, {
      results: params.pagination.pageSize, page: params.pagination.current,
      ...params
    }, {
      headers: authHeader()
    }).then(response => {
      setState({
        loading: false,
        data: response.data,
        pagination: {
          ...params.pagination,
          total: response.data.totalRows,

        },
       
      
      });

    }).catch((error) => {
      errorHandler(error);
    });
  };



  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12 px-4">
          <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
            <div className="mb-0 p-6 pb-0">
              <div className="text-center flex justify-between items-center">
                <h6 className="text-xl font-bold mb-0">{cardFormAddress.title}</h6>

                <div className="text-center flex  items-center">
                  <Button {...cardFormAddress.button} onClick={() => setAddressAdd(prevState => (!prevState))} />
                
                </div>

              </div>
            </div>
            {/* <Spin spinning={Loader} tip="Please Wait..."> */}
            {addressAdd && <div className="flex-auto px-6 pb-6 pt-0">
              <form onSubmit={handleAddressSubmit} encType="multipart/form-data" >
                {cardFormAddress.forms.map((prop, key) => (

                  <div key={key}>
                    <h6 className="mt-6 mb-2 font-bold">{prop.title}</h6>
                    <hr className="mb-6 border-b-1 border-blueGray-200" />
                    <div className="flex flex-wrap -mx-4">
                      {prop.inputs &&
                        prop.inputs.map((inputProp, inputKey) => (
                         
                          <div
                            key={inputKey}
                            className={"w-full px-4 " + widths[inputProp.width]}
                          >
                            <div className="relative w-full mb-3">
                              {inputProp.label && (
                                <label
                                  className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                                  htmlFor="grid-password"
                                >
                                  {inputProp.label}
                                </label>
                              )}
                              {inputProp.input && <Input {...inputProp.input} />}
                              
                              {/* {inputProp.select && <Select {...inputProp.select} />} */}
                              {/* {inputProp.CKediter && <CKEditer {...inputProp.CKediter} />} */}

                            
                              {inputProp.switch && <Switch  {...inputProp.switch} />}
                              {inputProp.multiselect && <MultiSelect {...inputProp.multiselect} />}
                              {inputProp.section && <div><section dangerouslySetInnerHTML={{ __html: inputProp.section.content }} /></div>}
                              {inputProp.singleSelect &&
                                <Select
                                  showSearch
                                  style={{ width: '100%' }}
                                  showArrow={false}
                                  size='middle'
                                 
                                  {...(inputProp.singleSelect.defaultValue ? { defaultValue: inputProp.singleSelect.defaultValue } : {})}
                                  
                                  mode={inputProp.singleSelect.mode ? inputProp.singleSelect.mode : ''}
                                  placeholder={inputProp.singleSelect.placeholder}
                                  onChange={inputProp.singleSelect.onChange}
                                  optionLabelProp="label"
                                  optionFilterProp="label"
  
                                >
                                 
          
                                    {inputProp.singleSelect.option.map((prop, key) => (

                                      <Option

                                        key={key}
                                        value={prop.value}
                                        label={prop.label}>{prop.label}
                                      </Option>
                                    ))
                                  }


                                </Select>
                              }
                              
                              

                              {inputProp.button && <Button {...inputProp.button} />}


                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}

              </form>

            </div>}
            {/* </Spin> */}
            <div className="px-4 py-2">
            <Table
              columns={columns}
              rowKey={record => record.mlogid}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              // sorter={false}
              onChange={handleTableChange}
              // expandable={expandable}
              expandRowByClick={true}
            />
            </div>
            
          </div>
        </div>


     
      </div>


      
    </>
  );
}

const inputsObject = {
  // NOTE: this width only applies on large devices
  width: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  // if you wish, you can send somthing like
  // <span className="mr-2">Label Name</span><span className="text-red-500">*</span>
  // NOTE: the label tag will be auto generated
  label: PropTypes.node,
};

const inputsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Input component
  input: PropTypes.object,
});

const selectsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Select component
  select: PropTypes.object,
});

const inputsTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([inputsShape, selectsShape])
);

CardConfigurationPermission.defaultProps = {
  button: {},
  forms: [],
};

CardConfigurationPermission.propTypes = {
  title: PropTypes.string,
  // props to pass to the Button component
  button: PropTypes.object,

  forms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      inputs: inputsTypes,
    })
  ),
};
