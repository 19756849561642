import React from 'react';
import { Tooltip } from 'antd';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const CheckInput2 = React.forwardRef(
  ({ error, items, buttonClick,  ...rest }, ref) => {

function onClick(event) {
  if (event.detail === 2) {
    console.log("DOUBLE CLICK"+event.target.id);
  }
}

    return (
      <>
      <div className="tag-item-list" >
       {items.map(item => (
      <div className={"tag-item "+ item.email} id={item.email} onClick={onClick} key={item.email}>
        {/* {item.email}  <span className={"sp"+item.email}>{item.name}</span> */}
        <>
        <Collapse  ghost>
    <Panel showArrow={false} header={(item.name!=='' ? item.name : item.email)} key={item.email}>
      <p>{item.email}</p>
    </Panel>
    </Collapse>
    </>
        <button
          type="button"
          className="button"
          onClick={buttonClick}
          name={rest.name}
          id={item.email}
        
        >
          &times;
        </button>
      </div>
    ))}
    </div>
    <div className="relative flex w-full flex-wrap items-stretch mb-3">
    <Tooltip title={(rest.tooltip ? rest.tooltip : "")} placement="bottom" color='#2db7f5' key='#108ee9'>
    <input
      className={"input px-3 py-2 text-sm w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200  border-blueGray-300 pr-10" + (error && " has-error")}
      {...rest} list="browsers"
    />
      <datalist id="browsers">
         <option value="jasar@kila.ac.in" />                                                                                  
            
       </datalist>
    </Tooltip>
     <span className="z-10 h-full flex absolute text-center text-blueGray-300 text-sm items-center w-8 right-0">
      <i className="fas fa-at"></i>
    </span>
    </div>
    {error && <p className="error">{error}</p>}
  </>
     
    );
  }
  

  
  );
  CheckInput2.defaultProps = {
    items: [],
    
  };
  
  export default CheckInput2;