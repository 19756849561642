import React from "react";

import lottie from "lottie-web";
import LockScreen from "animations/90452-access-door";

export default function LockScreenBg() {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#screen"),
          animationData: LockScreen
        });
      }, []);
  return (
    <>
      <div className=""  id="screen" ></div>
    </>
  );
}
