
import React, { useEffect, useState, useRef } from "react";
import { Table, Spin, Space, Tag, Popconfirm, Timeline, Divider, Badge, Select,Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { authHeader } from '../../../_helpers';
import axios from "axios";
import { errorHandler } from "error/errorHandler";
import Button from "../../Elements/Button.js";
import Input from "../../Elements/Input.js";
import {  Chip, Accordion, AccordionSection } from 'react-rainbow-components';
import moment from 'moment'
import { Spinner } from 'react-rainbow-components';
import socketIOClient from "socket.io-client";
import {ExportToExcel} from "../../Export/ExportToExcel"





const StakeholderCardTable = () => {
  const { Option } = Select;
  const widths = {
    1: "lg:w-1/12",
    2: "lg:w-2/12",
    3: "lg:w-3/12",
    4: "lg:w-4/12",
    5: "lg:w-5/12",
    6: "lg:w-6/12",
    7: "lg:w-7/12",
    8: "lg:w-8/12",
    9: "lg:w-9/12",
    10: "lg:w-10/12",
    11: "lg:w-11/12",
    12: "lg:w-12/12",
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const socket = socketIOClient(apiUrl);

  const [state, setState] = useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 5,
    },
    loading: false,
    visible: false,
    formLoading: false,
    searchText: '',
    searchedColumn: '',

  });
  const [jobData, setJobData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const { data, pagination, loading,formLoading, visible, searchText, searchedColumn } = state;
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  function editStakeholder(e) {
    scrollToTop()
    e.preventDefault();
    const {  id } = e.target;
    setCreateMode(false)
    setState(state => ({ ...state, ['formLoading']: true }));
    setState(state => ({ ...state, ['visible']: true }));
    axios.get(`${apiUrl}/api/users/stakeholders/${id}`, {
      headers: authHeader()
    }).then(response => {

      //  setSelectedCountry(response.data.country_id,() => {
      //   setSelectedState(response.data.state_id,() => {
      //     setSelectedDistrict(response.data.district_id,() => {
      //       setSelectedInstType(response.data.inst_type_id,() => {
      //         setSelectedBlock(response.data.block_id,() => {
      //           setInputs(inputs => ({ ...inputs, 
      //             ['inst_id']: response.data.reg_inst_id,
      //             ['inst_name']: response.data.inst_name,
      //             ['pri_mail']: response.data.p_mail,
      //             ['sec_mail']: response.data.s_mail,
      //             ['office_phone']: response.data.office_phone,
      //            }),() => {
      //             setState(state => ({ ...state, ['loading']: false }));

      //           });
      //         });
      //       });
      //   });
      //   });
      //   });





      setInputs(inputs => ({
        ...inputs,
        ['inst_id']: response.data.person_id,
        ['inst_name']: response.data.name,
        ['pri_mail']: response.data.p_mail,
        ['sec_mail']: response.data.s_mail,
        ['pri_office_phone']: response.data.p_phone,
        ['sec_office_phone']: response.data.s_phone,
        ['pri_mobile']: response.data.p_m_phone,
        ['sec_mobile']: response.data.s_m_phone,
      }));
      

      //  setSelectedCountry(response.data.country_id)
      //  setSelectedState(response.data.state_id)
      // setSelectedDistrict(response.data.district_id);
      // setSelectedInstType(response.data.inst_type_id);
      // setSelectedBlock(response.data.block_id);
      setState(state => ({ ...state, ['formLoading']: false }));






    })
      .catch((error) => {
        errorHandler(error);
      });


  }
  function deleteStakeholder(e) {
    e.preventDefault();
    const {  id } = e.target;
    const { confirm } = Modal;
    confirm({
      // icon: <ExclamationCircleOutlined />,
      content: <span>Are You Sure</span>,
      onOk() {
    setCreateMode(false)
    setState(state => ({ ...state, ['loading']: true }));
    // setState(state => ({ ...state, ['visible']: true }));
    axios.get(`${apiUrl}/api/users/delete-stakeholders/${id}`, {
      headers: authHeader()
    }).then(response => {
      setState(state => ({ ...state, ['loading']: false }));
      const { pagination } = state;
      fetch({ pagination });
      toast.success("User Deleted", {
        autoClose: true
      })

    })
      .catch((error) => {
        errorHandler(error);
      });
    },
    onCancel() {
      console.log('Cancel');
    },
  });


  }
 
  

  let searchInput = useRef(null);
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="sm"
            color="indigo"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="sm" color="light" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            color="indigo"
            size="sm"
            onClick={() => {
              confirm({ closeDropdown: false });

              setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
              setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState(state => ({ ...state, ['searchText']: selectedKeys[0] }));
    setState(state => ({ ...state, ['searchedColumn']: dataIndex }));
  };

  const handleReset = clearFilters => {
    clearFilters();
    setState(state => ({ ...state, ['searchText']: '' }));

  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'inst_name',
      sorter: true,
      //   render: subj => `${name.first} ${name.last}`,
      // width: '30%',
      // expandable: true,
      responsive: ['lg'],
      ...getColumnSearchProps('inst_name'),
    },
    {
      title: 'Type',
      dataIndex: 'inst_type',
      sorter: true,
      //   render: subj => `${name.first} ${name.last}`,
      // width: '20%',
      responsive: ['lg'],
      ...getColumnSearchProps('inst_type'),

    },
    {
      title: 'Level',
      dataIndex: 'level',
      sorter: true,
      responsive: ['lg'],
      filters: [
        {
          text: 'State',
          value: 'State',
        },
        {
          text: 'District',
          value: 'District',
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.level.includes(value),
      // render: text => <a>{text}</a>,
    },
    {
      title: 'District',
      dataIndex: 'district_name',
      sorter: true,
      responsive: ['lg'],
      ...getColumnSearchProps('district_name'),
      // render: text => <a>{text}</a>,
    },
    {
      title: 'Primary Mail',
      dataIndex: 'pri_mail',
      sorter: true,
      responsive: ['lg'],
      ...getColumnSearchProps('pri_mail'),
      // render: text => <a>{text}</a>,
    },
    {
      title: 'Status',
      dataIndex: 'pri_mail_status',
      sorter: true,
      key: 'pri_mail_status',
      responsive: ['lg'],
      render: record => <Tag color={record != 1 ? '#ff4d4f' : '#52c41a'}>{record != 1 ? record : 'Valid'}</Tag>,
      // ...getColumnSearchProps('pri_status'),
    },
    {
      title: 'Reason',
      dataIndex: 'pri_mail_reason',
      sorter: true,
      key: 'pri_mail_reason',
      responsive: ['lg'],
      render: record => <p>{(record.reason == null ? "" : <p>{record.reason} <br></br><small className="text-blueGray-500">Time:{moment(record).format("Do MMMM YYYY, h:mm a")}</small></p>)}</p>,
      // ...getColumnSearchProps('pri_mail_reason'),
    },
    {
      title: 'Secondary Mail',
      dataIndex: 'sec_mail',
      sorter: true,
      responsive: ['lg'],
      ...getColumnSearchProps('sec_mail'),
      // render: text => <a>{text}</a>,
    },
    {
      title: 'Status',
      dataIndex: 'sec_mail_status',
      // sorter: true,
      key: 'sec_mail_status',
      // responsive: ['lg'],
      render: record => <Tag color={record != 1 ? '#ff4d4f' : '#52c41a'}>{record != 1 ? record : 'Valid'}</Tag>,
      // ...getColumnSearchProps('sec_status'),
    },
    {
      title: 'Reason',
      dataIndex: 'sec_mail_reason',
      sorter: true,
      key: 'sec_mail_reason',
      responsive: ['lg'],
      render: record => <p>{(record.reason == null ? "" : <p>{record.reason} <br></br><small>Time:{moment(record).format("Do MMMM YYYY, h:mm a")}</small></p>)}</p>,

      // ...getColumnSearchProps('sec_status'),
    },
    {
      title: 'Action',
      dataIndex: 'person_id',
      key: 'x',
      render: (person_id) =>
        <>
          {/* <Button outline={true} color="lightBlue" children="Forwad" name="Status" id={mlogid} size="sm" onClick={deliveryStatus} /> */}

          <Button color="light" children="Edit" name="Status" id={person_id} size="sm" onClick={editStakeholder} />
          <Button color="red" children="Delete" name="Status" id={person_id} size="sm" onClick={deleteStakeholder} />
        </>,
      responsive: ['lg'],
    },
  ];


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys)
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };
  const [show, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    axios.post(`${apiUrl}/api/masters/addressListValidation`, {
      type: 1, user_type: 2
    }, {
      // headers: { "Access-Control-Allow-Origin": "*" }
      headers: authHeader()
    })
      .then((response) => {
        setJobData(response.data.jobData[0])

        setVisible(false);
        setConfirmLoading(false);
      })
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false);
  };
  const verifySelected = () => {
    if(selectedRows.length>0){
      setState(state => ({ ...state, ['loading']: true }));
    setState(state => ({ ...state, ['loading']: true }));
    axios.post(`${apiUrl}/api/masters/addressListValidation`, {
      type: 2, user_type: 2, rows: selectedRows
    }, {
      headers: authHeader()
    })
      .then((response) => {
        const { pagination } = state;
        fetch({ pagination });
        setState(state => ({ ...state, ['loading']: false }));

      })
    }
    else{
      toast.warn("Please Select atleast one")
    }
  };
  
  useEffect(() => {
    const { pagination } = state;
    fetch({ pagination });
  }, []);

  useEffect(() => {
    if(jobData.status && jobData.status === 1){
    setInterval(function () {
      socket.on("jobs", data => {
        // setState(state => ({ ...state, ['jobData']: data.jobData[0] }));
        setJobData(data.jobData[0])
        console.log(data.jobData[0])
      });
    }
      , 8000)
  }

    // return () => socket.disconnect();
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    setState(state => ({ ...state, ['loading']: true }));

    axios.post(`${apiUrl}/api/users/stakeholders`, {
      status: [2], results: params.pagination.pageSize, page: params.pagination.current,
      ...params
    }, {
      headers: authHeader()
    }).then(response => {
      setState({
        loading: false,
        data: response.data.result,
        pagination: {
          ...params.pagination,
          total: response.data.totalRows,

        },
      });
      setJobData(response.data.jobData[0])

    }).catch((error) => {
      errorHandler(error);
    });
  };
  function addinstitutionForm(e) {
    e.preventDefault();
    setCreateMode(true)
    setInputs(inputs => ({
      ...inputs,
      ['inst_id']: '',
      ['inst_name']: '',
      ['pri_mail']: '',
      ['sec_mail']: '',
      ['pri_office_phone']: '',
      ['sec_office_phone']: '',
      ['pri_mobile']: '',
      ['sec_mobile']: '',
    }));

    //  setSelectedCountry();
    //  setSelectedState();
    setSelectedDistrict();
    setSelectedStakeType();
    setStakeLevel(false)
    setState(state => ({ ...state, ['visible']: true }));
    setState(state => ({ ...state, ['formLoading']: false }));

  }
  const cardUser = {
    title: "Stakeholders",
    button: [
      {
        children: "Add New",
        size: "sm",
        color: "light",
        onClick: addinstitutionForm
      },
      {
        children: "Verify Selected",
        size: "sm",
        color: "indigo",
        key: "selected",
        onClick: verifySelected
      },

    ]
  };
  const [inputs, setInputs] = useState({
    inst_id: '',
    inst_name: '',
    pri_mail: '',
    sec_mail: '',
    pri_office_phone: '',
    sec_office_phone: '',
    pri_mobile: '',
    sec_mobile: '',
  });
  const { inst_id, inst_name, pri_mail, sec_mail, pri_office_phone, sec_office_phone, pri_mobile, sec_mobile } = inputs;
  function handleChangeInput(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(77);


  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(13);


  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();

  const [stakeTypeOptions, setStakeTypeOptions] = useState([]);
  const [selectedstakeType, setSelectedStakeType] = useState();
  const [stakeLevel, setStakeLevel] = useState(false);

  const [createMode, setCreateMode] = useState(true);
  useEffect(() => {
    if (visible) {
      axios.post(`${apiUrl}/api/masters/stakeType`, {}, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then((response) => {
          setStakeTypeOptions(
            response.data
          );

        });
      setStakeTypeOptions([]);
      if(selectedstakeType){
        axios.post(`${apiUrl}/api/masters/stakeLevel`, { type: [selectedstakeType] }, {
          // headers: { "Access-Control-Allow-Origin": "*" }
          headers: authHeader()
        })
          .then((response) => {
            response.data.map((prop, key) => {
              // alert(prop.level)
              if (prop.level === 'State') {

                setStakeLevel(true)

              }
              else {
                setStakeLevel(false)
              }
            })
            // setStakeholderLoading(false)

          });
      }
      axios.get(`${apiUrl}/api/masters/country`).then(response => {
        setCountryOptions(
          response.data
        );
      });
      if (selectedCountry) {

        axios.post(`${apiUrl}/api/masters/state`, { country: selectedCountry }, {
          headers: authHeader()
        })
          .then((response) => {
            setStateOptions(
              response.data
            );
          });
        setStateOptions(
          []
        );
      }
      if (selectedState && !stakeLevel) {
        axios.post(`${apiUrl}/api/masters/district`, { state: selectedState }, {
          // headers: { "Access-Control-Allow-Origin": "*" }
          headers: authHeader()
        })
          .then((response) => {
            setDistrictOptions(
              response.data
            );
          });
        setDistrictOptions(
          []
        );
      }
      
    }
  }, [visible, selectedCountry, selectedState, selectedstakeType]);



  const cardForm = {

    forms: [

      {
        title: "Stakeholders",

        inputs: [
          {
            label: "Institution Type *",
            width: (stakeLevel ? 4 : 3),
            display: createMode,
            singleSelect: {
              placeholder: "Institution Type",
              option: stakeTypeOptions,
              onChange: setSelectedStakeType,
              defaultValue: selectedstakeType,
              value: selectedstakeType
            },

          },
          {
            label: "Country *",
            width: (stakeLevel ? 4 : 3),
            display: createMode,
            singleSelect: {
              placeholder: "Country",
              option: countryOptions,
              onChange: setSelectedCountry,
              defaultValue: selectedCountry,
              value: selectedCountry


            },

          },
          {
            label: "State *",
            width: (stakeLevel ? 4 : 3),
            display: createMode,
            singleSelect: {
              placeholder: "State",
              option: stateOptions,
              onChange: setSelectedState,
              defaultValue: selectedState,
              value: selectedState


            },

          },
          {
            label: "District *",
            width: (stakeLevel ? 4 : 3),
            display: (!stakeLevel && createMode),
            singleSelect: {
              placeholder: "District",
              option: districtOptions,
              onChange: setSelectedDistrict,
              defaultValue: selectedDistrict,
              value: selectedDistrict


            },

          },
        ]
        },
        {
          title: "Contact Details",
          inputs: [
            
          {
            label: "Name *",
            width: (createMode ? 4 : 4),
            display: true,
            input: {
              placeholder: "Institution Name",
              type: "text",
              name: 'inst_name',
              value: inst_name,
              onChange: handleChangeInput
            },
          },

          {
            label: "Primary Mail *",
            width: (createMode ? 4 : 4),
            display: true,
            input: {
              placeholder: "Primary Mail",
              type: "email",
              name: 'pri_mail',
              value: pri_mail,
              onChange: handleChangeInput
            },
          },
          {
            label: "Secondary Mail *",
            width: (createMode ? 4 : 4),
            display: true,
            input: {
              placeholder: "Secondary Mail",
              type: "email",
              name: 'sec_mail',
              value: sec_mail,
              onChange: handleChangeInput
            },
          },
          {
            label: "Primary Office Phone",
            width: (createMode ? 3 : 3),
            display: true,
            input: {
              placeholder: "Office Phone",
              type: "text",
              name: 'pri_office_phone',
              value: pri_office_phone,
              onChange: handleChangeInput
            },
          },
          {
            label: "Secondary Office Phone",
            width: (createMode ? 3 : 3),
            display: true,
            input: {
              placeholder: "Sec Office Phone",
              type: "text",
              name: 'sec_office_phone',
              value: sec_office_phone,
              onChange: handleChangeInput
            },
          },
          {
            label: "Primary Mobile Phone",
            width: (createMode ? 3 : 3),
            display: true,
            input: {
              placeholder: "Mobile",
              type: "text",
              name: 'pri_mobile',
              value: pri_mobile,
              onChange: handleChangeInput
            },
          },
          {
            label: "Secondary Mobile Phone",
            width: (createMode ? 3 : 3),
            display: true,
            input: {
              placeholder: "Sec Mobile",
              type: "text",
              name: 'sec_mobile',
              value: sec_mobile,
              onChange: handleChangeInput
            },
          },

          {
            label: "",
            width: 4,
            display: true,
            button: {
              children: "Save ",
              size: "sm",
              color: "lightBlue",
            },
          },
        ],
      },
    ],
  };
  function handleSubmit(e) {

    e.preventDefault();
    if (inst_id > 0) {
      axios.put(
        `${apiUrl}/api/users/stakeholders/${inst_id}`,
        {
          // country: selectedCountry,
          // state: selectedState,
          // district: selectedDistrict,
          // inst_type_id: selectedInstType,
          // block_id:selectedBlock,
          desig: selectedstakeType,
          name: inst_name,
          p_mail: pri_mail,
          s_mail: sec_mail,
          p_phone: pri_office_phone,
          s_phone: sec_office_phone,
          p_m_phone: pri_mobile,
          s_m_phone: sec_mobile,

        }
      ).then((response) => {
        toast.success(`Institution updated `, {
          autoClose: true
        });
        console.log(response.data);
        setInputs(inputs => ({
          ...inputs,
          ['inst_id']: '',
          ['inst_name']: '',
          ['pri_mail']: '',
          ['sec_mail']: '',
          ['pri_office_phone']: '',
          ['sec_office_phone']: '',
          ['pri_mobile']: '',
          ['sec_mobile']: '',
        }));

        //  setSelectedCountry();
        //  setSelectedState();
        setSelectedDistrict();
        setSelectedStakeType();
        setStakeLevel(false)
        setState(state => ({ ...state, ['visible']: false }));
        fetch({ pagination });
      }).catch(error => {
        errorHandler(error);
      });
    }
    else {
      axios.post(
        `${apiUrl}/api/users/create-stakeholders`,
        {
          country: selectedCountry,
          state: selectedState,
          district: selectedDistrict,
          desig: selectedstakeType,
          name: inst_name,
          p_mail: pri_mail,
          s_mail: sec_mail,
          p_phone: pri_office_phone,
          s_phone: sec_office_phone,
          p_m_phone: pri_mobile,
          s_m_phone: sec_mobile,
        }
      ).then((response) => {
        toast.success(`Institution Added `, {
          autoClose: true
        });
        console.log(response.data);
        setInputs(inputs => ({
          ...inputs,
          ['inst_id']: '',
          ['inst_name']: '',
          ['pri_mail']: '',
          ['sec_mail']: '',
          ['pri_office_phone']: '',
          ['sec_office_phone']: '',
          ['pri_mobile']: '',
          ['sec_mobile']: '',
        }));

        //  setSelectedCountry();
        //  setSelectedState();
        setSelectedDistrict();
        setSelectedStakeType();
        setStakeLevel(false)
        setState(state => ({ ...state, ['visible']: false }));
        fetch({ pagination });
      }).catch(error => {
        errorHandler(error);
      });
    }
  }
  const fileName = "Stakeholders-List"; // here enter filename for your excel file
  const inputprops = {
    placeholder: "Search ",
    leftIcon: "fas fa-search",
    size: "sm",
    border: "borderless",
}
  const [searchVal, setSearchVal] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const searchItems = (searchValue, field) => {
        setSearchVal(searchValue)
        if (searchVal !== '') {
            const filteredData = data.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchVal.toLowerCase())
            })
            setFilteredResults(filteredData)

        }
        else {
            setFilteredResults(data)

        }

    }
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12 px-4">
          <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
            <div className="mb-0 p-6 pb-0">
              <div className="text-center flex justify-between items-center">
                <h6 className="text-xl font-bold mb-0">{cardUser.title}</h6>
                <div className="text-center flex  items-center">
                 
                  <div className="rainbow-m-around_xx-small">
                    <Accordion className="text-sm text-lightBlue" id="accordion-1">
                      <AccordionSection label={<> <div className="text-center text-sm text-blueGray-500 flex justify-between items-center">{"Recent Email Verification  Request  :- "}
                        <div className="rainbow-p-vertical_medium rainbow-align-content_center rainbow-flex_wrap">
                          <Chip className="rainbow-m-around_medium" label={(jobData.status && jobData.status === 1 ? "Processing" : "Completed")} variant='success' />

                        </div>
                        {jobData.status && jobData.status === 1 && <div className="py-2"> <Spinner size="large" /></div>}
                      </div></>}>

                        <p>
                          {/* <div className="rainbow-p-around_x-large rainbow-align-content_center items-center justify-center">
                            <Path currentStepName="in-progress">
                              <PathStep name="sent" label="Requested" />
                              <PathStep name="in-progress" label="InProgress" />
                              <PathStep name="completed" label="Completed" />

                            </Path>
                          </div> */}
                          <Divider orientation="center">Status Timeline</Divider>
                          <div className="relative flex flex-col min-w-0 break-words bg-white-100 w-full mb-6  rounded">
                            <div className="flex flex-wrap">
                              <div className="w-full xl:w-12/12 ">

                                <Timeline mode="alternleftate" pending={(jobData.status && jobData.status === 1 ? "Processing..." : "")}>
                                  <Timeline.Item >Requested  {jobData.requested_at}</Timeline.Item>
                                  <Timeline.Item color="blue">  Total Request <space></space>
                                    <Badge size="large" overflowCount={jobData.total_jobs} count={jobData.total_jobs}>
                                      {/* <Avatar shape="circle" size="large" /> */}
                                    </Badge>
                                  </Timeline.Item>
                                  <Timeline.Item color="green">Completed Request  <space></space>
                                    <Badge showZero={true} overflowCount={jobData.total_jobs} size="default" count={jobData.completed_jobs}>

                                    </Badge>
                                  </Timeline.Item>
                                  <Timeline.Item color="red">Pending Request <space></space>
                                    <Badge showZero={true} overflowCount={jobData.total_jobs} size="default" count={jobData.total_jobs - jobData.completed_jobs}>

                                    </Badge>
                                  </Timeline.Item>
                                  {jobData.status === 2 && <Timeline.Item color="green">Completed {jobData.completed_at}</Timeline.Item>}
                                </Timeline>
                              </div>
                            </div>
                          </div>
                        </p>
                      </AccordionSection>

                    </Accordion>
                  </div>
                </div>

                <div className="text-center flex  items-center">

                <ExportToExcel apiData={data} fileName={fileName} />
                  {cardUser.button.map((prop, key) => (
                    <>
                      <Button key={key} {...prop} />
                    </>
                  ))}
                  <Popconfirm
                    title="Are you Sure"
                    visible={show}
                    onConfirm={handleOk}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                  >
                    <Button children="Verify All" size="sm" color="teal" key="all" onClick={showPopconfirm} />
                  </Popconfirm>
                </div>

              </div>
            </div>
            <div className="px-4 py-2"></div>
          </div>
        </div>
      </div>
     { visible &&
        <div className="flex-auto px-6 pb-6 pt-0">

          <Spin spinning={formLoading} >


            <form onSubmit={handleSubmit} encType="multipart/form-data" >
              {cardForm.forms.map((prop, key) => (

                <div key={key}>
                  <h6 className="mt-6 mb-2 font-bold">{prop.title}</h6>
                  <hr className="mb-6 border-b-1 border-blueGray-200" />
                  <div className="flex flex-wrap -mx-4">
                    {prop.inputs &&
                      prop.inputs.map((inputProp, inputKey) => (
                        <div
                          key={inputKey}
                          className={"w-full px-4 " + widths[inputProp.width]}
                        >
                          <div className="relative w-full mb-3">
                            {inputProp.label && inputProp.display && (
                              <label
                                className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                                htmlFor="grid-password"
                              >
                                {inputProp.label}
                              </label>
                            )}
                            {inputProp.input && <Input {...inputProp.input} />}




                            {inputProp.section && <div><section dangerouslySetInnerHTML={{ __html: inputProp.section.content }} /></div>}
                            {inputProp.singleSelect && inputProp.display &&
                              <Select
                                showSearch
                                style={{ width: '100%' }}
                                showArrow={false}
                                size='middle'
                                disabled={inputProp.singleSelect.disabled ? inputProp.singleSelect.disabled : false}
                                {...(inputProp.singleSelect.defaultValue ? { defaultValue: inputProp.singleSelect.defaultValue } : {})}

                                mode={inputProp.singleSelect.mode ? inputProp.singleSelect.mode : ''}
                                placeholder={inputProp.singleSelect.placeholder}
                                onChange={inputProp.singleSelect.onChange}
                                optionLabelProp="label"
                                optionFilterProp="label"

                              >


                                {inputProp.singleSelect.option.map((prop, key) => (

                                  <Option

                                    key={key}
                                    value={prop.value}
                                    label={prop.label}>{prop.label}
                                  </Option>
                                ))
                                }


                              </Select>
                            }

                            {inputProp.button && <Button {...inputProp.button} />}


                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}

            </form>




          </Spin>


        </div>
      }
     <div className="w-full item-center xl:w-12/12 px-2">
                  <Divider><Input name="to" onChange={(e) => searchItems(e.target.value, e.target.name)} {...inputprops} /></Divider>
                </div>
      <Table
        columns={columns}
        rowKey={record => record.person_id}
        dataSource={(searchVal.length > 1 ? filteredResults : data)}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        rowSelection={{ ...rowSelection }}
        // expandable={expandable}
        expandRowByClick={true}
      />
    </>
  );

}
export default StakeholderCardTable;
// ReactDOM.render(<CardTable />, mountNode);