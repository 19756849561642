import React, {useState } from "react";
import { Tabs } from 'antd';
import LocalbodiesCardTable from "components/Cards/Users/LocalbodiesCardTable"
import StakeholderCardTable from "components/Cards/Users/StakeholderCardTable"
import UsersCardTable from "components/Cards/Users/UsersCardTable"
import OtherUsersCardTable from "components/Cards/Users/OtherUsersCardTable"
import TpmsUsersCardTable from "components/Cards/Users/TpmsUsersCardTable"
import BlacklistUsersCardTable from "components/Cards/Users/BlacklistUsersCardTable"


const { TabPane } = Tabs;


const Configuration = ({ color }) => {
  const [open, setOpen] = useState("1")
     const handleTabclick = (key,event) => {
      //  alert(key)
       setOpen(key)
     }
//   state = { size: 'small' };

//   onChange = e => {
//     this.setState({ size: e.target.value });
//   };

 
//     const { size } = this.state;
    return (
      <div>
         <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white px-4" 
        }
      >
        
        <Tabs defaultActiveKey="1" tabPosition="top" animated={{ inkBar: true, tabPane: false }} onTabClick={handleTabclick}  size={'large'}>
            <TabPane  tab={
                        <span className="text-lightBlue-500 bg-white">
                        <i className="fas fa-space-shuttle text-base mr-1 "></i> Localbodies
                        </span>
                        } 
                       key="1">
            {open==='1' && <LocalbodiesCardTable  />}
            {/* <SentItemCardTable /> */}
          </TabPane>
          
          <TabPane  tab={
                        <span className="text-teal-500 bg-white">
                        <i className="fas fa-mail-bulk text-base mr-1"></i> Stakeholder
                        </span>
                        } 
           key="2">
       {open==='2' && <StakeholderCardTable />}
    
          </TabPane>
          <TabPane tab={
                        <span className="text-blueGray-500 bg-white">
                       <i className="fas fa-mail-bulk text-base mr-1"></i> Tpms Users 
                        </span>
                        }  key="3">
         {open==='3' && <TpmsUsersCardTable />}
          </TabPane>
          <TabPane tab={
                        <span className="text-indigo-500 bg-white">
                       <i className="fas fa-mail-bulk text-base mr-1"></i> Postman Users 
                        </span>
                        }  key="4">
         {open==='4' && <UsersCardTable />}
          </TabPane>
          <TabPane tab={
                        <span className="text-amber-500 bg-white">
                       <i className="fas fa-mail-bulk text-base mr-1"></i> Others
                        </span>
                        }  key="5">
         {open==='5' && <OtherUsersCardTable />}
          </TabPane>
          <TabPane tab={
                        <span className="text-red-500 bg-white">
                       <i className="fas fa-mail-bulk text-base mr-1"></i> Blacklisted Others
                        </span>
                        }  key="6">
         {open==='6' && <BlacklistUsersCardTable />}
          </TabPane>
          {/* <TabPane tab={
                        <span className="text-amber-500 bg-white">
                       <i className="fas fa-mail-bulk text-base mr-1"></i>  Tpms Users
                        </span>
                        }  key="5">
         {open==='5' && <Empty />}
          </TabPane> */}
          
          
          
        </Tabs>
      </div>
      </div>
    );
    
}

export default function TabsRender() {
    return (
      <>
        <Configuration color="indigo" />
      </>
    );
  }
// ReactDOM.render(<MailTransaction />, mountNode);