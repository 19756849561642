import React, { useState } from "react";
import PropTypes from "prop-types";
// components
import Input from "../../../components/Elements/Input.js";
import Button from "../../../components/Elements/Button.js";
import { Link } from "react-router-dom";

import { errorHandler } from "error/errorHandler";
import { toast } from 'react-toastify';
import axios from "axios";
import ErrorCat from "animations/ErrorCat.js";
import Success from "animations/Success.js";

const apiUrl = process.env.REACT_APP_API_URL;
export default function CardChangePassword({
  image,
  title,
  subtitle,
  inputs,
  button,
  inputVal,
}) {
  let password = inputVal.password;
  let conf_password = inputVal.conf_password;
  let name = inputVal.name;
  let user_id = inputVal.user_id;
  const [response, setResponse] = useState({
    status:false,
    serverResponse:false,
    message:""

  })
  const { status, serverResponse,message } = response;
  function handleSubmit(e) {

    e.preventDefault();

    if (user_id) {
     
      if(password!==''){
        if(password.length<8){
          return  toast.error("Password Should be 8 Charecter", {
            
          });
        }
        else{
          if(password!==conf_password){
            return  toast.error("Password Does not match", {
              
            });
          }
          else{
            
            axios.put(`${apiUrl}/api/auth/update-pswd/${user_id}`, {
              password:password
            }, {
            })
              .then((response) => {
               
                  setResponse({...response,
                    ['status']:true,
                    ['serverResponse']:response.data.status,
                    ['message']:response.data.message
                  })
               
                console.log(response.data.status)
              })

          }
        }
      }
      

    }
  }
  return (
    <>
      <div className="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
        <div className="pb-6 rounded-t-lg overflow-hidden">
          <img
            src={image}
            alt="..."
            className="w-full rounded-t-lg"
            style={{ marginTop: "-46%" }}
          />
        </div>

        <div className="lg:p-10 px-4 py-4 flex-auto rounded-b-lg">
     
          <h4 className="text-2xl font-bold text-center">{title}</h4>
          <h3 className="text-2xl text-indigo-500 font-bold text-center">Welcome Back {name}</h3>
          <div className="text-center text-blueGray-500 mb-5 mt-1">
            <p>{subtitle}</p>
          </div>
          {(status && serverResponse === false) && <ErrorCat />
          }
          {(status && serverResponse === true) && <Success />  }
          {status && 
           <div className="text-center text-teal-500 mb-5 mt-1">
           <p>{message}</p>
         </div> }
          { serverResponse === false &&
          <form onSubmit={handleSubmit}>
            {inputs.map((prop, key) => {
                return (
                  <div key={key} className="relative w-full">
                    <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                      {prop.label}
                    </label>
                    <Input {...prop.input} />
                  </div>
                );
              })}
            {/* <Input {...input} /> */}

            <div className="text-center mt-6">
              <Button {...button} />
            </div>
          </form>
}
<div className="text-center text-blueGray-500 py-4 mb-5 mt-1">
<Link to="/login"  className="text-indigo-500 ml-2">
               Return to Login
              </Link>
              </div>
        </div>
      </div>
    </>
  );
}

CardChangePassword.defaultProps = {
  button: {},
  inputs: [],
};

CardChangePassword.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  // It is represetnted by the props you
  // can pass to our Input component element
  // It is represetnted by the props you
  // can pass to our Button component element
  button: PropTypes.object,
  inputVal: PropTypes.object,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      // It is represetnted by the props you
      // can pass to our Input component element
      // NOTE: if you wish to generate a textarea, you will need to pass
      // // // inside this object >> type: "textarea"
      // // // full example >> input: { type: "textarea" }
      input: PropTypes.object,
    })
  ),
};
