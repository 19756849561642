// import config from 'config';
import { authHeader } from '../_helpers';
// import axios from "axios";

export const userService = {
    login,
    loginOtp,
    verifyOtp,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete
};

const apiUrl=process.env.REACT_APP_API_URL;
function login(username, password,otpRadio) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password,otpRadio })
    };

    return fetch(`${apiUrl}/api/auth/signin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
   
        // return axios
        // .post(`${apiUrl}/api/auth/signin`, { username, password },{
        //     headers: {"Access-Control-Allow-Origin": "*"}
        //   })
        //   .then((response) => {
        //     if (response.data.accessToken) {
  
        //         localStorage.setItem('user', JSON.stringify(response.data));
        //     }
        //     else{
        //         const error = (data && data.message) || response.statusText;
        //     return Promise.reject(error);
        //     }
    
        //     return response.data;
        //   });
}

function loginOtp(mobile,otpRadio) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ mobile,otpRadio })
    };

    return fetch(`${apiUrl}/api/auth/signinOtp`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
   
}
function verifyOtp(username, otp,device) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, otp,device })
    };

    return fetch(`${apiUrl}/api/auth/verify-otp`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.removeItem('user');
            // localStorage.clear();
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}
function logout() {
    // remove user from local storage to log user out
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
        // body: JSON.stringify(user)
    };
    
    //  fetch(`${apiUrl}/api/auth/signinOut`, requestOptions).then(handleResponse);
     localStorage.removeItem('user');
     localStorage.clear();
    
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${apiUrl}/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        // console.log("userssss"+data);
        if (!data.status) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }
            if (response.status === 200) {
                return data;
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}