import React from "react";

import lottie from "lottie-web";
import verifyEmail from "animations/email-verify-small";

export default function MailVerifySpinner() {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#verify-spin"),
          animationData: verifyEmail
        });
      }, []);
  return (
    <>
      <div className="h-64 w-80" id="verify-spin"></div>
    </>
  );
}
