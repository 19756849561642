import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

// components
import CardLoginSocial from "components/Cards/Auth/CardLoginSocial.js";
import FooterAuth from "components/Footers/Auth/FooterAuth.js";
// texts
import footerauth from "_texts/auth/footers/footerauth.js";
import lottie from "lottie-web";
import deliveryService from "animations/delivery-service.json";
import bg from "animations/85570-background-animation-for-a-simple-project"
import A from "animations/30816-mail-send-animation"
import B from "animations/27931-fly-in-sky"
import C from "animations/58923-sms-header-homepage"



export default function LoginA() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  React.useEffect(() => {
    // lottie.loadAnimation({
    //   container: document.querySelector("#image-loader"),
    //   animationData: deliveryService
    // });
    lottie.loadAnimation({
      container: document.querySelector("#image-loader1"),
      animationData: C
    });
    lottie.loadAnimation({
      container: document.querySelector("#image-loader"),
      animationData: B
    });
  }, []);

  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });
  const [otpButton, setotpButton] = useState(false);
  const { username, password } = inputs;

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  function handleOtpview(e) {
    e.preventDefault();
    setotpButton(true); // Here we change state
  }

  const [otpRadio, setOtpRadio] = React.useState(1);

  const onChange = e => {
    console.log('radio checked', e.target.value);
    setOtpRadio(e.target.value);
  };

  const textProps = {
    title: "Sign in with OTP",
    subtitle: "Or sign in with credentials",
    // title:"sign in with credentials",
    socials: [
      { icon: "sms", color: "teal", onclick: handleOtpview, button: { color: "lightBlue" } },
    ],
    button: {
      fullWidth: true,
      color: "dark",
      children: "Sign in",
    },
    forgotPassword: {
      href: "#pablo",
    },
    createAccount: {
      href: "#pablo",
    },
    // checkbox: {
    //   label: "Remember me",
    // },
    inputs: [
      {
        label: "Email",
        input: {
          type: "email",
          placeholder: "Email",
          name: "username",
          value: username,
          onChange: handleChange,

        },
      },
      {
        label: "Password",
        input: {
          type: "password",
          placeholder: "Password",
          name: "password",
          value: password,
          onChange: handleChange,

        },
      },
    ],
    radioGroup: {
      title: "OTP Request to",
      onChange: onChange,
      value: otpRadio,
      radioButtons: [
        {
          radio: {
            name: "SMS",
            value: 1
          },
        },
        {
          radio: {
            name: "Email",
            value: 2
          },
        },
      ],
    }

  };
  const inputData = {
    inputVal: {
      username: username,
      password: password,
      otpButton: otpButton,
      otpRadio: otpRadio
    }
  };
  const style = {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
    "flex": 1,
    "position": "relative",
  }
  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">

          {/* <div id="image-loader"
            className="absolute top-0 w-screen h-screen bg-light-900 bg-no-repeat bg-full"
            // style={{
            //   backgroundImage:
            //     "url(" + require("assets/img/loginbg.png").default + ")",
            // }}

          >
          </div> */}
          <div id="image-loader1"
            className=" absolute left-0 w-32 top-0  h-32 bg-light-900 bg-no-repeat bg-50"
            // style={{
            //   backgroundImage:
            //     "url(" + require("assets/img/loginbg.png").default + ")",
            // }}

          >
             <div id="image-loader1"
            className=" absolute right-0 w-32 top-0  h-32 bg-light-900 bg-no-repeat bg-50"
            // style={{
            //   backgroundImage:
            //     "url(" + require("assets/img/loginbg.png").default + ")",
            // }}

          ></div>
          </div>


          <div className="container mx-auto px-4 h-full">
            <div className="flex  h-full">
              <div className="w-full lg:w-4/12 px-4">
                <CardLoginSocial {...textProps} {...inputData} />
              </div>
              {/* <div className="w-full lg:w-4/12 px-4 py-2" id="image-loader">
  
  </div> */}

            </div>

          </div>
          <FooterAuth {...footerauth} />
        </section>
      </main>
    </>
  );
}
