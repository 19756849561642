import React, { useState } from "react";
import { Tabs, Empty } from 'antd';
import SentItemCardTable from "components/Cards/Communication/SentItemCardTable";
import ScheduleItemCardTable from "components/Cards/Communication/ScheduleItemCardTable";

const { TabPane } = Tabs;


const MailTransaction = ({ color }) => {
  const [open, setOpen] = useState('1')
     const handleTabclick = (key,event) => {
      //  alert(key)
       setOpen(key)
     }
//   state = { size: 'small' };

//   onChange = e => {
//     this.setState({ size: e.target.value });
//   };

 
//     const { size } = this.state;
    return (
      <div>
        
        <Tabs defaultActiveKey="1" tabPosition="top" activeKey={open}  animated={{ inkBar: true, tabPane: false }} onTabClick={handleTabclick}  size={'large'}>
            <TabPane  tab={
                        <span className="text-indigo-500 bg-white">
                        <i className="fas fa-share px-2"></i>
                          Sent Items
                        </span>
                        } 
                       key="1">
            {open==='1' && <SentItemCardTable />}
            {/* <SentItemCardTable /> */}
          </TabPane>
          
          <TabPane  tab={
                        <span className="text-teal-500 bg-white">
                        <i className="fas fa-clock px-2"></i>
                        Scheduled Emails
                        </span>
                        } 
           key="2">
          {open==='2' && <ScheduleItemCardTable />}
    
          </TabPane>
          <TabPane tab={
                        <span className="text-blueGray-500 bg-white">
                        <i className="fas fa-envelope-open px-2"></i>
                       Open
                        </span>
                        }  key="3">
          <Empty />
          </TabPane>
          <TabPane tab={
                        <span className="text-lightBlue-500 bg-white">
                        <i className="fas fa-box-open px-2"></i>
                        Unique Open
                        </span>
                        }  key="4">
          <Empty />
          </TabPane>
          <TabPane tab={
                        <span className="text-dark-500 bg-white">
                        <i className="fas fa-mouse px-2"></i>
                        Click
                        </span>
                        } key="5">
          <Empty />
          </TabPane>
          <TabPane tab={
                        <span className="text-emerald-500 bg-white"> 
                        <i className="fas fa-fingerprint px-2"></i>
                        Unique Click
                        </span>
                        } key="6">
          <Empty />
          </TabPane>
          <TabPane tab={
                        <span className="text-amber-500 bg-white">
                        <i className="fas fa-exclamation-circle px-2"></i>
                        Unsubscribe
                        </span>
                        } key="7">
          <Empty />
          </TabPane>
          <TabPane tab={
                        <span className="text-red-500 bg-white">
                        <i className="fas fa-exclamation-triangle px-2"></i>
                        Spam
                        </span>
                        } key="8">
          <Empty />
          </TabPane>
        </Tabs>
      </div>
    );
    
}

export default function TabsRender() {
    return (
      <>
        <MailTransaction color="indigo" />
      </>
    );
  }
// ReactDOM.render(<MailTransaction />, mountNode);