import React, { useEffect } from "react";
import axios from "axios";

import { authHeader } from '../../_helpers';
import CardFullTable from "components/Cards/Admin/CardFullTable.js";
import { useSelector } from 'react-redux';

import CardEmail from "components/Cards/Communication/CardEmail.js";
import CardBulkEmail from "components/Cards/Communication/CardBulkEmail.js";
import MailTransaction from "components/Tabs/MailTransaction";
import { errorHandler } from "error/errorHandler";
const TabsEmail = ({ color }) => {
  const user = useSelector(state => state.authentication.user);

 
  const [openTab, setOpenTab] = React.useState(1);
  const [draftItems, setDraftItems] = React.useState([]);
  const apiUrl=process.env.REACT_APP_API_URL;
  // useEffect(()=>{
  //   window.addEventListener('storage', () => {
      
  //     let forwardMailid=(!localStorage.getItem('forwardMail')) ? "" : localStorage.getItem('forwardMail');
  //     if (forwardMailid) {
  //       setOpenTab(1)
  //     }
  //   })
    
  // }, []);
  useEffect(() => {

    // if (openTab === 2) {

    //   axios.post(`${apiUrl}/api/mail/senditems`, { status: [2, 3] }, {
    //     // headers: { "Access-Control-Allow-Origin": "*" }
    //     headers: authHeader()
    //   })
    //     .then(response => {
    //       setSentItems([]);
    //       let itemsArray = [];
    //       response.data.map((prop, key) => {
    //         // console.log(prop.subject);
    //         const Data = [
    //           // {
    //           //   image: "/notus-pro-react/static/media/vue.43bd93a8.jpg",
    //           //   text: "Vue Material Dashboard",
    //           // },
    //           prop.subject,
    //           prop.date,
    //           { color: "emerald", text: "completed" },
    //           { color: "emerald", text: "completed" },
    //           // {
    //           //   images: {
    //           //     images: [
    //           //       {
    //           //         image:
    //           //           "/notus-pro-react/static/media/team-1-800x800.fa5a7ac2.jpg",
    //           //         text: "Team 1",
    //           //       },
    //           //       {
    //           //         image:
    //           //           "/notus-pro-react/static/media/team-2-800x800.2f02b23c.jpg",
    //           //         text: "Team 2",
    //           //       },
    //           //       {
    //           //         image:
    //           //           "/notus-pro-react/static/media/team-3-800x800.19201574.jpg",
    //           //         text: "Team 3",
    //           //       },
    //           //       {
    //           //         image:
    //           //           "/notus-pro-react/static/media/team-4-470x470.4ef82ef4.png",
    //           //         text: "Team 4",
    //           //       },
    //           //     ],
    //           //   },
    //           // },
    //           { text: "100%", progress: { value: 100, color: "emerald" } },
    //           {
    //             dropdown: {
    //               icon: "fas fa-cog",
    //               button: { color: "white", size: "sm" },
    //               items: [
    //                 { href: "#pablo", children: "Forward" },
    //                 // { href: "#pablo", children: "Settings" },
    //                 // { href: "#pablo", children: "Log out" },
    //               ],
    //             },
    //           },
    //         ];
    //         itemsArray.push(Data);
    //         // alert(Data);

    //       })
    //       setSentItems(itemsArray);

    //     })
    //     .catch(error => {
    //       errorHandler(error);
    //     });
    //   console.log(...sentItems);
    // }
    if (openTab === 3) {

      axios.post(`${apiUrl}/api/mail/senditems`, { status: [1] }, {
        // headers: { "Access-Control-Allow-Origin": "*" }
        headers: authHeader()
      })
        .then(response => {
          setDraftItems([]);
          let itemsArray = [];
          response.data.map((prop, key) => {
            // console.log(prop.subject);
            const Data = [
              // {
              //   image: "/notus-pro-react/static/media/vue.43bd93a8.jpg",
              //   text: "Vue Material Dashboard",
              // },
              prop.subject,
              prop.date,
              { color: "emerald", text: "completed" },
              { color: "emerald", text: "completed" },
              // {
              //   images: {
              //     images: [
              //       {
              //         image:
              //           "/notus-pro-react/static/media/team-1-800x800.fa5a7ac2.jpg",
              //         text: "Team 1",
              //       },
              //       {
              //         image:
              //           "/notus-pro-react/static/media/team-2-800x800.2f02b23c.jpg",
              //         text: "Team 2",
              //       },
              //       {
              //         image:
              //           "/notus-pro-react/static/media/team-3-800x800.19201574.jpg",
              //         text: "Team 3",
              //       },
              //       {
              //         image:
              //           "/notus-pro-react/static/media/team-4-470x470.4ef82ef4.png",
              //         text: "Team 4",
              //       },
              //     ],
              //   },
              // },
              { text: "100%", progress: { value: 100, color: "emerald" } },
              {
                dropdown: {
                  icon: "fas fa-cog",
                  button: { color: "white", size: "sm" },
                  items: [
                    { href: "#pablo", children: "Edit" },
                    // { href: "#pablo", children: "Settings" },
                    // { href: "#pablo", children: "Log out" },
                  ],
                },
              },
            ];
            itemsArray.push(Data);
            // alert(Data);

          })
          setDraftItems(itemsArray);

        })
        .catch(error => {
          errorHandler(error);
        });
      console.log(...draftItems);
    }
  }, [openTab]);
 
  const cardDrafttable = {
    title: "Sent Items",
    color: "white",

    head: ["Subject", "Date", "Status", "Recipients", "Delivery", "More     Action"],
    body: [
      ...draftItems
    ],
  }
  
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="relative  flex  min-w-0  w-full mb-8 shadow-lg rounded-lg"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase hover:text-red px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-" + color + "-500"
                    : "text-" + color + "-500 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="fas fa-space-shuttle text-base mr-1"></i> Compose
              </a>
            </li>
            {user.roles.includes('ADMIN') &&
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase hover:text-red px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 4
                    ? "text-white bg-" + color + "-500"
                    : "text-" + color + "-500 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                <i className="fas fa-space-shuttle text-base mr-1"></i> Bulk Email Excel
              </a>
            </li>}
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-" + color + "-500"
                    : "text-" + color + "-500 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                <i className="fas fa-mail-bulk text-base mr-1"></i> Transactions
              </a>
            </li>
            {/* <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="fas fa-briefcase text-base mr-1"></i> Draft
              </a>
            </li> */}
          
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className="flex flex-wrap">
                    {/* <div className="w-full xl:w-12/12 px-4"> */}
                    <CardEmail />
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  {/* <CardFullTable {...cardSentItemTable} /> */}
                  <MailTransaction />
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <CardFullTable {...cardDrafttable} />
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <div className="flex flex-wrap">
                    {/* <div className="w-full xl:w-12/12 px-4"> */}
                    <CardBulkEmail />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender() {
  return (
    <>
      <TabsEmail color="indigo" />
    </>
  );
}