import React from "react";

import lottie from "lottie-web";
import deliveryService from "animations/user-profile-v2";

export default function UserLogin() {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#profile"),
          animationData: deliveryService
        });
      }, []);
  return (
    <>
      <div id="profile" ></div>
    </>
  );
}
