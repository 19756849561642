import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { errorHandler } from "error/errorHandler";
import 'antd/dist/antd.css';

// components
import { toast } from 'react-toastify';
import { authHeader } from '../../../_helpers';
import InfoMailListIcon from "components/InfoAreas/Presentation/InfoMailListIcon.js";
import Button from "../../Elements/Button.js";
import Input from "../../Elements/Input.js";
import Select from "../../Elements/Select.js";
import { MultiSelect } from "react-multi-select-component";

import { InboxOutlined } from '@ant-design/icons';
import { Drawer } from 'react-rainbow-components';
import { DatePicker, Space, Switch } from 'antd';
import moment from 'moment'

const { RangePicker } = DatePicker;






export default function CardSystemConfiguration() {
  const apiUrl=process.env.REACT_APP_API_URL;
  const widths = {
    1: "lg:w-1/12",
    2: "lg:w-2/12",
    3: "lg:w-3/12",
    4: "lg:w-4/12",
    5: "lg:w-5/12",
    6: "lg:w-6/12",
    7: "lg:w-7/12",
    8: "lg:w-8/12",
    9: "lg:w-9/12",
    10: "lg:w-10/12",
    11: "lg:w-11/12",
    12: "lg:w-12/12",
  };
  const [inputs, setInputs] = useState({
    system_maintenance_date:[],
    system_maintenance_help:'',
    sms_maintenance_date: '',
    sms_maintenance_help: '',
    mail_maintenance_date: '',
    mail_maintenance_help: '',
    
  });
  const { system_maintenance_date,system_maintenance_help, sms_maintenance_date, sms_maintenance_help, mail_maintenance_date,mail_maintenance_help } = inputs;
  const [systemStatus, setSystemStatus] = useState(true);
  const [smsStatus, setSmsStatus] = useState(true);
  const [mailStatus, setMailStatus] = useState(true);

  const [Logintoggle, setLoginToggle] = useState(false);
  const [LoginMobiletoggle, setLoginMobileToggle] = useState(false);


  function ToggleSystemStatus(value) {
    setSystemStatus(value);
  }
  function ToggleSmsStatus(value) {
    setSmsStatus(value);
  }
  function ToggleMailStatus(value) {
    setMailStatus(value);
  }
  function handleToggleLogin(value) {
    setLoginToggle(value);
  }
  function handleToggleLoginMobile(value) {
    setLoginMobileToggle(value);
  }
  function onChangeSystem(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    setInputs(inputs => ({ ...inputs, ['system_maintenance_date']: [  moment(dateString[0]),  moment(dateString[1])] }));
  }
  function onChangeSms(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    setInputs(inputs => ({ ...inputs, ['sms_maintenance_date']: [  moment(dateString[0]),  moment(dateString[1])] }));
  }
  function onChangeMail(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    setInputs(inputs => ({ ...inputs, ['mail_maintenance_date']: [  moment(dateString[0]),  moment(dateString[1])] }));
  }
  
  function onOk(value) {
    console.log('onOk: ', value);
  }
  
  
  function handleChangeInput(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  const cardApiForm = {
    title: "System Maintenance Settings",
    button: {
      children: "Save change",
      size: "sm",
      color: "indigo",
      onClick:handleSubmit
    },
    forms: [
      {
        title: "Access Management",
        inputs : [
          {
            label: "Login using Mobile",
            width: 3,
            switch: {
              id: 6,
              name: "login_mobile",
              checked: LoginMobiletoggle,
              // size:"small",
              onChange: handleToggleLoginMobile,
            },
            // display:configToggle
          },
          {
            label: "Login Outside KILA",
            width: 3,
            switch: {
              id: 5,
              name: "loginPermission",
              checked: Logintoggle,
              // size:"small",
              onChange: handleToggleLogin,
            },
            // display:configToggle
          },
        ]

      },
      {
        title: "System Maintenance Mode",
        inputs: [
          {
            label: "Date Time",
            width: 4,
            rangePicker: {
             
              name:'system_maintenance_date',
              value:system_maintenance_date,
              onChange:onChangeSystem
           
            },
          },
         
          {
            label: "Active",
            width: 2,
            switch: {
              id: 1,
              name: "first",
              checked: systemStatus,
              // size:"small",
              onChange: ToggleSystemStatus,
            },
          },
          {
            label: "Information",
            width: 6,
            input: {
              placeholder: "Note",
              type: "text",
              name:'system_maintenance_help',
              value:system_maintenance_help,
              onChange:handleChangeInput
              
            },
          },
         
        ],
      },
      {
        title: "SMS Module Maintenance Mode",
        inputs: [
          {
            label: "Date Time",
            width: 4,
            rangePicker: {
             
              name:'sms_maintenance_date',
              value:sms_maintenance_date,
              onChange:onChangeSms
              
            },
          },
         
          {
            label: "Active",
            width: 2,
            switch: {
              id: 1,
              name: "first",
              checked: smsStatus,
              // size:"small",
              onChange: ToggleSmsStatus,
            },
          },
          {
            label: "Information",
            width: 6,
            input: {
              placeholder: "Note",
              type: "name",
              name:'sms_maintenance_help',
              value:sms_maintenance_help,
              onChange:handleChangeInput
            },
          },
         
        ],
      },
      {
        title: "Mail Module Maintenance Mode",
        inputs: [
          {
            label: "Date Time",
            width: 4,
            rangePicker: {
              name:'mail_maintenance_date',
              value:mail_maintenance_date,
              onChange:onChangeMail
        
            },
          },
         
          {
            label: "Active",
            width: 2,
            switch: {
              id: 1,
              name: "first",
              checked: mailStatus,
              // size:"small",
              onChange: ToggleMailStatus,
            },
          },
          {
            label: "Information",
            width: 6,
            input: {
              placeholder: "Note",
              type: "text",
              name:'mail_maintenance_help',
              value:mail_maintenance_help,
              onChange:handleChangeInput
            },
          },
         
        ],
      },
      
    ],
  };
  function handleSubmit(e) {
    e.preventDefault();
    axios.post(
      `${apiUrl}/api/config/global-settings`,
      {
        updateData:[
          {key:'system_maintenance', value: JSON.stringify({"from":moment(system_maintenance_date[0].format('YYYY/MM/DD HH:mm')).format("YYYY-MM-DD HH:mm"),"to":moment(system_maintenance_date[1].format('YYYY/MM/DD HH:mm')).format("YYYY-MM-DD HH:mm"),"help_text":system_maintenance_help,"status":systemStatus ? 1 :0})},
          {key:'sms_maintenance', value: JSON.stringify({"from":moment(sms_maintenance_date[0].format('YYYY/MM/DD HH:mm')).format("YYYY-MM-DD HH:mm"),"to":moment(sms_maintenance_date[1].format('YYYY/MM/DD HH:mm')).format("YYYY-MM-DD HH:mm"),"help_text":sms_maintenance_help,"status":smsStatus ? 1 :0})},
          {key:'mail_maintenance', value: JSON.stringify({"from":moment(mail_maintenance_date[0].format('YYYY/MM/DD HH:mm')).format("YYYY-MM-DD HH:mm"),"to":moment(mail_maintenance_date[1].format('YYYY/MM/DD HH:mm')).format("YYYY-MM-DD HH:mm"),"help_text":mail_maintenance_help,"status":mailStatus ? 1 :0})},
          {key:'LoginMobile', value: !LoginMobiletoggle ? 0 : 1,},
          {key:'LoginOutside', value: !Logintoggle ? 0 : 1,}
        ]
        
      }
    ).then((response) => {
       toast.success(`System Configuration updated `, {
              autoClose:true
            });
      console.log(response.data);
    }).catch(error => {
        errorHandler(error);
    });

  }
  
  useEffect(() => {
    axios.get(`${apiUrl}/api/config/global-settings`).then(response => {
      // setLocationOptions(response.data);
      console.log(response.data.result)
      response.data.result.map((prop, key) => {
        console.log(prop.config_key)
        if (prop.config_key === 'system_maintenance') {
          var config = JSON.parse(prop.config_value)
          setInputs(inputs => ({ ...inputs, ['system_maintenance_date']: [moment(config.from),moment(config.to)] }));
          setSystemStatus(config.status==1 ? true : false)
          setInputs(inputs => ({ ...inputs, ['system_maintenance_help']: config.help_text}));
        }
        if (prop.config_key === 'sms_maintenance') {
          var config = JSON.parse(prop.config_value)
          setInputs(inputs => ({ ...inputs, ['sms_maintenance_date']: [moment(config.from),moment(config.to)] }));
          setSmsStatus(config.status==1 ? true : false)
          setInputs(inputs => ({ ...inputs, ['sms_maintenance_help']: config.help_text}));
        }
        if (prop.config_key === 'mail_maintenance') {
          var config = JSON.parse(prop.config_value)
          setInputs(inputs => ({ ...inputs, ['mail_maintenance_date']: [moment(config.from),moment(config.to)] }));
          setMailStatus(config.status==1 ? true : false)
          setInputs(inputs => ({ ...inputs, ['mail_maintenance_help']: config.help_text}));
        }
        if (prop.config_key === 'LoginMobile') {
          setLoginMobileToggle(parseInt(prop.config_value) === 1 ? true : false)
        }
        if (prop.config_key === 'LoginOutside') {
          setLoginToggle(parseInt(prop.config_value) === 1 ? true : false)
        }

  
        
        
      })
    });

  }, []);




  return (
    <>

      <div className="w-full xl:w-12/12 px-4">
        <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
          <div className="mb-0 p-6 pb-0">
            <div className="text-center flex justify-between items-center">
              <h6 className="text-xl font-bold mb-0">{cardApiForm.title}</h6>
              
              <div className="text-center flex  items-center">
              <Button {...cardApiForm.button} />
                {/* {cardEmailForm.modalButton.map((prop, key) => (
                  <>
                    <Button key={key} {...prop} />
                  </>
                ))} */}
              </div>

            </div>
          </div>
          {/* <Spin spinning={Loader} tip="Please Wait..."> */}
            <div className="flex-auto px-6 pb-6 pt-0">
              <form encType="multipart/form-data" >
                {cardApiForm.forms.map((prop, key) => (

                  <div key={key}>
                    <h6 className="mt-6 mb-2 font-bold">{prop.title}</h6>
                    <hr className="mb-6 border-b-1 border-blueGray-200" />
                    <div className="flex flex-wrap -mx-4">
                      {prop.inputs &&
                        prop.inputs.map((inputProp, inputKey) => (
                          <div
                            key={inputKey}
                            className={"w-full px-4 " + widths[inputProp.width]}
                          >
                            <div className="relative w-full mb-3">
                              {inputProp.label  && (
                                <label
                                  className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                                  htmlFor="grid-password"
                                >
                                  {inputProp.label}
                                </label>
                              )}
                              {inputProp.input && <Input {...inputProp.input} />}
                              {inputProp.rangePicker &&<RangePicker
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                // onChange={onChange}
                                onOk={onOk}
                                {...inputProp.rangePicker}
                                />}
                                    
    
                              {inputProp.select && <Select {...inputProp.select} />}
                              {inputProp.switch && <Switch  {...inputProp.switch} />}
                              {/* {inputProp.CKediter && <CKEditer {...inputProp.CKediter} />} */}
                              

                              
                              {inputProp.button && <Button {...inputProp.button} />}
                             

                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
    
 

               
                


              </form>

            </div>
          {/* </Spin> */}
        </div>
      </div>

      
    </>
  );
}

const inputsObject = {
  // NOTE: this width only applies on large devices
  width: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  // if you wish, you can send somthing like
  // <span className="mr-2">Label Name</span><span className="text-red-500">*</span>
  // NOTE: the label tag will be auto generated
  label: PropTypes.node,
};

const inputsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Input component
  input: PropTypes.object,
});

const selectsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Select component
  select: PropTypes.object,
});

const inputsTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([inputsShape, selectsShape])
);

CardSystemConfiguration.defaultProps = {
  button: {},
  forms: [],
};

CardSystemConfiguration.propTypes = {
  title: PropTypes.string,
  // props to pass to the Button component
  button: PropTypes.object,

  forms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      inputs: inputsTypes,
    })
  ),
};
