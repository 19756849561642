import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";

// components
import CardStatsMini from "../../../components/Cards/Admin/CardStatsMini.js";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment'
import { Badge } from 'react-rainbow-components';
import { useIdleTimer } from 'react-idle-timer'
import { authHeader } from '../../../_helpers';
import axios from 'axios';
import { socket } from 'context/socket';
import {  useSelector } from 'react-redux';
import { toast } from 'react-toastify';


export default function HeaderStatCards({ cards, color, welcome,last_login }) {
  const bgColors = {
    light: "bg-blueGray-200",
    dark: "bg-blueGray-800",
    blueGray: "bg-blueGray-500",
    red: "bg-red-500",
    orange: "bg-orange-500",
    amber: "bg-amber-500",
    emerald: "bg-emerald-500",
    teal: "bg-teal-500",
    lightBlue: "bg-lightBlue-500",
    indigo: "bg-indigo-500",
    purple: "bg-purple-500",
    pink: "bg-pink-500",
    white: "bg-blueGray-100",
  };
  const user = useSelector(state => state.authentication.user);
  var today = new Date()
  var curHr = today.getHours()
  var time = ''
  var image = ''

  if (curHr < 12) {
    time = 'Good Morning';
    image = 'morning.svg';
  } else if (curHr < 18) {
    time ='Good Afternoon';
    image = 'afternoon.svg';
  } else {
    time = 'Good Evening';
    image = 'evening.svg';
  }
  
    const [ minutes, setMinutes ] = useState(0);
    const [seconds, setSeconds ] =  useState(0);
    const apiUrl=process.env.REACT_APP_API_URL;
    function logoutCall(){
      axios.post(`${apiUrl}/api/auth/signinOut`, {}, {
        headers: authHeader()
      }).then(response => {
        localStorage.removeItem('user');
        localStorage.clear();
        if (response.status === 200) {
          window.location = "/login"
       }
        
      });
    }
    
  const handleOnIdle = event => {
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    axios.post(`${apiUrl}/api/auth/signinOut`, {}, {
      headers: authHeader()
    }).then(response => {
      localStorage.removeItem('user');
      localStorage.clear();
      if (response.status === 200) {
        window.location = "/login"
     }
      
    });
  }

  const handleOnActive = event => {
    // console.log('user is active', event)
    // console.log('time remaining', moment(getRemainingTime()).format())
    
  }

  const handleOnAction = event => {
    // console.log('user did something', event)
    // reset()
    // console.log("last active"+moment(getLastActiveTime()).format())
    // console.log('time remaining', getRemainingTime())
    var time= millisToMinutesAndSeconds(getRemainingTime())
    setMinutes(time.minutes)
    setSeconds(time.seconds)
  }

  const { getRemainingTime, getLastActiveTime,reset } = useIdleTimer({
    timeout: 985 * 60 * 15, //idle time 10 minutes
    // timeout: 100000 * 60 * 15, //idle time 10 minutes
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })
 
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    // seconds =(seconds < 10 ? '0' : '') + seconds
    return {minutes : minutes,seconds:seconds}
    // return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
  
    useEffect(()=>{
      socket.on("logout", data => {
            // console.log("logout data "+data.id)
            // console.log("my id "+id)
            if(parseInt(data.id) === parseInt(user.id)){
              logoutCall();
            }
            else{
            }
          });
          if(user.roles.includes('ADMIN')){
          socket.on("online-user-message", data => {
            toast.success(`${data.user} Connected online on IP :${data.ip}`, {
              autoClose: true
            })
          });
        }
        
          
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
          
    });

  return (
    <>
      {/* <div
        className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-50 bg-cover p-10"
        style={{ backgroundImage: "url('"+ require("assets/img/landing-header.png").default+"')" }}
      > */}
      <div className={"relative pt-20 pb-32 " + bgColors[color]}>
        <div className="px-4 md:px-6 mx-auto w-full">
          <div>
            {/* <div className="px-4 py-4"> */}
            <div className="w-full xl:w-12/12 px-4">
        <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
              {/* <Card > */}
              <div className=" px-6 py-4">
            <span className="text-emerald-500 font-bold text-sm px-6">  
            <img
              alt="..."
              className="  align-middle border-none  p-1  pt-5 text-center inline-flex  w-12 h-12"
              src={require("assets/img/"+image).default}
            />
            {/* <Morning></Morning> */}
            {time} {welcome}
            </span>
                
            
            <span className="text-blueGray-500 font-bold right-0 absolute pt-2   pr-4">
            <Badge  title="the badge title" className="rainbow-m-around_medium">
            {/* <FontAwesomeIcon icon={faStar} pull="left" size="lg" /> */}
            Last Login : {moment(last_login).format("Do MMMM YYYY, h:mm a")} <br></br>
             
            { minutes === 0 && seconds === 0
            ? null
            : <small className="text-red-500"> Expire In {minutes}:{seconds < 10 ?  `0${seconds}` : seconds} Sec</small> 
            }
          
            </Badge>
              
            </span>
             
              
              {/* <span className="text-blueGray-500 font-bold  absolute pt-10 right-0 pr-4">   </span> */}

                
              </div>
             
              {/* </Card> */}
            </div>
            </div>
            {/* </div> */}

            <div className="flex flex-wrap">
              {cards.map((prop, key) => (
                <div key={key} className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStatsMini {...prop} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

HeaderStatCards.defaultProps = {
  cards: [],
  color: "blueGray",
};

HeaderStatCards.propTypes = {
  // array of props to pass to the CardStatsMini component
  cards: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.oneOf([
    "light",
    "dark",
    "blueGray",
    "red",
    "orange",
    "amber",
    "emerald",
    "teal",
    "lightBlue",
    "indigo",
    "purple",
    "pink",
    "white",
  ]),
};
